import { Duration } from 'dayjs/plugin/duration';

import { ACTIVITY_TYPES } from 'business/activity/types';
import { getTotalAvailability } from 'business/task/pages/TasksAnalyzer/services/chartServices';
import { parseTaskDateString } from 'business/task/services/timeOperations';
import {
  ConstructionSiteFieldsFragment,
  GetPreviousPrintTasksQuery,
  ShiftReportChartViewFragment,
  TaskAverageTimeInfosFragment,
  TaskFullFragment,
} from 'generated/graphql';
import { TIME_HOUR_MINUTE_FORMAT } from 'technical/string/formatter';
import { toPercent } from 'technical/utils/converter';
import { UploadMetadata } from 'ui/form/fileUpload/types';

import { TableData, TaskTimeInfo } from './types';

export const reportIsValidated = (
  report: ShiftReportChartViewFragment,
  constructionSite: ConstructionSiteFieldsFragment | null,
) => {
  if (constructionSite?.managerValidationRequired) {
    return !!report.managerValidation;
  }

  return !!report.operatorValidation;
};

export const totalAvailability = (
  totalExcavationTime: Duration,
  totalBuildTime: Duration,
  totalTasksTime: Duration,
) => {
  const total = toPercent(
    getTotalAvailability(
      totalExcavationTime.asMinutes(),
      totalBuildTime.asMinutes(),
      totalTasksTime.asMinutes(),
    ),
  );
  return total || 0;
};

export const totalCriticalAvailability = (
  totalCriticalExcavationTime: Duration,
  totalCriticalBuildTime: Duration,
  totalCriticalTasksTime: Duration,
) => {
  const total = toPercent(
    getTotalAvailability(
      totalCriticalExcavationTime.asMinutes(),
      totalCriticalBuildTime.asMinutes(),
      totalCriticalTasksTime.asMinutes(),
    ),
  );
  return total || 0;
};

export const tasksHasFiles = (tasks: TaskFullFragment[]) =>
  tasks.some(({ files }: { files: UploadMetadata[] }) => files.length > 0);

export const getFinishedRingFromTaskList = (tasks?: TaskTimeInfo[]) => {
  if (tasks && tasks.length > 0) {
    return tasks
      .reduce(
        (acc: number[], task) => (task.ended ? [...acc, task.ring] : acc),
        [],
      )
      .filter((ring, idx, array) => array.indexOf(ring) === idx);
  }
  return [];
};

export const getTasksByCategory = (
  activityId: string,
  reportTasks?: ShiftReportChartViewFragment['tasks'],
) => {
  const descriptions: string[] = [];

  const tasks = reportTasks?.filter((task) => {
    let activityCategory = task.activity;
    while (activityCategory.parent) {
      descriptions[task.id] =
        activityCategory.name +
        (descriptions[task.id] ? ` / ${descriptions[task.id]}` : '');
      activityCategory = activityCategory.parent;
    }
    return activityCategory.id === activityId;
  });

  const tableData = tasks?.reduce((acc: TableData[], task) => {
    const tableTaskData = {
      key: task.id,
      startTime: parseTaskDateString(task.startDate).format(
        TIME_HOUR_MINUTE_FORMAT,
      ),
      spentTime: parseTaskDateString(task.endDate).diff(
        parseTaskDateString(task.startDate),
        'minutes',
      ),
      endTime: parseTaskDateString(task.endDate).format(
        TIME_HOUR_MINUTE_FORMAT,
      ),
      critical: task.critical,
      ring: task.ring,
      note: task.note,
    };

    if (descriptions[task.id]) {
      return [
        ...acc,
        {
          ...tableTaskData,
          description: descriptions[task.id],
        },
      ];
    }
    return [...acc, tableTaskData];
  }, []);

  if (!tableData || tableData.length === 0) {
    return null;
  }

  return tableData;
};

const averageRingTime = (
  tasksForAverage: TaskAverageTimeInfosFragment[],
  endedRings: number[],
): string | null => {
  if (tasksForAverage.length === 0 || endedRings.length === 0) {
    return null;
  }

  const totalTimeInShiftForType = tasksForAverage
    .filter(({ ring }) => endedRings.includes(ring))
    .reduce((acc, { duration }) => {
      if (duration) {
        return acc + duration;
      }
      return acc;
    }, 0);
  const averageTime = totalTimeInShiftForType / endedRings.length;

  return averageTime.toFixed(0);
};

export const averageRingTimeWithTypeSelection =
  (
    endedExcavationRings: number[],
    endedBuildRings: number[],
    tasksForAverageExcavation?: TaskAverageTimeInfosFragment[],
    tasksForAverageBuild?: TaskAverageTimeInfosFragment[],
    dataPreviousTasks?: GetPreviousPrintTasksQuery,
  ) =>
  (type: ACTIVITY_TYPES): string | null => {
    if (type === ACTIVITY_TYPES.EXCAVATION) {
      return averageRingTime(
        tasksForAverageExcavation?.concat(
          dataPreviousTasks?.excavationTasks ?? [],
        ) ?? [],
        endedExcavationRings,
      );
    }
    if (type === ACTIVITY_TYPES.BUILD) {
      return averageRingTime(
        tasksForAverageBuild?.concat(dataPreviousTasks?.buildTasks ?? []) ?? [],
        endedBuildRings,
      );
    }
    return null;
  };
