import { parseTaskDateString } from 'business/task/services/timeOperations';
import { TaskFullFragment } from 'generated/graphql';
import { TIME_HOUR_MINUTE_FORMAT } from 'technical/string/formatter';

export const nameWithPreviousActivityName = (activity: {
  id: string;
  name: string;
  parent?: { id: string; name: string } | null;
}): [string, string?] => {
  if (activity.parent) {
    return [activity.parent.name, activity.name];
  }
  return [activity.name];
};

export const nameWithPreviousActivityNameAndDate = (
  task: TaskFullFragment,
): string[] => {
  if (task.activity.parent) {
    return [
      task.activity.parent.name,
      task.activity.name,
      parseTaskDateString(task.startDate).format(TIME_HOUR_MINUTE_FORMAT),
    ];
  }
  return [
    task.activity.name,
    parseTaskDateString(task.startDate).format(TIME_HOUR_MINUTE_FORMAT),
  ];
};
