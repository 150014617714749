import { Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'translations/hooks';

import { GraphSetTypeEnum_Enum } from 'generated/graphql';

interface GraphSetTagProps {
  type: GraphSetTypeEnum_Enum;
}

export const GraphSetTag: React.FC<GraphSetTagProps> = ({ type }) => {
  const { t } = useTranslation();

  switch (type) {
    case GraphSetTypeEnum_Enum.Period:
      return (
        <Tag color={'orange'}>
          {t('dataAnalysis.modeSelection.timePeriod.title')}
        </Tag>
      );
    case GraphSetTypeEnum_Enum.Ring:
      return (
        <Tag color={'green'}>{t('dataAnalysis.modeSelection.ring.title')}</Tag>
      );
  }
};
