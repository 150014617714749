import { Result } from 'antd';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { i18n } from 'translations';

import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import Button from 'ui/button';

const ErrorBoundaryForRouter = () => {
  const error = useRouteError();

  useEffect(() => {
    if (error && error instanceof Error) {
      logger.error('component error', error);
      errorReporting.error(error);
    }
  }, [error]);

  return (
    <Result
      status="warning"
      title="There are some problems with your operation."
      extra={
        <Button
          type="primary"
          onClick={() => {
            window.location.replace('/');
          }}
        >
          {i18n.t('common.to_main_page')}
        </Button>
      }
    />
  );
};

export default ErrorBoundaryForRouter;
