import { SwapRightOutlined } from '@ant-design/icons';
import { InputNumber, Space, Typography } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';

import { notEmpty } from 'technical/utils/not-empty';

import styles from './index.module.scss';

export type NumberRangeValue =
  | [number | null | undefined, number | null | undefined]
  | null;

export interface NumberRangeInputProps {
  values: NumberRangeValue;
  onChange: (values: NumberRangeValue) => void;
  leftInputProps?: InputNumberProps;
  leftTitle?: string;
  rightInputProps?: InputNumberProps;
  rightTitle?: string;
  errorMessage?: string;
}

function NumberRangeInput({
  values,
  onChange,
  leftInputProps,
  leftTitle,
  rightInputProps,
  rightTitle,
  errorMessage,
}: Readonly<NumberRangeInputProps>) {
  const leftInputId = `leftInput-${leftTitle}`;
  const rightInputId = `rightInput-${rightTitle}`;

  return (
    <>
      <Space>
        <div>
          {leftTitle ? (
            <label htmlFor={leftInputId}>
              <Typography.Text type="secondary">{leftTitle}</Typography.Text>
            </label>
          ) : null}
          <InputNumber
            {...leftInputProps}
            id={leftTitle ? leftInputId : undefined}
            value={notEmpty(values?.[0]) ? values[0] : undefined}
            onChange={(value) =>
              typeof value !== 'string'
                ? onChange([value, values ? values[1] : undefined])
                : onChange([undefined, values ? values[1] : undefined])
            }
          />
        </div>
        <SwapRightOutlined
          className={leftTitle && rightTitle ? styles.arrow : undefined}
        />
        <div>
          {rightTitle ? (
            <label htmlFor={rightInputId}>
              <Typography.Text type="secondary">{rightTitle}</Typography.Text>
            </label>
          ) : null}
          <InputNumber
            {...rightInputProps}
            id={rightTitle ? rightInputId : undefined}
            value={notEmpty(values?.[1]) ? values[1] : undefined}
            onChange={(value) =>
              typeof value !== 'string'
                ? onChange([values ? values[0] : undefined, value])
                : onChange([values ? values[0] : undefined, undefined])
            }
          />
        </div>
      </Space>
      {errorMessage ? (
        <Typography.Text type="danger">{errorMessage}</Typography.Text>
      ) : null}
    </>
  );
}

export default NumberRangeInput;
