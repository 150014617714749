import { message, Skeleton } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'ui/button';
import CapCard from 'ui/CapCard/CapCard';
import Flex from 'ui/flex';

import styles from './index.module.scss';

interface DashboardTileProps {
  title: string;
  icon: string;
  loading: boolean;
  text?: React.ReactNode;
  route: string;
  ctaLabel: string;
  disabled?: boolean;
  disabledText?: string;
}

export default function DashboardTile(props: DashboardTileProps) {
  const {
    title,
    icon,
    loading,
    text,
    route,
    ctaLabel,
    disabled,
    disabledText,
  } = props;

  return (
    <CapCard title={title} className={styles.card}>
      <Flex
        column
        justify="center"
        alignItems="center"
        className={styles.spacer}
      >
        <img src={icon} alt={`${title} icon`} />
        <Skeleton
          title={false}
          paragraph={{ rows: 1, width: '100' }}
          active
          loading={loading}
        >
          {text}
        </Skeleton>
        {disabled ? (
          <Button
            onClick={() => {
              message.info(disabledText);
            }}
            type="primary"
          >
            {ctaLabel}
          </Button>
        ) : (
          <Link to={route}>
            <Button type="primary">{ctaLabel}</Button>
          </Link>
        )}
      </Flex>
    </CapCard>
  );
}
