import ReactDOM from 'react-dom/client';

import App from 'business/App';
import 'technical/time-utils/dayjs.extends';

import * as serviceWorker from './serviceWorker';
import './translations';

const root = document.getElementById('root');
if (!root) {
  throw new Error('Root not found');
}

ReactDOM.createRoot(root).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
