/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/browser';

import config from 'config';

/**
 * Enum based on Sentry.SeverityLevel string literal type
 */
enum SeverityLevel {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
}

const SENTRY_DSN = config.sentry.dsn;

type ErrorInfo = Record<string, unknown>;

interface IErrorReporting {
  error(error: Error, errorInfo?: ErrorInfo): void;
  info(error: Error, errorInfo?: ErrorInfo): void;
  warning(error: Error, errorInfo?: ErrorInfo): void;
  setUser(user: { id: string; username?: string; email?: string }): void;
  removeUser(): void;
}

/**
 * Main class
 */
class ErrorReporting implements IErrorReporting {
  constructor(key: string) {
    this.init(key);
  }

  private init(key: string): void {
    return Sentry.init({
      dsn: key,
      environment: config.env,
    });
  }

  private log(level: SeverityLevel, error: Error, errorInfo?: ErrorInfo) {
    return Sentry.withScope((scope) => {
      if (errorInfo) {
        scope.setExtras(errorInfo as Record<string, unknown>);
      }
      scope.setLevel(level);
      Sentry.captureException(error);
    });
  }

  public setUser(user: Sentry.User) {
    Sentry.configureScope((scope) => {
      scope.setUser(user);
    });
  }

  public removeUser() {
    Sentry.configureScope((scope) => {
      scope.setUser({ username: '', id: '', email: '' });
    });
  }

  public error(error: Error, errorInfo?: ErrorInfo) {
    this.log(SeverityLevel.Error, error, errorInfo);
  }

  public info(error: Error, errorInfo?: ErrorInfo) {
    this.log(SeverityLevel.Info, error, errorInfo);
  }

  public warning(error: Error, errorInfo?: ErrorInfo) {
    this.log(SeverityLevel.Warning, error, errorInfo);
  }
}

/**
 * Mocks class
 */
class ErrorReportingMocks implements IErrorReporting {
  setUser() {}

  error() {}

  info() {}

  warning() {}

  removeUser() {}
}

const errorReporting: IErrorReporting = SENTRY_DSN
  ? new ErrorReporting(SENTRY_DSN)
  : new ErrorReportingMocks();

export default errorReporting;
