import isEqual from 'lodash.isequal';

import { GraphSetStateDto } from 'generated/apiSchemas';

const formatIgnoreValues = (graphSetToFormat: GraphSetStateDto) => {
  const formattedGraphs = graphSetToFormat.graphs.map((graph) => {
    const formattedParameters = graph.parameters.map((param) => {
      return { ...param, values: undefined };
    });
    return { ...graph, parameters: formattedParameters };
  });
  return { ...graphSetToFormat, graphs: formattedGraphs };
};

export const sameGraphSet = (
  baseGraphSet: GraphSetStateDto,
  potentiallyUpdatedGraphSet: GraphSetStateDto,
) => {
  return isEqual(
    formatIgnoreValues(baseGraphSet),
    formatIgnoreValues(potentiallyUpdatedGraphSet),
  );
};
