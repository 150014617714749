import { message } from 'antd';
import { Dayjs } from 'dayjs';
import { TFunction } from 'translations/hooks';

import errorReporting from 'technical/error-reporting';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';

export const checkForExistingReport = async (
  t: TFunction,
  checkReport: (variables: any) => void,
  currentConstructionSiteId: string,
  date?: Dayjs,
  shiftId?: string,
) => {
  try {
    if (!date || !shiftId) {
      return;
    }
    checkReport({
      variables: {
        date: date.format(SIMPLE_DATE_FORMAT),
        shiftId,
        constructionSiteId: currentConstructionSiteId,
      },
    });
  } catch (err) {
    message.warning(t('pages.report.new.checkExistingReportFailed'), 10);
    if (err instanceof Error) {
      errorReporting.error(err);
    }
  }
};
