import dayjs from 'dayjs';

const REQUIRED_ENV = [
  'VITE_ENVIRONMENT',
  'VITE_FRONT_URL',
  'VITE_GRAPHQL_URI',
  'VITE_AUTH0_DOMAIN',
  'VITE_AUTH0_CLIENT_ID',
  'VITE_AUTH0_REDIRECT_URI',
  'VITE_BUCKET_BASE_URL',
];

const unsetEnvs = REQUIRED_ENV.reduce((unsetEnvAcc, envString) => {
  if (!import.meta.env[envString]) {
    return [...unsetEnvAcc, envString];
  }
  return unsetEnvAcc;
}, [] as string[]);

if (unsetEnvs.length > 0) {
  throw new Error(`Unset env: ${unsetEnvs}`);
}

const maintenanceDate = dayjs(import.meta.env.VITE_FF_MAINTENANCE_DATE);

const config = {
  env: import.meta.env.VITE_ENVIRONMENT,
  auth0: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientID: import.meta.env.VITE_AUTH0_CLIENT_ID,
    redirectUri: import.meta.env.VITE_AUTH0_REDIRECT_URI,
    audience: `https://${import.meta.env.VITE_AUTH0_DOMAIN}/api/v2/`,
  },
  hasura: {
    graphqlUri: import.meta.env.VITE_GRAPHQL_URI,
  },
  gcp: {
    publicUri: import.meta.env.VITE_BUCKET_BASE_URL,
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
  },
  features: {
    maintenanceDate: maintenanceDate.isValid()
      ? (import.meta.env.VITE_FF_MAINTENANCE_DATE as string)
      : null,
  },
} as const;

export default config;
