export enum ROLES {
  DATA_MANAGER = 'data-manager',
  CONSTRUCTION_SITE_ADMIN = 'construction-site-admin',
  CONSTRUCTION_SITE_MANAGER = 'construction-site-manager',
  CONSTRUCTION_SITE_FULL_READER = 'construction-site-full-reader',
  CONSTRUCTION_SITE_READER = 'construction-site-reader',
  USER = 'user',
}

// Array key used as weight for roles order, 0 as the highest
// To add a role, put it at the right place
// (usualy never before data-manager)
export const ROLES_RANKING = [
  ROLES.DATA_MANAGER,
  ROLES.CONSTRUCTION_SITE_MANAGER,
  ROLES.CONSTRUCTION_SITE_FULL_READER,
  ROLES.CONSTRUCTION_SITE_READER,
  ROLES.USER,
];

export const DEFAULT_ALLOWED_ROLES = [ROLES.USER];
