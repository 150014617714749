import { ApolloClient } from '@apollo/client';

import { getAuthEmail, getAuthId } from 'business/user/services/authentication';
import { HASURA_ROLE_KEY } from 'technical/auth/hasura/constants';

import { MUTATION_UPDATE_USER_AUTHID, QUERY_MY_USER } from './query.gql';

async function fetchUser(client: ApolloClient<object>) {
  const authId = getAuthId();
  const email = getAuthEmail();

  // try to get my user
  const data = await client.query({
    query: QUERY_MY_USER,
    variables: { id: authId, email },
    fetchPolicy: 'network-only',
  });

  if (data) {
    const {
      data: {
        user: [finalUser],
      },
    } = data;
    return finalUser;
  }

  return undefined;
}

// Used to update user in order to add Auth0 id for admin created user.
// An update in `_set` is required to trigger the hasura preset who update authId
async function updateUserAuthId(client: ApolloClient<object>, id: string) {
  const { data } = await client.mutate({
    mutation: MUTATION_UPDATE_USER_AUTHID,
    variables: {
      id,
    },
    context: {
      headers: {
        // Enforce user role to allow only user role to perform update
        [HASURA_ROLE_KEY]: 'user',
      },
    },
  });

  if (!data) {
    throw new Error('no data after user update');
  }

  const {
    update_user: {
      returning: [freshlyUpdatedUser],
    },
  } = data;

  return freshlyUpdatedUser;
}

export default async function getUserAndCreateIfNeeded(
  client: ApolloClient<object>,
) {
  let user = await fetchUser(client);

  if (!user) {
    // Should not be possible, auth0 check the user exist with the same conditions
    // See back/external-code/auth0/actions/custom-jwt-claims.js to check the query executed by auth0
    // If no user, app access is restricted anyway, the user needs to be created in the backend first
    throw new Error('no user found');
  }

  if (!user.authId) {
    // User created in BO doesn't have a authId before user first connects
    user = await updateUserAuthId(client, user.id);
  }

  return { ...user };
}
