import { EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/interface';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import {
  useIsConstructionSiteManager,
  usePermissions,
} from 'business/contextProviders/usePermissions';
import {
  RingTableColumnKeys,
  RingTableRow,
} from 'business/production-and-performance/pages/ring-table/types';
import { formatRingDuration } from 'business/production-and-performance/services/ring-time-format';
import { Module_Enum } from 'generated/graphql';
import { formatDateToDateAndTime } from 'technical/time-utils';

import { useFormatMetricPoint } from './use-format-metric-point';

export const useRingTableColumns = (
  onEditionClick: (id: string | null) => void,
): ColumnsType<RingTableRow> => {
  const { t } = useTranslation();
  const { formatMetricPoint } = useFormatMetricPoint();

  const { currentConstructionSite } = useAppContext();
  const { isDataManager } = usePermissions();
  const isConstructionSiteManager = useIsConstructionSiteManager(
    Module_Enum.ProdPerf,
  );

  const edit = (record: RingTableRow) => {
    onEditionClick(record.id);
  };

  const columns: ColumnsType<RingTableRow> = [
    {
      title: t('pages.ring.table.ringNumber'),
      dataIndex: RingTableColumnKeys.RingNumber,
      key: RingTableColumnKeys.RingNumber,
    },
    {
      title: t('pages.ring.table.keyPosition'),
      dataIndex: RingTableColumnKeys.KeyPosition,
      key: RingTableColumnKeys.KeyPosition,
      render: (_, { keyPosition }) =>
        (currentConstructionSite?.keyPositionPrefix || '') + keyPosition,
    },
    {
      title: t('pages.ring.table.length'),
      dataIndex: RingTableColumnKeys.Length,
      key: RingTableColumnKeys.Length,
      render: (_, { length }) => {
        if (length === 2 && currentConstructionSite?.ringLengthCode1) {
          return currentConstructionSite.ringLengthCode1;
        }
        return currentConstructionSite?.ringLengthCode0;
      },
    },
    {
      title: t('pages.ring.table.buildEndMetricPoint'),
      dataIndex: RingTableColumnKeys.BuildEndMetricPoint,
      key: RingTableColumnKeys.BuildEndMetricPoint,
      render: (_, { buildEndMetricPoint }) =>
        formatMetricPoint(buildEndMetricPoint),
    },
    {
      title: t('pages.ring.table.excavationStart'),
      dataIndex: RingTableColumnKeys.ExcavationStart,
      key: RingTableColumnKeys.ExcavationStart,
      render: (_, { excavationStart }) =>
        formatDateToDateAndTime(excavationStart),
    },
    {
      title: t('pages.ring.table.excavationEnd'),
      dataIndex: RingTableColumnKeys.ExcavationEnd,
      key: RingTableColumnKeys.ExcavationEnd,
      render: (_, { excavationEnd }) => formatDateToDateAndTime(excavationEnd),
    },
    {
      title: t('pages.ring.table.totalExcavationDuration'),
      dataIndex: RingTableColumnKeys.TotalExcavationDuration,
      key: RingTableColumnKeys.TotalExcavationDuration,
      render: (_, { totalExcavationDuration }) =>
        formatRingDuration(totalExcavationDuration),
    },
    {
      title: t('pages.ring.table.realExcavationDuration'),
      dataIndex: RingTableColumnKeys.RealExcavationDuration,
      key: RingTableColumnKeys.RealExcavationDuration,
      render: (_, { realExcavationDuration }) =>
        formatRingDuration(realExcavationDuration),
    },
    {
      title: t('pages.ring.table.buildStart'),
      dataIndex: RingTableColumnKeys.BuildStart,
      key: RingTableColumnKeys.BuildStart,
      render: (_, { buildStart }) => formatDateToDateAndTime(buildStart),
    },
    {
      title: t('pages.ring.table.buildEnd'),
      dataIndex: RingTableColumnKeys.BuildEnd,
      key: RingTableColumnKeys.BuildEnd,
      render: (_, { buildEnd }) => formatDateToDateAndTime(buildEnd),
    },
    {
      title: t('pages.ring.table.totalBuildDuration'),
      dataIndex: RingTableColumnKeys.TotalBuildDuration,
      key: RingTableColumnKeys.TotalBuildDuration,
      render: (_, { totalBuildDuration }) =>
        formatRingDuration(totalBuildDuration),
    },
    {
      title: t('pages.ring.table.realBuildDuration'),
      dataIndex: RingTableColumnKeys.RealBuildDuration,
      key: RingTableColumnKeys.RealBuildDuration,
      render: (_, { realBuildDuration }) =>
        formatRingDuration(realBuildDuration),
    },
  ];

  if (isConstructionSiteManager || isDataManager) {
    columns.push({
      title: t('pages.ring.table.action.title'),
      key: 'editionColumn',
      render: (_, record) => {
        return <EditOutlined onClick={() => edit(record)} />;
      },
    });
  }

  return columns;
};
