import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { ApolloQueryResult } from '@apollo/client';
import { message, Popconfirm, Table, TableProps } from 'antd';
import React from 'react';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import {
  Exact,
  GetGraphSetListQuery,
  GetSavedGraphCountQuery,
  GraphSetTypeEnum_Enum,
  useDeleteSavedGraphSetMutation,
  useGetGraphSetListQuery,
} from 'generated/graphql';
import Button from 'ui/button';
import Flex from 'ui/flex';

import { GraphSetTag } from './components/graph-set-tag';
import styles from './index.module.scss';

interface GraphSetListProps {
  currentGraphSetId: string | null;
  onLoadGraphSet: (graphSetId: string) => void;
  onClose: () => void;
  type: GraphSetTypeEnum_Enum;
  deleteCurrentGraphSet: () => void;
  refetchGraphSetCount: (
    variables?:
      | Partial<
          Exact<{
            constructionSiteId: any;
            userId: any;
            type: GraphSetTypeEnum_Enum;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetSavedGraphCountQuery>>;
}

export const GraphSetList: React.FC<GraphSetListProps> = ({
  currentGraphSetId,
  onLoadGraphSet,
  onClose,
  type,
  deleteCurrentGraphSet,
  refetchGraphSetCount,
}) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const { currentConstructionSite, user } = useAppContext();

  const { data, refetch: graphSetListRefetch } = useGetGraphSetListQuery({
    fetchPolicy: 'network-only',
    variables: {
      constructionSiteId: currentConstructionSite?.id,
      userId: user?.id,
      type: type,
    },
  });

  const [deleteSavedGraphSetMutation] = useDeleteSavedGraphSetMutation();

  const deleteGraphSet = async (id: string) => {
    await deleteSavedGraphSetMutation({
      variables: { id },
      onCompleted: async () => {
        await graphSetListRefetch();
        await refetchGraphSetCount();
        if (id === currentGraphSetId) {
          deleteCurrentGraphSet();
        }
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: t('dataAnalysis.errors.graphSetDeletion'),
        });
      },
    });
  };

  const columns: TableProps<
    GetGraphSetListQuery['graphSet'][number]
  >['columns'] = [
    {
      key: 'name',
      dataIndex: 'name',
    },
    {
      key: 'type',
      dataIndex: 'type',
      render: (graphSetType) => <GraphSetTag type={graphSetType} />,
    },
    {
      key: 'current',
      render: (_, record) =>
        currentGraphSetId === record.id ? <EyeOutlined /> : null,
    },
    {
      key: 'delete',
      align: 'right',
      render: (_, record) => (
        <Popconfirm
          onConfirm={async (event) => {
            event?.stopPropagation();
            await deleteGraphSet(record.id);
          }}
          onCancel={(event) => event?.stopPropagation()}
          title={t('dataAnalysis.myGraphSets.deleteAction.title')}
          okText={t('dataAnalysis.myGraphSets.deleteAction.confirm')}
          cancelText={t('dataAnalysis.myGraphSets.deleteAction.cancel')}
          placement="topLeft"
        >
          <Button
            type="text"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={(event) => event.stopPropagation()}
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Flex column className={styles.container}>
        <Table
          columns={columns}
          dataSource={data?.graphSet}
          showHeader={false}
          pagination={false}
          className={styles.list}
          rowKey={(record) => record.id}
          onRow={(record) => {
            return { onClick: () => onLoadGraphSet(record.id) };
          }}
        />

        <Flex justify={'flex-end'}>
          <Button type={'default'} size={'large'} onClick={onClose}>
            {t('common.close')}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
