import {
  ReloadOutlined,
  LineChartOutlined,
  ExportOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { Affix, Layout, message, Typography } from 'antd';
import { useTranslation } from 'translations/hooks';

import { headerHeight } from 'business/constants';
import { ChangeModeButton } from 'business/data-analysis/components/change-mode-button';
import { PlotterMode } from 'business/data-analysis/constants';
import { sameGraphSet } from 'business/data-analysis/services/graph-set-comparison';
import { useDataAnalysisControllerDuplicateGraphSet } from 'generated/apiComponents';
import { GraphSetStateDto } from 'generated/apiSchemas';
import Button from 'ui/button';
import Flex from 'ui/flex';

import styles from './index.module.scss';

const { Header } = Layout;

const ACTION_OFFSET = headerHeight - 15; // 15 is a magic number to make it works

interface Props {
  mode: PlotterMode;
  currentGraphSetId: string | null;
  setCurrentGraphSetId: (graphSetId: string | null) => void;
  displaySavedGraphs: () => void;
  displaySavedGraphsButtonIsDisabled: boolean;
  generateGraphs: () => void;
  generateButtonIsDisabled: boolean;
  setSaveSideBarVisible: (visible: boolean) => void;
  atLeastOneParameterIsSelected: boolean;
  graphSetTitle: string;
  exportGraphSet: () => void;
  graphSet: GraphSetStateDto;
  setGraphSet: (graphSet: GraphSetStateDto) => void;
  savedGraphSet: GraphSetStateDto | undefined;
  setSavedGraphSet: (graphSet: GraphSetStateDto | undefined) => void;
}

export const DataAnalysisHeader = ({
  mode,
  currentGraphSetId,
  setCurrentGraphSetId,
  displaySavedGraphs,
  displaySavedGraphsButtonIsDisabled,
  generateGraphs,
  generateButtonIsDisabled,
  setSaveSideBarVisible,
  atLeastOneParameterIsSelected,
  graphSetTitle,
  exportGraphSet,
  graphSet,
  setGraphSet,
  savedGraphSet,
  setSavedGraphSet,
}: Props) => {
  const { t } = useTranslation();

  const [messageApi, messageContext] = message.useMessage();

  const { mutate: duplicateGraphSet } =
    useDataAnalysisControllerDuplicateGraphSet();

  const onDuplicationClick = () => {
    if (!currentGraphSetId) {
      return;
    }
    duplicateGraphSet(
      {
        queryParams: {
          id: currentGraphSetId,
        },
      },
      {
        onSuccess: (data) => {
          const graphSetData = {
            ...data,
            title: data.name,
          };

          setGraphSet(graphSetData);
          setSavedGraphSet(graphSetData);
          setCurrentGraphSetId(data.id);
          messageApi.open({
            type: 'success',
            content: t('dataAnalysis.actions.duplication.message'),
          });
        },
        onError: (error) => {
          // @ts-expect-error Front generated conflict error type do not match backend type
          if (error.stack.statusCode === 409) {
            messageApi.open({
              type: 'error',
              content: t('dataAnalysis.errors.duplicationAlreadyExists'),
            });
            return;
          }

          messageApi.open({
            type: 'error',
            content: t('dataAnalysis.errors.duplicationGenericError'),
          });
        },
      },
    );
  };

  return (
    <Header className={styles.header}>
      {messageContext}
      <Typography>{graphSetTitle}</Typography>
      <Flex className={styles.headerButtons} alignItems="center">
        <Flex className={styles.buttonSubSection}>
          <ChangeModeButton mode={mode} />
          <Button
            icon={<LineChartOutlined />}
            iconPosition="end"
            onClick={displaySavedGraphs}
            disabled={displaySavedGraphsButtonIsDisabled}
          >
            {t('dataAnalysis.actions.savedGraph')}
          </Button>
          <Button
            icon={<ExportOutlined />}
            iconPosition="end"
            onClick={exportGraphSet}
            disabled={!atLeastOneParameterIsSelected}
          >
            {t('dataAnalysis.actions.export')}
          </Button>
          <Button
            icon={<CopyOutlined />}
            iconPosition="end"
            onClick={onDuplicationClick}
            disabled={!savedGraphSet || !sameGraphSet(savedGraphSet, graphSet)}
          >
            {t('dataAnalysis.actions.duplication.button')}
          </Button>
        </Flex>
        <Affix offsetTop={ACTION_OFFSET}>
          {/**
           * A native html component is required as a direct children of the Affix
           * It is the same problem as the Popover but no info is present in the documentation
           * cf doc: https://ant.design/components/popover#why-does-the-warning-finddomnode-is-deprecated-some-times-appear-in-strict-mode
           */}
          <div>
            <Button
              icon={<ReloadOutlined />}
              iconPosition="end"
              onClick={generateGraphs}
              disabled={generateButtonIsDisabled}
              className={styles.generateGraphButton}
            >
              {t('dataAnalysis.actions.generateGraphs')}
            </Button>
          </div>
        </Affix>
        <Button
          onClick={() => setSaveSideBarVisible(true)}
          disabled={!atLeastOneParameterIsSelected}
          className={styles.saveGraphSetButton}
        >
          {t('dataAnalysis.actions.saveGraphSet')}
        </Button>
      </Flex>
    </Header>
  );
};
