import groupBy from 'lodash.groupby';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { ILanguage } from 'translations';
import { useTranslation } from 'translations/hooks';

import {
  useAvailableCustomIndicatorsQuery,
  ShiftReportGroupByEnum,
} from 'generated/graphql';

export function useAvailableIndicatorSelection(constructionSiteId?: string) {
  const { language } = useTranslation();
  const { data, loading, error } = useAvailableCustomIndicatorsQuery({
    variables: { constructionSiteId },
    skip: !constructionSiteId,
  });
  const [selection, setSelection] = useState<Array<string>>();

  const list = useMemo(() => {
    const indicators:
      | Array<{
          key: string;
          title: string;
          unit: string;
        }>
      | undefined = data?.indicator.map((indicator) => ({
      key: indicator.id,
      title:
        {
          [ILanguage.fr]: indicator.nameFr,
          [ILanguage.enGB]: indicator.nameEn,
          [ILanguage.enUS]: indicator.nameEn,
          [ILanguage.es]: indicator.nameEs,
        }[language] ?? indicator.nameEn,

      unit: indicator.unit,
    }));

    return indicators ?? [];
  }, [data, language]);

  const select = useCallback((keys: string[]) => {
    setSelection(keys);
  }, []);

  const defaultValues = useMemo(() => list.map(({ key }) => key), [list]);

  useEffect(() => {
    if (selection === undefined && list.length > 0) {
      setSelection(defaultValues);
    }
  }, [defaultValues, list, selection]);

  return {
    list,
    selection: selection ?? [],
    loading: loading || selection === undefined,
    error,
    select,
  };
}

export function getCurrentAnalysisType(
  filteredBy: 'date' | 'ring',
  groupedBy: ShiftReportGroupByEnum,
) {
  if (filteredBy === 'ring') {
    return 'ring';
  }
  switch (groupedBy) {
    case ShiftReportGroupByEnum.Report:
      return 'report';
    case ShiftReportGroupByEnum.Day:
      return 'day';
    case ShiftReportGroupByEnum.Week:
      return 'week';
    case ShiftReportGroupByEnum.Month:
      return 'month';
    default: {
      throw new Error(`group type '${groupedBy}' is invalid.`);
    }
  }
}

interface ComputeChartDataParams {
  indicators: Record<string, any>;
  selected: Array<string>;
  results: Array<any>;
  axis: { x: string; y: string };
  sortBy?: (a: any, b: any) => number;
  transform?: (a: any) => any;
}
export function computeChartData({
  indicators,
  selected,
  results,
  axis,
  sortBy = () => 0,
  transform = (a) => a,
}: ComputeChartDataParams) {
  const header = [
    axis.x,
    ...selected.map((id) => {
      const indicator = indicators[id];
      return `${indicator?.title} (${indicator?.unit})`;
    }),
  ];
  const grouped = groupBy(results, 'group');
  let min = 0;
  let max = 100;
  const rows = Object.entries(grouped)
    .map(([group, data]) => {
      const dataByIndicatorId = Object.fromEntries(data.map((y) => [y.id, y]));
      return [
        transform(group),
        ...selected.map((id) => {
          const { value } = dataByIndicatorId[id];
          if (value < min) {
            min = value;
          }
          if (value > max) {
            max = value;
          }
          return value;
        }),
      ];
    })
    .sort(sortBy);

  return {
    data: [header, ...rows],
    options: {
      hAxis: {
        title: axis.x,
      },
      vAxis: {
        title: axis.y,
        viewWindow: {
          min: Math.min(0, min),
          max: Math.max(100, max),
        },
      },
    },
  };
}
