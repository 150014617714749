import { Flex, Typography } from 'antd';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';

import moduleStyles from 'business/production-and-performance/production-and-performance.module.scss';
import { SimpleCard } from 'ui/SimpleCard';

interface Props {
  title: string;
  header?: ReactNode;
  filters?: ReactNode;
}

export const ProdPerfPageTemplate = ({
  title,
  children,
  header,
  filters,
}: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(moduleStyles.page, 'page-appear')}>
      <Flex
        align="stretch"
        justify="center"
        className={moduleStyles.container}
        vertical
        gap={20}
      >
        <Flex justify="space-between">
          <Typography>{title}</Typography>
          {header}
        </Flex>

        {filters}

        <SimpleCard>{children}</SimpleCard>
      </Flex>
    </div>
  );
};
