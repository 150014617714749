import { Descriptions } from 'antd';
import { useTranslation } from 'translations/hooks';

import { AggregatedReportType } from 'business/report/types';
import { AggregatedShiftReportFragment } from 'generated/graphql';

import { calculAverage } from './services';

interface RingTableProps {
  type: AggregatedReportType;
  report: AggregatedShiftReportFragment;
  nbDays: number;
  nbWeeks: number;
}

function displayAverage(average: number) {
  return Number.isNaN(average) ? 'N/A' : average;
}

export default function RingTable(props: RingTableProps) {
  const { type, report, nbDays, nbWeeks } = props;
  const { t } = useTranslation();

  return (
    <Descriptions
      layout="horizontal"
      size="small"
      className="header-info"
      column={6}
    >
      {/* ring */}
      <Descriptions.Item span={2} label={t('pages.print.startRing')}>
        {report.startRing}
      </Descriptions.Item>
      <Descriptions.Item span={2} label={t('pages.print.endRing')}>
        {report.endRing}
      </Descriptions.Item>
      <Descriptions.Item span={2} label={t('pages.print.totalRing')}>
        {report.totalRing}
      </Descriptions.Item>
      {(type === 'week' || type === 'month') && (
        <Descriptions.Item
          span={type === 'week' ? 6 : 3}
          label={t('pages.print.averageRingPerDay')}
        >
          {displayAverage(calculAverage(report.totalRing, nbDays))}
        </Descriptions.Item>
      )}
      {type === 'month' && (
        <Descriptions.Item span={3} label={t('pages.print.averageRingPerWeek')}>
          {displayAverage(calculAverage(report.totalRing, nbWeeks))}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
}
