import { Empty, Flex } from 'antd';
import dayjs from 'dayjs';
import invariant from 'tiny-invariant';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { RingPerDayAverages } from 'business/production-and-performance/components/ring-per-day-averages';
import { RingPerDayFilter } from 'business/production-and-performance/components/ring-per-day-filters';
import { RingPerDayGraph } from 'business/production-and-performance/components/ring-per-day-graph';
import moduleStyles from 'business/production-and-performance/production-and-performance.module.scss';
import { useRingPerDayControllerRingPerDay } from 'generated/apiComponents';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import Loader from 'ui/loader';

interface Props {
  filters: RingPerDayFilter;
}

export const RingPerDayGraphContainer = ({ filters }: Props) => {
  const { currentConstructionSiteId } = useAppContext();
  invariant(currentConstructionSiteId, 'No constructionSite id');

  const [startDate, endDate] = filters.values ? filters.values : [null, null];

  const { data, isLoading } = useRingPerDayControllerRingPerDay({
    queryParams: {
      constructionSiteId: currentConstructionSiteId,
      dayMode: filters.dayMode,
      // start and end date should not be null as we force to always have values, but input type say it can be null
      startDate: (startDate ? startDate : dayjs()).format(SIMPLE_DATE_FORMAT),
      endDate: (endDate ? endDate : dayjs()).format(SIMPLE_DATE_FORMAT),
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  if (!data || !data.graphData.length) {
    return <Empty />;
  }

  return (
    <Flex vertical gap={24}>
      <RingPerDayAverages
        averagePerDay={data.averagePerDay}
        averagePerDayAndShift={data.averagePerDayAndShift}
      />
      <div className={moduleStyles.graphContainer}>
        <RingPerDayGraph ringPerDayGraphData={data.graphData} />
      </div>
    </Flex>
  );
};
