import dayjs from 'dayjs';
import { GoogleChartWrapperChartType } from 'react-google-charts/dist/types';
import { TFunction } from 'translations/hooks';

import { formatReportDateStringToLocalizedDateString } from 'business/report/services/timeOperations';
import {
  AnalysisType,
  DataRecord,
  GraphMeta,
  ShiftReportGroupByEnumWithRing,
} from 'business/task/pages/TasksAnalyzer/types';
import {
  RingAggregationTasksAnalyzerFullFragment,
  ShiftReportGroupByEnum,
  ShiftReportTasksAnalyzerFullFragment,
} from 'generated/graphql';
import { toPercent } from 'technical/utils/converter';

import { computePieData } from './pieChartServices';
import { getTaskName } from './utils';

function sortEntriesByType(
  analysisType: AnalysisType,
  groupByType?: ShiftReportGroupByEnumWithRing,
) {
  return ([item1Index]: any[], [item2Index]: any[]) => {
    if (item1Index === 'total') {
      return 1;
    }
    if (item2Index === 'total') {
      return -1;
    }
    if (analysisType === 'date') {
      if (groupByType === ShiftReportGroupByEnum.Week) {
        return (
          dayjs(item1Index, 'ww').tz().valueOf() -
          dayjs(item2Index, 'ww').tz().valueOf()
        );
      }
      if (groupByType === ShiftReportGroupByEnum.Month) {
        return (
          dayjs(item1Index, 'MMM').tz().valueOf() -
          dayjs(item2Index, 'MMM').tz().valueOf()
        );
      }
      return (
        dayjs(item1Index, 'L').tz().valueOf() -
        dayjs(item2Index, 'L').tz().valueOf()
      );
    }
    return item1Index - item2Index;
  };
}

function getDateAnalysisFormatedIndex(
  groupByType: ShiftReportGroupByEnumWithRing,
  groupByValue?: number | null,
  year?: number | null,
): string | null {
  if (!year || !groupByValue) {
    return null;
  }
  if (groupByType === ShiftReportGroupByEnum.Week) {
    return dayjs.tz().year(year).week(groupByValue).format('ww');
  }
  if (groupByType === ShiftReportGroupByEnum.Month) {
    return (
      dayjs
        .tz()
        .year(year)
        // In JS month are from 0 to 11 but in postgres from 1 to 12
        // Therefore we remove 1
        .month(groupByValue - 1)
        .format('MMM')
    );
  }
  // Default to day if missing => groupByType === ShiftReportGroupByEnum.Day
  return dayjs.tz().year(year).dayOfYear(groupByValue).format('L');
}

function formatListDataHeader(
  chartRawData: { activityName: string; activityNameExport: string }[],
  isExportData: boolean,
) {
  return chartRawData.reduce(
    (acc: string[], task) =>
      acc.includes(getTaskName(task, isExportData))
        ? acc
        : [...acc, getTaskName(task, isExportData)],
    [],
  );
}

function getRingAggregationGroupByValue(
  task: RingAggregationTasksAnalyzerFullFragment,
) {
  return { groupByValue: task.ring ?? undefined, rawGroupByValue: task.ring };
}

function getDateAggregationGroupByValue(
  task: ShiftReportTasksAnalyzerFullFragment,
  groupByType: ShiftReportGroupByEnumWithRing,
) {
  const groupByValue =
    groupByType === ShiftReportGroupByEnum.Report && task.shiftReportDate
      ? `${formatReportDateStringToLocalizedDateString(
          task.shiftReportDate,
        )} - ${task.shiftName}`
      : getDateAnalysisFormatedIndex(groupByType, task.groupByValue, task.year);

  const rawGroupByValue =
    groupByType === ShiftReportGroupByEnum.Report && task.shiftReportDate
      ? `${formatReportDateStringToLocalizedDateString(
          task.shiftReportDate,
        )} - ${task.shiftName}`
      : task.groupByValue ?? undefined;

  return { groupByValue, rawGroupByValue };
}

export function getTotalAvailability(
  buildTime: number,
  excavationTime: number,
  totalTime: number,
) {
  return (buildTime + excavationTime) / totalTime;
}

export function getTotalAvailabilityFromChartData(
  analysisType: AnalysisType,
  groupByType: ShiftReportGroupByEnumWithRing,
  chartTasks?:
    | ShiftReportTasksAnalyzerFullFragment[]
    | RingAggregationTasksAnalyzerFullFragment[]
    | null,
  groupBy?: string | number,
) {
  if (!chartTasks) {
    return 0;
  }

  let buildTime = 0;
  let excavationTime = 0;
  let totalTime = 0;
  chartTasks.forEach(
    (
      task:
        | ShiftReportTasksAnalyzerFullFragment
        | RingAggregationTasksAnalyzerFullFragment,
    ) => {
      let groupByValue: string | number | undefined;
      if (analysisType === 'date') {
        ({ rawGroupByValue: groupByValue } = getDateAggregationGroupByValue(
          task as ShiftReportTasksAnalyzerFullFragment,
          groupByType,
        ));
      } else {
        ({ groupByValue } = getRingAggregationGroupByValue(
          task as RingAggregationTasksAnalyzerFullFragment,
        ));
      }

      if (groupByValue === groupBy || !groupBy) {
        if (task.type === 'build') {
          buildTime += task.total;
        }
        if (task.type === 'excavation') {
          excavationTime += task.total;
        }
        totalTime += task.total;
      }
    },
  );

  return toPercent(getTotalAvailability(buildTime, excavationTime, totalTime));
}

function formatRingDataRecord(
  data: DataRecord,
  header: string[],
  _analysisType: AnalysisType,
  chartType: GoogleChartWrapperChartType,
  _groupByType: ShiftReportGroupByEnumWithRing,
  chartRingRawData: RingAggregationTasksAnalyzerFullFragment[],
  isExportData: boolean,
) {
  return chartRingRawData.reduce((acc: DataRecord, task) => {
    const groupByValue = task.ring;

    if (groupByValue == null) {
      return acc;
    }
    if (!acc[groupByValue]) {
      acc[groupByValue] = new Array(header.length).fill(0);
    }

    const index = header.findIndex(
      (name) => name === getTaskName(task, isExportData),
    );
    acc[groupByValue][index] = task.total;

    if (chartType === 'Table') {
      acc.total[index] += task.total;
    }

    return acc;
  }, data);
}

function formatDateDataRecord(
  data: DataRecord,
  header: string[],
  _analysisType: AnalysisType,
  chartType: GoogleChartWrapperChartType,
  groupByType: ShiftReportGroupByEnumWithRing,
  chartDateRawData: ShiftReportTasksAnalyzerFullFragment[],
  isExportData: boolean,
) {
  return chartDateRawData.reduce((acc: DataRecord, task) => {
    const { groupByValue } = getDateAggregationGroupByValue(task, groupByType);

    if (!groupByValue) {
      return acc;
    }
    if (!acc[groupByValue]) {
      acc[groupByValue] = new Array(header.length).fill(0);
    }

    const index = header.findIndex(
      (name) => name === getTaskName(task, isExportData),
    );
    acc[groupByValue][index] = task.total;

    if (chartType === 'Table') {
      acc.total[index] += task.total;
    }

    return acc;
  }, data);
}

function formatDataRecordToArrayOfFlattenData(
  header: string[],
  data: DataRecord,
  analysisType: AnalysisType,
  groupByType: ShiftReportGroupByEnumWithRing,
  t: TFunction,
) {
  // Transform data part to [groupByValue, ...dataArray]
  const flattenData = Object.entries(data)
    .sort(sortEntriesByType(analysisType, groupByType))
    .reduce(
      (acc, taskList) => {
        const [index, dataArray] = taskList;

        // TODO: ring as number index but create NaN for total
        // const castIndex =
        //   analysisType === 'date' || index === 'total'
        //     ? index
        //     : parseInt(index, 10);

        return [...acc, [index, ...dataArray]];
      },
      [] as (string | number)[][],
    );

  // Create final data to [[header with groupByType], ...[data part]]
  return [
    [t(`tasksAnalyzer.groupByOptions.${groupByType.toLowerCase()}`), ...header],
    ...flattenData,
  ];
}

export function formatLineData(
  graphRawMeta: GraphMeta,
  { t, isExportData = false }: { t: TFunction; isExportData?: boolean },
) {
  const {
    groupByType,
    chartType,
    analysisType,
    chartDateRawData,
    chartRingRawData,
  } = graphRawMeta;

  // Date format
  if (analysisType === 'date') {
    if (!chartDateRawData) {
      return undefined;
    }
    const header = formatListDataHeader(chartDateRawData, isExportData);

    let data: DataRecord = {};
    if (chartType === 'Table') {
      data.total = new Array(header.length).fill(0);
    }

    // Generate data part as { groupByValue: [dataArray] }
    data = formatDateDataRecord(
      data,
      header,
      analysisType,
      chartType,
      groupByType,
      chartDateRawData,
      isExportData,
    );

    return formatDataRecordToArrayOfFlattenData(
      header,
      data,
      analysisType,
      groupByType,
      t,
    );
  }

  // Ring format
  if (!chartRingRawData) {
    return undefined;
  }
  const header = formatListDataHeader(chartRingRawData, isExportData);

  let data: DataRecord = {};
  if (chartType === 'Table') {
    data.total = new Array(header.length).fill(0);
  }

  // Generate data part as { groupByValue: [dataArray] }
  data = formatRingDataRecord(
    data,
    header,
    analysisType,
    chartType,
    groupByType,
    chartRingRawData,
    isExportData,
  );

  return formatDataRecordToArrayOfFlattenData(
    header,
    data,
    analysisType,
    groupByType,
    t,
  );
}

function computeLineData(graphRawMeta: GraphMeta, t: TFunction) {
  return {
    data: formatLineData(graphRawMeta, { t }),
    exportData: formatLineData(graphRawMeta, { t, isExportData: true }),
    options: {
      hAxis: {
        title: t(
          `tasksAnalyzer.groupByOptions.${graphRawMeta.groupByType.toLowerCase()}`,
        ),
      },
      vAxis: {
        title: t(`tasksAnalyzer.labels.time`),
        viewWindow: {
          min: graphRawMeta.vChartScale?.[0],
          max: graphRawMeta.vChartScale?.[1],
        },
      },
    },
  };
}

export function computeGraphData(graphRawMeta: GraphMeta, t: TFunction) {
  if (graphRawMeta.chartType === 'PieChart') {
    return computePieData(graphRawMeta, t);
  }
  return computeLineData(graphRawMeta, t);
}
