import { AgCartesianSeriesOptions, AgChartOptions } from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';

import { PlotterMode } from 'business/data-analysis/constants';
import { formatParameterName } from 'business/data-analysis/services/parameter-name-differentiation';
import { parseAndFormatDateForGraph } from 'business/data-analysis/services/parse-and-format-date-for-graph';
import { GraphStateDto, ParameterDto } from 'generated/apiSchemas';

import styles from './index.module.scss';
import { ChartType } from './types';

interface Props {
  graphState: GraphStateDto;
  mode: PlotterMode;
}

const getTimePeriodSerie = (
  parameter: ParameterDto,
): AgCartesianSeriesOptions => {
  return {
    type: ChartType.LINE,
    xKey: 'date',
    yKey: formatParameterName(parameter),
    yName: parameter.name,
    stroke: parameter.color,
    marker: { stroke: parameter.color, fill: parameter.color },
  };
};

const getRingSerie = (parameter: ParameterDto): AgCartesianSeriesOptions => {
  return {
    type: ChartType.BAR,
    xKey: 'ring',
    yKey: formatParameterName(parameter),
    yName: parameter.name,
    fill: parameter.color,
  };
};

const Chart = ({ graphState, mode }: Props) => {
  const getSerie =
    mode === PlotterMode.TIME_PERIOD ? getTimePeriodSerie : getRingSerie;

  const series = graphState.parameters
    .map((parameter): AgCartesianSeriesOptions | undefined => {
      if (!parameter.values?.length) {
        return;
      }

      return getSerie(parameter);
    })
    .filter((parameter): parameter is AgCartesianSeriesOptions => !!parameter);

  const parameterNames = graphState.parameters.map(formatParameterName);

  const data = graphState.parameters.flatMap((parameter) => {
    return parameter.values.map((value) => {
      const { date, ring, ...dataValue } = value;
      return {
        date,
        ring,
        [formatParameterName(parameter)]: Object.values(dataValue)[0],
      };
    });
  });

  const chartOptions: AgChartOptions = {
    data,
    contextMenu: {
      enabled: true,
    },
    zoom: {
      // zomm on where the mouse is
      anchorPointX: 'pointer',
      anchorPointY: 'pointer',
    },
    axes:
      mode === PlotterMode.TIME_PERIOD
        ? [
            {
              label: {
                formatter: parseAndFormatDateForGraph,
              },
              keys: ['date'],
              type: 'time',
              position: 'bottom',
            },
            {
              keys: parameterNames,
              position: 'left',
              type: 'number',
            },
          ]
        : [
            {
              keys: ['ring'],
              type: 'category',
              position: 'bottom',
            },
            {
              keys: parameterNames,
              position: 'left',
              type: 'number',
            },
          ],
    series,
  };

  return (
    <div className={styles.chartContainer}>
      <AgCharts options={chartOptions} />
    </div>
  );
};
export default Chart;
