import { TablePaginationConfig } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { useEffect } from 'react';

import { RangeFilters } from 'business/production-and-performance/components/filter-by-ranges-inputs';
import { Parameter_Ring_Bool_Exp, useGetRingsQuery } from 'generated/graphql';
import { validateRingRange } from 'technical/validation/rules';

export type PaginationProps = Required<
  Pick<TablePaginationConfig, 'current' | 'pageSize'>
>;

export const useGetFilteredAndPaginatedRingsQuery = (
  constructionSiteId: string,
  pagination: PaginationProps,
  filter: RangeFilters,
) => {
  const [messageApi, contextHolder] = useMessage();

  const combinedFilters: Parameter_Ring_Bool_Exp = {};

  if (
    filter.filterType === 'ring' &&
    filter.values &&
    validateRingRange(filter.values)
  ) {
    const [min, max] = filter.values;
    combinedFilters.ringNumber = {
      _gte: min === null ? undefined : min,
      _lte: max === null ? undefined : max,
    };
  }

  if (filter.filterType === 'date' && filter.values) {
    const [min, max] = filter.values;
    combinedFilters.buildEnd = {
      _gte: min === null ? undefined : min,
      _lte: max === null ? undefined : max,
    };
  }

  const { data, loading, error } = useGetRingsQuery({
    variables: {
      constructionSiteId,
      limit: pagination.pageSize,
      offset: (pagination.current - 1) * pagination.pageSize,
      filters: combinedFilters,
    },
  });

  useEffect(() => {
    if (error) {
      messageApi.open({
        type: 'error',
        content: error.message,
      });
    }
  }, [error, messageApi]);

  return { data, loading, error, messageContextHolder: contextHolder };
};
