import { ApolloQueryResult } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'translations/hooks';

import { GraphSetList } from 'business/data-analysis/components/graph-set-list';
import GraphSetSideBar from 'business/data-analysis/components/graph-set-side-bar';
import {
  PlotterMode,
  mapPlotterModeToGraphSetType,
} from 'business/data-analysis/constants';
import {
  Exact,
  GetSavedGraphCountQuery,
  GraphSetTypeEnum_Enum,
} from 'generated/graphql';

interface GraphListSideBarProps {
  visible: boolean;
  onClose: () => void;
  currentGraphSetId: string | null;
  onLoadGraphSet: (graphSetId: string) => void;
  deleteGraphSet: () => void;
  refetchGraphSetCount: (
    variables?:
      | Partial<
          Exact<{
            constructionSiteId: any;
            userId: any;
            type: GraphSetTypeEnum_Enum;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetSavedGraphCountQuery>>;
  mode: PlotterMode;
}

export const GraphListSideBar: React.FC<GraphListSideBarProps> = ({
  visible,
  onClose,
  currentGraphSetId,
  onLoadGraphSet,
  deleteGraphSet,
  refetchGraphSetCount,
  mode,
}) => {
  const { t } = useTranslation();

  return (
    <GraphSetSideBar
      title={t('dataAnalysis.myGraphSets.title')}
      visible={visible}
      onClose={onClose}
    >
      {visible && (
        <GraphSetList
          onClose={onClose}
          currentGraphSetId={currentGraphSetId}
          type={mapPlotterModeToGraphSetType[mode]}
          onLoadGraphSet={onLoadGraphSet}
          deleteCurrentGraphSet={deleteGraphSet}
          refetchGraphSetCount={refetchGraphSetCount}
        />
      )}
    </GraphSetSideBar>
  );
};
