import { PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  ConfigProvider,
  Dropdown,
  Layout,
  MenuProps,
  Select,
} from 'antd';
import classnames from 'classnames';
import { useEffect } from 'react';
import { ILanguage } from 'translations';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { login, logout, signUp } from 'business/user/services/authentication';
import config from 'config';
import AppLogo from 'ui/appLogo';
import Flex from 'ui/flex';
import Menu from 'ui/navBar/components/menu';

import styles from './index.module.scss';

const { Header } = Layout;

export interface NavSection {
  label: string;
  path: string;
}

interface Props {
  isConnected?: boolean;
  title?: string;
  constructionSiteId?: string;
}

interface LanguageData {
  label: string;
  value: ILanguage;
  flag: string;
}

const AVAILABLE_LANGUAGES: LanguageData[] = [
  { label: 'UK', value: ILanguage.enGB, flag: '🇬🇧' },
  { label: 'US', value: ILanguage.enUS, flag: '🇺🇸' },
  { label: 'FR', value: ILanguage.fr, flag: '🇫🇷' },
  { label: 'ES', value: ILanguage.es, flag: '🇪🇸' },
];
const DEFAULT_LANGUAGE = AVAILABLE_LANGUAGES[0];

function getFlagDisplay(originalLang?: LanguageData) {
  const lang = originalLang ?? DEFAULT_LANGUAGE;
  return `${lang.flag} ${lang.label}`;
}

export default function FullNavBar({
  isConnected,
  title,
  constructionSiteId,
}: Readonly<Props>) {
  const { t, language, changeLanguage } = useTranslation();
  const {
    currentConstructionSite,
    constructionSites,
    setCurrentConstructionSiteId,
    user,
  } = useAppContext();

  useEffect(() => {
    if (constructionSiteId) {
      setCurrentConstructionSiteId(constructionSiteId);
    }
  });

  const userInitals =
    user?.firstName && user?.lastName ? (
      `${user.firstName[0]}${user.lastName[0]}`
    ) : (
      <UserOutlined />
    );
  const userNameDisplayed =
    user?.firstName && user?.lastName
      ? `${user.firstName} ${user.lastName}`
      : user?.email;

  const logo = currentConstructionSite?.logo;
  const name = currentConstructionSite?.name;
  function handleLoginOnClick() {
    login();
  }

  function handleRegisterOnClick() {
    signUp();
  }

  function handleConstructionSiteChange(value: string): void {
    const constructionSite = constructionSites.find((cs) => cs.id === value);
    if (constructionSite) {
      setCurrentConstructionSiteId(constructionSite.id);
    }
  }

  const menu: MenuProps['items'] = [
    {
      key: 'name',
      label: userNameDisplayed,
      disabled: true,
    },
    {
      key: 'disconnect',
      danger: true,
      onClick: () => logout(),
      label: (
        <>
          <PoweroffOutlined style={{ fontSize: '14px' }} /> {t('user.logout')}
        </>
      ),
    },
  ];

  return (
    <Header className={classnames(styles.header, 'elevation2')}>
      <div className={styles.headerContent}>
        <div className={styles.headerLeft}>
          <AppLogo />
          {logo && (
            <AppLogo
              alt="Construction site logo"
              path={`${config.gcp.publicUri}/${logo}`}
            />
          )}
          {!!constructionSites.length ? <Menu /> : null}
        </div>
        <Flex className={styles.title} column alignItems="center">
          {!constructionSiteId && constructionSites?.length > 1 ? (
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    colorText: '#012169',
                  },
                },
              }}
            >
              <Select
                popupMatchSelectWidth={false}
                size="large"
                className={styles.select}
                onChange={handleConstructionSiteChange}
                defaultValue={
                  currentConstructionSite?.id || constructionSites[0].id
                }
                options={constructionSites
                  .concat()
                  .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                  )
                  .map((constructionSite) => ({
                    value: constructionSite.id,
                    label: constructionSite.name,
                  }))}
              />
            </ConfigProvider>
          ) : null}
          {constructionSiteId ||
          (constructionSites && constructionSites.length === 1) ? (
            <h1>{name}</h1>
          ) : null}
          <h1 className={styles.pageTitle}>{title}</h1>
        </Flex>
        {isConnected ? (
          <div className={styles.headerRight}>
            <Select
              popupMatchSelectWidth={false}
              size="middle"
              className={styles.select}
              defaultValue={getFlagDisplay(
                AVAILABLE_LANGUAGES.filter(
                  (lang) => lang.value === language,
                )[0],
              )}
              onChange={(value) => {
                changeLanguage(value);
              }}
              variant="borderless"
              options={AVAILABLE_LANGUAGES.map((lang) => ({
                value: lang.value,
                label: `${lang.flag} ${lang.label}`,
              }))}
            />

            <Dropdown menu={{ items: menu }} trigger={['click']}>
              <button
                style={{ border: 'initial', background: 'initial' }}
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Avatar style={{ backgroundColor: '#003a8c' }}>
                  {userInitals}
                </Avatar>
              </button>
            </Dropdown>
          </div>
        ) : (
          <div className={styles.headerRight}>
            <Button onClick={handleLoginOnClick}>
              {t('notConnected.login.button')}
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              onClick={handleRegisterOnClick}
              type="primary"
            >
              {t('notConnected.signup')}
            </Button>
          </div>
        )}
      </div>
    </Header>
  );
}
