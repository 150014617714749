import { ExportOutlined } from '@ant-design/icons';
import { message } from 'antd';
import dayjs from 'dayjs';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { RingPerDayFilter } from 'business/production-and-performance/components/ring-per-day-filters';
import config from 'config';
import { useRingPerDayControllerGenerateRingPerDayExport } from 'generated/apiComponents';
import { downloadFile } from 'technical/print';
import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import Button from 'ui/button';

interface Props {
  filters: RingPerDayFilter;
}

export const RingPerDayHeader = ({ filters }: Props) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const { currentConstructionSiteId } = useAppContext();
  invariant(currentConstructionSiteId, 'No constructionSite id');

  const { mutate: generateExport } =
    useRingPerDayControllerGenerateRingPerDayExport({
      onSuccess: (data) => {
        downloadFile(`${config.gcp.publicUri}/${data.url}`, data.fileName);
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: t('errors.export_download_error'),
        });
      },
    });

  const [startDate, endDate] = filters.values ?? [null, null];

  const onClick = () => {
    generateExport({
      body: {
        constructionSiteId: currentConstructionSiteId,
        // start and end date should not be null as we force to always have values, but input type say it can be null
        startDate: (startDate ? startDate : dayjs()).format(SIMPLE_DATE_FORMAT),
        endDate: (endDate ? endDate : dayjs()).format(SIMPLE_DATE_FORMAT),
        dayMode: filters.dayMode,
        locale: getLocale(),
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Button icon={<ExportOutlined />} iconPosition="end" onClick={onClick}>
        {t('productionAndPerformance.excavationBuildTime.header.export')}
      </Button>
    </>
  );
};
