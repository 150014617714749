import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'translations/hooks';

import GraphSection from 'business/data-analysis/components/graphCard/components/graph-section';
import Header from 'business/data-analysis/components/graphCard/components/header';
import { PlotterMode } from 'business/data-analysis/constants';
import { UpdateGraphState } from 'business/data-analysis/pages/graph/types';
import { GraphStateDto, ParameterDto } from 'generated/apiSchemas';
import Flex from 'ui/flex';
import TrashIcon from 'ui/icons/trash';
import { SimpleCard } from 'ui/SimpleCard';

import ParameterList from './components/parameter-list';
import styles from './index.module.scss';

interface Props {
  graphState: GraphStateDto;
  addParameter: () => void;
  updateParameter: (updatedParameter: ParameterDto) => void;
  removeParameter: (parameterId: number) => void;
  removeAllParameters: () => void;
  updateGraphState: (state: UpdateGraphState) => void;
  deleteGraph: (index: number) => void;
  disabledDeletion: boolean;
  mode: PlotterMode;
}

export const GraphCard = ({
  graphState,
  addParameter,
  updateGraphState,
  deleteGraph,
  disabledDeletion,
  updateParameter,
  removeParameter,
  removeAllParameters,
  mode,
}: Props) => {
  const { t } = useTranslation();

  return (
    <SimpleCard className={styles.graphCard}>
      <Header
        graphState={graphState}
        disabledDeletion={disabledDeletion}
        updateGraphState={updateGraphState}
        deleteGraph={deleteGraph}
      />

      <Flex className={styles.graphSectionContainer}>
        <div className={styles.parameterSection}>
          <Button
            className={styles.addParameter}
            type="primary"
            onClick={addParameter}
            icon={<PlusOutlined />}
            iconPosition="end"
          >
            {t('dataAnalysis.actions.addParameter')}
          </Button>
          {graphState.parameters.length > 0 ? (
            <Button
              icon={<TrashIcon className={styles.removeAllParametersIcon} />}
              iconPosition="end"
              className={styles.removeAllParameters}
              onClick={removeAllParameters}
            >
              {t('dataAnalysis.actions.removeParameters')}
            </Button>
          ) : null}
          <ParameterList
            graphTitle={graphState.title}
            parameters={graphState.parameters}
            updateParameter={updateParameter}
            removeParameter={removeParameter}
            mode={mode}
          />
        </div>

        <GraphSection graphState={graphState} mode={mode} />
      </Flex>
    </SimpleCard>
  );
};
