import { createContext, Dispatch } from 'react';

import { initialFilters } from 'business/production-and-performance/hooks/excavation-build-time-filters-context/filter-values';
import {
  ExcavationBuildTimeFilter,
  ActionType,
} from 'business/production-and-performance/hooks/excavation-build-time-filters-context/types';

export const ExcavationBuildTimeFiltersValueContext =
  createContext<ExcavationBuildTimeFilter>(initialFilters);

export const ExcavationBuildTimeUpdateFiltersContext = createContext<
  Dispatch<ActionType>
>(() => {});
