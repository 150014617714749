import { CloseCircleOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import { i18n } from 'translations';

import Flex from 'ui/flex';

const UnauthorizedPage = () => (
  <Flex
    style={{ flex: 1, padding: '20px' }}
    justify="center"
    alignItems="center"
  >
    <Result
      icon={<CloseCircleOutlined />}
      title={i18n.t('user.unauthorized.title')}
      subTitle={i18n.t('user.unauthorized.subTitle')}
    />
  </Flex>
);

export default UnauthorizedPage;
