import { ACTIVITY_TYPES } from 'business/activity/types';
import { Task, Activity } from 'generated/graphql';

export function getLastFinishedRing(
  tasks: Array<{
    activity: { type: Activity['type'] };
    ended: Task['ended'];
    ring: Task['ring'];
  }>,
) {
  const endedBuildTasks = tasks.filter(
    (task) => task.activity.type === ACTIVITY_TYPES.BUILD && task.ended,
  );

  if (endedBuildTasks.length > 0) {
    return Math.max(...endedBuildTasks.map((task) => task.ring));
  }

  // if no build task was ended, we fallback to (the last recorded ring - 1)
  const rings = tasks.map((task) => task.ring);

  return Math.max(...rings) - 1;
}
