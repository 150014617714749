import { gql } from '@apollo/client';

export const ActivityFragment = gql`
  fragment ActivityFull on activity {
    id
    name
    parentId
    hidden
    color
    secondaryColor
    showRingNumber
    type
    level
    order
    # Cascader values
    value: id
    label: name
    disabled: hidden
    isAlwaysDisplayedInPdfReport
  }
`;

export const ActivityTreeFragment = gql`
  fragment ActivityTreeFull on activity {
    ...ActivityFull
    parent {
      id
      name
    }
    children: child(order_by: { order: asc }) {
      ...ActivityFull
      parent {
        id
        name
      }
      children: child(order_by: { order: asc }) {
        parent {
          id
          name
        }
        ...ActivityFull
        children: child(order_by: { order: asc }) {
          parent {
            id
            name
          }
          ...ActivityFull
        }
      }
    }
  }
  ${ActivityFragment}
`;

export const ActivityWithoutHiddenTreeFragment = gql`
  fragment ActivityWithoutHiddenTreeFull on activity {
    ...ActivityFull
    parent {
      id
      name
    }
    children: child(
      where: { hidden: { _eq: false } }
      order_by: { order: asc }
    ) {
      ...ActivityFull
      parent {
        id
        name
      }
      children: child(
        where: { hidden: { _eq: false } }
        order_by: { order: asc }
      ) {
        parent {
          id
          name
        }
        ...ActivityFull
        children: child(
          where: { hidden: { _eq: false } }
          order_by: { order: asc }
        ) {
          parent {
            id
            name
          }
          ...ActivityFull
        }
      }
    }
  }
  ${ActivityFragment}
`;

export const ActivityInvertedTreeFragment = gql`
  fragment ActivityInvertedTreeFull on activity {
    ...ActivityFull
    parent {
      ...ActivityFull
      parent {
        ...ActivityFull
        parent {
          ...ActivityFull
        }
      }
    }
  }
  ${ActivityFragment}
`;

export const ActivityMinimalFragment = gql`
  fragment ActivityMinimal on activity {
    id
    name
    key: id
    title: name
  }
`;

export const ActivityExtendedFragment = gql`
  fragment ActivityExtended on activity {
    id
    name
    key: id
    title: name
    hidden
    tasks_aggregate(
      where: {
        shiftReport: { constructionSiteId: { _eq: $constructionSiteId } }
        activity: { hidden: { _eq: true } }
      }
    ) {
      nodes {
        id
        activityId
      }
    }
  }
`;
