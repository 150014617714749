import { Typography, Select, Popconfirm, Button } from 'antd';
import { useTranslation } from 'translations/hooks';

import { DisplayType } from 'business/data-analysis/pages/graph/types';
import { GraphStateDto } from 'generated/apiSchemas';
import Flex from 'ui/flex';
import PencilIcon from 'ui/icons/pencil';
import TrashIcon from 'ui/icons/trash';

import styles from './index.module.scss';

interface Props {
  graphState: GraphStateDto;
  disabledDeletion: boolean;
  updateGraphState: (graphState: GraphStateDto) => void;
  deleteGraph: (index: number) => void;
}

const Header = ({
  graphState,
  disabledDeletion,
  updateGraphState,
  deleteGraph,
}: Props) => {
  const { t } = useTranslation();

  const graphTypeOptions = [
    {
      value: DisplayType.graph,
      label: <span>{t('dataAnalysis.graphType.graph')}</span>,
    },
    {
      value: DisplayType.table,
      label: <span>{t('dataAnalysis.graphType.table')}</span>,
    },
  ];

  return (
    <Flex justify="space-between" className={styles.head}>
      <Flex>
        <Typography.Text
          className={styles.cardTitle}
          editable={{
            icon: <PencilIcon className={styles.cardTitleAction} />,
            tooltip: t('dataAnalysis.graph.header.tooltip'),
            onChange: (title: string) =>
              updateGraphState({ ...graphState, title }),
            triggerType: ['icon'],
          }}
        >
          {graphState.title}
        </Typography.Text>
      </Flex>

      <Flex className={styles.actionContainer}>
        <Select
          className={styles.graphType}
          options={graphTypeOptions}
          value={graphState.displayType}
          onChange={(displayType) =>
            updateGraphState({ ...graphState, displayType })
          }
        />

        <Popconfirm
          title={t('dataAnalysis.graph.header.delete.title')}
          okButtonProps={{ type: 'primary' }}
          placement="topRight"
          onConfirm={() => deleteGraph(graphState.index)}
        >
          <Button
            icon={<TrashIcon className={styles.actionIcon} />}
            disabled={disabledDeletion}
          />
        </Popconfirm>
      </Flex>
      {/* <GraphTypeMenu> */}
    </Flex>
  );
};

export default Header;
