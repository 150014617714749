import dayjs from 'dayjs';

import { LOCALIZED_SHORT_DATE_WITH_FULL_TIME } from 'business/data-analysis/components/graphCard/components/graph-table-view/constants';
import { PlotterMode } from 'business/data-analysis/constants';
import { ParameterDto } from 'generated/apiSchemas';

const formatGraphByPeriodData = (parameters: ParameterDto[]) => {
  // should be equivalent to aggregateGraphDataByDate in back
  const groupedData = parameters.reduce<
    Record<
      string,
      { [key: string]: string | number; date: string; rawDate: number }
    >
  >((acc, parameter) => {
    parameter.values.forEach((value) => {
      const formattedDate = dayjs(value.date).format(
        LOCALIZED_SHORT_DATE_WITH_FULL_TIME,
      );

      // Initialize the object for the given date if it doesn't exist
      if (!acc[formattedDate]) {
        acc[formattedDate] = {
          date: formattedDate,
          rawDate: value.date,
          key: value.date,
        };
      }

      // Add the parameter's value to the correct date entry
      acc[formattedDate][parameter.name] = value[parameter.name];
    });
    return acc;
  }, {});

  return Object.values(groupedData).sort((a, b) => {
    const dateA = dayjs(a.rawDate);
    const dateB = dayjs(b.rawDate);

    return dateA.isBefore(dateB) ? -1 : 1;
  });
};

const formatGraphByRingData = (parameters: ParameterDto[]) => {
  // should be equivalent to aggregateGraphDataByRing in back
  const ringValueMap = parameters.reduce<
    Record<number, { [key: string]: string | number; ring: number }>
  >((acc, parameter) => {
    const parameterIdentifier = `${parameter.name}-${parameter.index}`;
    parameter.values.forEach((value) => {
      const { ring } = value;

      if (!acc[ring]) {
        acc[ring] = {
          ring,
          key: ring,
        };
      }

      // Add the parameter's value to the correct ring entry
      acc[ring][parameterIdentifier] = value[parameterIdentifier];
    });
    return acc;
  }, {});

  // Convert the grouped object into an array and sort by ring
  const sortedData = Object.values(ringValueMap).sort((paramA, paramB) => {
    return paramA.ring - paramB.ring;
  });

  return sortedData;
};

export const formatTableData = (
  parameters: ParameterDto[],
  mode: PlotterMode,
) => {
  if (mode === PlotterMode.TIME_PERIOD) {
    return formatGraphByPeriodData(parameters);
  }

  return formatGraphByRingData(parameters);
};
