const GraphEmptyState = () => (
  <svg
    width="281"
    height="236"
    viewBox="0 0 281 236"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2427_1966)">
      <path
        d="M258.277 230.746L254.097 230.745L252.108 214.622H258.278L258.277 230.746Z"
        fill="#9E616A"
      />
      <path
        d="M259.343 234.798L245.864 234.797V234.627C245.864 233.236 246.417 231.901 247.401 230.917C248.385 229.933 249.719 229.381 251.111 229.381L259.343 229.381L259.343 234.798Z"
        fill="#2F2E41"
      />
      <path
        d="M242.251 230.746L238.071 230.745L236.082 214.622H242.252L242.251 230.746Z"
        fill="#9E616A"
      />
      <path
        d="M243.317 234.798L229.838 234.797V234.627C229.838 233.236 230.391 231.901 231.375 230.917C232.359 229.933 233.693 229.381 235.085 229.381L243.317 229.381L243.317 234.798Z"
        fill="#2F2E41"
      />
      <path
        d="M61.6482 234.174V104.668C61.6482 103.87 61.9651 103.105 62.5291 102.541C63.0931 101.977 63.858 101.66 64.6556 101.66H78.0765C78.8741 101.66 79.6391 101.977 80.2031 102.541C80.7671 103.105 81.0839 103.87 81.0839 104.668V234.512L61.6482 234.174Z"
        fill="#3F3D56"
      />
      <path
        d="M105.975 234.58V131.605C105.975 130.807 106.292 130.042 106.856 129.478C107.42 128.914 108.185 128.597 108.983 128.597H122.404C123.201 128.597 123.966 128.914 124.53 129.478C125.094 130.042 125.411 130.807 125.411 131.605V234.358L105.975 234.58Z"
        fill="#3F3D56"
      />
      <path
        d="M27.2092 112.237C27.0116 112.237 26.8183 112.18 26.6526 112.072C26.4869 111.965 26.3559 111.811 26.2753 111.631C26.1948 111.451 26.1683 111.251 26.1989 111.056C26.2295 110.86 26.316 110.678 26.4478 110.531L71.3224 60.4788L115.485 85.2779L159.911 8.72925L205.168 45.9794C205.272 46.0647 205.358 46.1697 205.421 46.2883C205.484 46.4069 205.524 46.5367 205.537 46.6705C205.55 46.8043 205.536 46.9393 205.497 47.0679C205.458 47.1965 205.394 47.3161 205.308 47.4198C205.223 47.5236 205.118 47.6095 204.999 47.6727C204.881 47.7359 204.751 47.775 204.617 47.788C204.483 47.8009 204.348 47.7873 204.22 47.7481C204.091 47.7088 203.972 47.6446 203.868 47.5591L160.471 11.84L116.242 88.0494L71.7508 63.0657L27.9711 111.897C27.8753 112.004 27.7578 112.09 27.6265 112.148C27.4952 112.207 27.353 112.237 27.2092 112.237Z"
        fill="#3F3D56"
      />
      <path
        d="M27.039 120.761C32.3118 120.761 36.5863 116.487 36.5863 111.214C36.5863 105.941 32.3118 101.667 27.039 101.667C21.7661 101.667 17.4916 105.941 17.4916 111.214C17.4916 116.487 21.7661 120.761 27.039 120.761Z"
        fill="#012169"
      />
      <path
        d="M71.3661 71.3196C76.639 71.3196 80.9135 67.0451 80.9135 61.7722C80.9135 56.4994 76.639 52.2249 71.3661 52.2249C66.0932 52.2249 61.8187 56.4994 61.8187 61.7722C61.8187 67.0451 66.0932 71.3196 71.3661 71.3196Z"
        fill="#CCCCCC"
      />
      <path
        d="M115.693 96.211C120.966 96.211 125.241 91.9365 125.241 86.6636C125.241 81.3907 120.966 77.1162 115.693 77.1162C110.42 77.1162 106.146 81.3907 106.146 86.6636C106.146 91.9365 110.42 96.211 115.693 96.211Z"
        fill="#E6E6E6"
      />
      <path
        d="M160.02 19.8321C165.293 19.8321 169.568 15.5576 169.568 10.2847C169.568 5.01181 165.293 0.737305 160.02 0.737305C154.747 0.737305 150.473 5.01181 150.473 10.2847C150.473 15.5576 154.747 19.8321 160.02 19.8321Z"
        fill="#012169"
      />
      <path
        d="M245.265 90.9378C250.538 90.9378 254.812 86.6633 254.812 81.3904C254.812 76.1175 250.538 71.843 245.265 71.843C239.992 71.843 235.717 76.1175 235.717 81.3904C235.717 86.6633 239.992 90.9378 245.265 90.9378Z"
        fill="#012169"
      />
      <path
        d="M241.889 155.765C245.462 156.195 254.6 152.935 255.096 157.895C259.799 171.74 259.659 224.814 259.659 224.814L252.499 225.155C252.499 225.155 246.777 182.985 244.071 173.963C242.714 184.928 242.951 226.86 242.951 226.86L236.473 227.201C236.473 227.201 230.662 191.494 230.628 181.014C229.922 173.338 231.654 161.962 230.913 154.541C234.456 155.664 238.185 156.08 241.889 155.765Z"
        fill="#3F3D56"
      />
      <path
        d="M194.63 234.121V84.8568C194.63 82.9425 195.976 81.3904 197.637 81.3904H211.058C212.719 81.3904 214.065 82.9422 214.065 84.8568V234.512L194.63 234.121Z"
        fill="#3F3D56"
      />
      <path
        d="M229.733 115.598C228.3 112.668 226.549 109.904 224.513 107.356C224.087 106.859 223.7 106.33 223.353 105.774C222.62 104.502 222.402 103.009 221.938 101.618C220.643 97.7169 217.41 94.5833 216.623 90.5496C216.336 89.0834 213.917 93.3449 214.553 95.163C215.688 98.4534 215.835 101.938 217.281 105.116C218.726 108.294 220.424 111.342 221.672 114.592C222.382 116.437 222.948 118.343 223.988 120.048C225.028 121.752 226.651 123.181 228.611 123.437C228.598 122.201 228.786 120.971 229.167 119.795C229.593 118.452 230.35 116.87 229.733 115.598Z"
        fill="#9E616A"
      />
      <path
        d="M251.184 117.807C251.096 115.396 251.473 112.991 252.292 110.722C252.606 109.852 252.974 108.969 252.944 108.045C252.899 107.186 252.49 106.398 252.118 105.621C250.836 102.944 249.936 100.107 249.05 97.2771L247.345 91.9408C246.789 91.5332 250.035 91.3918 250.727 92.3499C252.991 95.4938 254.706 99.1047 256.5 102.538C256.957 103.357 257.347 104.211 257.666 105.092C258.104 106.521 258.311 108.01 258.28 109.505C258.28 112.297 257.912 115.08 257.53 117.848C257.288 119.584 256.971 121.473 255.658 122.622C254.294 120.876 252.923 119.171 251.184 117.807Z"
        fill="#9E616A"
      />
      <path
        d="M242.169 115.045C246.076 115.045 249.244 111.877 249.244 107.969C249.244 104.062 246.076 100.894 242.169 100.894C238.261 100.894 235.093 104.062 235.093 107.969C235.093 111.877 238.261 115.045 242.169 115.045Z"
        fill="#9E616A"
      />
      <path
        d="M236.417 100.911C236.337 100.696 236.32 100.463 236.365 100.239C236.411 100.014 236.519 99.8074 236.676 99.6409C236.853 99.5017 237.087 99.4498 237.277 99.3169C237.546 99.0905 237.766 98.8117 237.924 98.4975C238.408 97.8211 239.115 97.3378 239.921 97.1333C240.726 96.9287 241.579 97.0162 242.326 97.3802C243.495 97.9922 244.201 99.2176 245.241 100.043C245.769 100.47 246.381 100.788 246.912 101.215C247.976 102.134 248.733 103.357 249.079 104.72C249.424 106.073 249.625 107.458 249.68 108.854C249.735 109.272 249.719 109.698 249.631 110.111C249.524 110.457 249.363 110.785 249.156 111.082C248.087 112.702 246.678 114.172 244.913 115.008C243.147 115.844 241 115.98 239.259 115.105C239.084 115.034 238.933 114.914 238.825 114.759C238.726 114.522 238.702 114.261 238.757 114.011C238.852 113.021 238.656 112.025 238.194 111.144C237.87 110.497 237.384 109.939 237.125 109.269C236.884 108.519 236.78 107.731 236.82 106.944C236.789 104.904 236.928 102.873 236.417 100.911Z"
        fill="#2D293D"
      />
      <path
        d="M17.3208 234.324V162.261C17.3208 161.337 18.6673 160.588 20.3282 160.588H33.7491C35.41 160.588 36.7565 161.338 36.7565 162.261V234.512L17.3208 234.324Z"
        fill="#3F3D56"
      />
      <path
        d="M280.159 235.263H0.840978C0.750545 235.263 0.663816 235.227 0.59987 235.163C0.535924 235.099 0.5 235.012 0.5 234.922C0.5 234.831 0.535924 234.744 0.59987 234.68C0.663816 234.616 0.750545 234.581 0.840978 234.581H280.159C280.249 234.581 280.336 234.616 280.4 234.68C280.464 234.744 280.5 234.831 280.5 234.922C280.5 235.012 280.464 235.099 280.4 235.163C280.336 235.227 280.249 235.263 280.159 235.263H280.159Z"
        fill="#E6E6E6"
      />
      <path
        d="M150.302 234.07V64.6828C150.302 62.5104 151.649 60.7493 153.31 60.7493H166.73C168.391 60.7493 169.738 62.5104 169.738 64.6828V234.512L150.302 234.07Z"
        fill="#012169"
      />
      <path
        d="M214.355 94.2899C216.05 94.2899 217.424 92.916 217.424 91.2211C217.424 89.5263 216.05 88.1523 214.355 88.1523C212.66 88.1523 211.286 89.5263 211.286 91.2211C211.286 92.916 212.66 94.2899 214.355 94.2899Z"
        fill="#A0616A"
      />
      <path
        d="M248.453 92.9259C250.148 92.9259 251.522 91.552 251.522 89.8571C251.522 88.1623 250.148 86.7883 248.453 86.7883C246.758 86.7883 245.384 88.1623 245.384 89.8571C245.384 91.552 246.758 92.9259 248.453 92.9259Z"
        fill="#A0616A"
      />
      <path
        d="M255.91 127.846C256.21 127.572 258.651 113.295 258.636 112.973C258.599 111.214 253.232 109.205 252.158 109.904C251.145 110.564 249.38 118.13 248.245 119.508L247.043 117.406H237.496L236.917 118.48C235.664 117.726 234.537 117.03 234.136 116.8C233.997 116.706 233.847 116.632 233.689 116.58C232.696 116.358 231.765 115.92 230.961 115.298C230.176 114.694 226.537 108.373 225.562 108.54C224.46 108.724 220.745 111.937 220.788 115.019C221.256 115.009 227.339 127.174 227.425 127.627C227.892 129.544 229.434 157.49 229.932 158.793C232.226 158.571 230.415 158.176 232.635 158.793C235.025 159.458 237.548 158.738 240.014 158.472C241.04 158.371 242.071 158.325 243.103 158.336C246.854 158.353 252.207 158.561 255.838 159.492C255.859 157.112 253.769 151.963 252.995 149.716C252.893 149.044 252.897 148.361 253.005 147.691C253.104 146.869 255.1 128.754 255.91 127.846Z"
        fill="#E6E6E6"
      />
    </g>
    <defs>
      <clipPath id="clip0_2427_1966">
        <rect
          width="280"
          height="234.525"
          fill="white"
          transform="translate(0.5 0.737305)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GraphEmptyState;
