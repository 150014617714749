import { ApolloError } from '@apollo/client';
import { Tree, Typography } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { useTranslation } from 'translations/hooks';

import Loader from 'ui/loader';

const { Title } = Typography;

interface Props {
  title: string;
  data: DataNode[];
  checkedKeys: string[];
  defaultChecked?: string[];
  setCheckedKeys: (keys: string[]) => void;
  loading: boolean;
  error: ApolloError | undefined;
}

const CheckableTree = ({
  title,
  data,
  checkedKeys,
  defaultChecked,
  setCheckedKeys,
  loading,
  error,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Title level={3}>{title}</Title>
      {(error || !data) && <div>{t('errors.error_generic')}</div>}
      {loading && <Loader />}
      {data && !loading && (
        <Tree
          checkable
          defaultCheckedKeys={defaultChecked}
          treeData={data}
          checkedKeys={checkedKeys}
          onCheck={(keys) => {
            // @ts-ignore: the created data only have strings as key
            setCheckedKeys(keys);
          }}
        />
      )}
    </>
  );
};

export default CheckableTree;
