import { Result } from 'antd';
import classNames from 'classnames';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import Routes from 'config/routes';
import Button from 'ui/button';
import CapCard from 'ui/CapCard/CapCard';
import Flex from 'ui/flex';

import icon from './endReportIcon.svg';
import styles from './index.module.scss';

function ReportEditEndPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  invariant(id, "id isn't set within the route");
  return (
    <Flex
      justify="center"
      className={classNames('main-bg-image', styles.endReportResultContainer)}
    >
      <CapCard
        className={styles.card}
        title={t('pages.report.chart.operatorValidation.validatedTitle')}
      >
        <Result
          icon={
            <img className={styles.icon} src={icon} alt="result success icon" />
          }
          extra={[
            <Button
              key="primary"
              type="primary"
              onClick={() =>
                navigate(
                  generatePath(Routes.ReportView, {
                    id,
                  }),
                )
              }
            >
              {t('pages.report.chart.managerValidation.generatePdfReport')}
            </Button>,
            <Button
              key="secondary"
              type="primary"
              ghost
              onClick={() => navigate(Routes.App)}
            >
              {t('pages.report.chart.operatorValidation.validatedSecondary')}
            </Button>,
          ]}
        />
      </CapCard>
    </Flex>
  );
}

export default ReportEditEndPage;
