import { EditOutlined } from '@ant-design/icons';
import {
  Alert,
  Col,
  DatePicker,
  Form,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { checkForExistingReport } from 'business/report/services/reportOperations';
import { parseReportDateString } from 'business/report/services/timeOperations';
import { NewShiftReportFormValues } from 'business/report/types';
import { parseShiftTimeStringWithReportDate } from 'business/shift/services/timeOperations';
import Routes from 'config/routes';
import {
  ShiftReportCardInfoFragment,
  ShiftReportCheckFragment,
  useCheckNewReportLazyQuery,
  useCreateNewTaskMutation,
  useGetNewReportFieldsQuery,
  useStartNewReportMutation,
} from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import { formatUserName, SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import { isOnTwoDays } from 'technical/time-utils';
import Button from 'ui/button';
import CapCard from 'ui/CapCard/CapCard';
import Flex from 'ui/flex';
import Loader from 'ui/loader';

import './index.scss';

const { Option } = Select;
const { Paragraph } = Typography;
interface Props {
  previousReport?: ShiftReportCardInfoFragment;
}

function NewReportForm({ previousReport }: Props) {
  const { t } = useTranslation();
  const { user, currentConstructionSite } = useAppContext();
  const navigate = useNavigate();

  const { loading, error, data } = useGetNewReportFieldsQuery({
    variables: {
      constructionSiteId: currentConstructionSite?.id,
    },
    skip: !currentConstructionSite?.id,
  });

  const [checkReport, { data: dataCheck, loading: loadingCheck }] =
    useCheckNewReportLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [addNewReport] = useStartNewReportMutation();
  const [createNewTask] = useCreateNewTaskMutation();
  const [existingReport, setExistingReport] = useState<
    ShiftReportCheckFragment | undefined
  >(undefined);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <Alert message={t('errors.error_generic')} type="error" />;
  }
  if (
    !currentConstructionSite ||
    !data?.operators ||
    !data.shiftLeaders ||
    !data.shifts
  ) {
    return <Alert message={t('errors.error_generic')} type="error" />;
  }
  const {
    operators,
    shiftLeaders,
    shiftEngineers,
    shiftManagers,
    shifts,
    recurringTasks,
  } = data;
  const {
    isManagerRequiredInReport: isManagerRequired,
    isEngineerRequiredInReport: isEngineerRequired,
  } = currentConstructionSite;

  const goToExistingReport = () => {
    if (existingReport?.operatorValidation) {
      return navigate(
        generatePath(Routes.ReportView, { id: existingReport?.id ?? '' }),
      );
    }
    return navigate(
      generatePath(Routes.ReportEdit, { id: existingReport?.id ?? '' }),
    );
  };

  const checkReportValidity = (
    _changedValues: NewShiftReportFormValues,
    values: NewShiftReportFormValues,
  ) => {
    checkForExistingReport(
      t,
      checkReport,
      currentConstructionSite?.id,
      values.date,
      values.shiftId,
    );
  };

  const onFinish = async (values: NewShiftReportFormValues) => {
    if (loadingCheck || !user) {
      return;
    }

    // if yes propose to open the existing report
    if (dataCheck && dataCheck?.shiftReport?.length > 0) {
      setExistingReport(dataCheck?.shiftReport[0]);
    } else {
      // if not create new report
      try {
        const newReport = await addNewReport({
          variables: {
            object: {
              operatorId: values.operatorId,
              shiftLeaderId: values.shiftLeaderId,
              shiftEngineerId: values.shiftEngineerId,
              shiftManagerId: values.shiftManagerId,
              startMetricPoint: values.startMetricPoint,
              date: values.date?.format(SIMPLE_DATE_FORMAT),
              shiftId: values.shiftId,
              userId: user.id,
              userName: formatUserName(user),
              constructionSiteId: currentConstructionSite?.id,
            },
          },
        });
        const shift = shifts.find((s) => s.id === values.shiftId);
        // Creating reccuring Tasks
        if (
          shift &&
          newReport?.data?.shiftReport &&
          recurringTasks.length > 0
        ) {
          const shiftReportDate = parseReportDateString(
            newReport.data.shiftReport.date,
          );
          const shiftStartTime = parseShiftTimeStringWithReportDate(
            shift.startTime,
            shiftReportDate,
          );
          let shiftEndTime = parseShiftTimeStringWithReportDate(
            shift.endTime,
            shiftReportDate,
          );
          if (isOnTwoDays(shift.startTime, shift.endTime)) {
            shiftEndTime = shiftEndTime.add(1, 'day');
          }

          const shiftReportId = newReport.data.shiftReport.id;

          await Promise.all(
            recurringTasks.map((recurringTask) =>
              createNewTask({
                variables: {
                  object: {
                    ring:
                      previousReport?.lastFinishedRing != null
                        ? previousReport.lastFinishedRing + 1
                        : 0,
                    startDate:
                      recurringTask.timeOrigin === 'start'
                        ? shiftStartTime
                            .clone()
                            .add(recurringTask.timeOffset, 'minutes')
                        : shiftEndTime
                            .clone()
                            .subtract(
                              recurringTask.timeOffset + recurringTask.duration,
                              'minutes',
                            ),
                    endDate:
                      recurringTask.timeOrigin === 'start'
                        ? shiftStartTime
                            .clone()
                            .add(
                              recurringTask.timeOffset + recurringTask.duration,
                              'minutes',
                            )
                        : shiftEndTime
                            .clone()
                            .subtract(recurringTask.timeOffset, 'minutes'),
                    duration: recurringTask.duration,
                    note: recurringTask.note,
                    critical: recurringTask.critical,
                    files: [],
                    shiftReportId,
                    activityId: recurringTask.activityId,
                  },
                },
              }),
            ),
          );
        }

        message.success(t('pages.report.new.addNewReportSuccess'));
        navigate(
          generatePath(Routes.ReportEdit, {
            id: newReport.data?.shiftReport?.id,
          }),
        );
      } catch (newTaskError) {
        message.error(t('pages.report.new.addNewReportFail'));
        if (newTaskError instanceof Error) {
          errorReporting.error(newTaskError);
        }
      }
    }
  };

  return (
    <div className="cap-new-report">
      <CapCard title={t('pages.report.new.title')}>
        <Form
          name="basic"
          layout="horizontal"
          size="middle"
          onFinish={onFinish}
          onValuesChange={(changedValues, values) =>
            checkReportValidity(changedValues, values)
          }
          initialValues={{
            operatorId: operators.length === 1 ? operators[0].id : null,
            shiftLeaderId:
              shiftLeaders.length === 1 ? shiftLeaders[0].id : null,
            shiftEngineerId:
              isEngineerRequired && shiftEngineers.length === 1
                ? shiftEngineers[0].id
                : null,
            shiftManagerId:
              isManagerRequired && shiftManagers.length === 1
                ? shiftManagers[0].id
                : null,
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label={t('pages.form.operator.label')}
                name="operatorId"
                rules={[
                  { required: true, message: t('pages.form.operator.alert') },
                ]}
              >
                <Select
                  showSearch
                  placeholder={t('pages.form.operator.placeholder')}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {operators.map((operator) => (
                    <Option value={operator.id} key={operator.id}>
                      {operator.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={t('pages.form.shiftLeader.label')}
                name="shiftLeaderId"
                rules={[
                  {
                    required: true,
                    message: t('pages.form.shiftLeader.alert'),
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={t('pages.form.shiftLeader.placeholder')}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {shiftLeaders.map((shiftLeader) => (
                    <Option value={shiftLeader.id} key={shiftLeader.id}>
                      {shiftLeader.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {isEngineerRequired && (
            <Row>
              <Col span={24}>
                <Form.Item
                  label={t('pages.form.shiftEngineer.label')}
                  name="shiftEngineerId"
                  rules={[
                    {
                      required: true,
                      message: t('pages.form.shiftLeader.alert'),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={t('pages.form.shiftEngineer.placeholder')}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option!.children as unknown as string).includes(
                        input.toLowerCase(),
                      )
                    }
                  >
                    {shiftEngineers.map(({ id, ...rest }) => (
                      <Option value={id} key={id}>
                        {formatUserName(rest)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          {isManagerRequired && (
            <Row>
              <Col span={24}>
                <Form.Item
                  label={t('pages.form.shiftManager.label')}
                  name="shiftManagerId"
                  rules={[
                    {
                      required: true,
                      message: t('pages.form.shiftManager.alert'),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={t('pages.form.shiftManager.placeholder')}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option!.children as unknown as string).includes(
                        input.toLowerCase(),
                      )
                    }
                  >
                    {shiftManagers.map(({ id, ...rest }) => (
                      <Option value={id} key={id}>
                        {formatUserName(rest)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <Form.Item
                label={t('pages.form.shift.label')}
                name="shiftId"
                rules={[
                  { required: true, message: t('pages.form.shift.alert') },
                ]}
              >
                <Select
                  showSearch
                  placeholder={t('pages.form.shift.placeholder')}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(
                      input.toLowerCase(),
                    )
                  }
                >
                  {shifts.map((shift: any) => {
                    return (
                      <Option value={shift.id} key={shift.id}>
                        {shift.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <Form.Item
                label={t('pages.form.startMetricPoint.label')}
                name="startMetricPoint"
                initialValue={
                  previousReport?.endMetricPoint
                    ? previousReport.endMetricPoint
                    : 0
                }
                rules={[
                  {
                    required: true,
                    message: t('pages.form.startMetricPoint.alert'),
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} min={0} step={0.001} />
              </Form.Item>
            </Col>
            <Col span={11} offset={2}>
              <Form.Item
                label={t('pages.form.date.label')}
                name="date"
                initialValue={dayjs()}
                rules={[
                  { required: true, message: t('pages.form.date.alert') },
                ]}
              >
                <DatePicker style={{ width: '100%' }} format="L" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item>
                <Flex alignItems="center" column>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<EditOutlined />}
                  >
                    {t('pages.report.new.startReport')}
                  </Button>
                </Flex>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Modal
          title={t('pages.report.new.existingReport.title')}
          open={!!existingReport}
          onOk={() => goToExistingReport()}
          onCancel={() => setExistingReport(undefined)}
        >
          {existingReport?.operatorValidation && (
            <Paragraph>
              {t('pages.report.new.existingReport.content.edit')}
            </Paragraph>
          )}
          {!existingReport?.operatorValidation && (
            <Paragraph>
              {t('pages.report.new.existingReport.content.view')}
            </Paragraph>
          )}
        </Modal>
      </CapCard>
    </div>
  );
}

export default NewReportForm;
