import {
  AgBarSeriesTooltipRendererParams,
  AgCartesianAxisOptions,
  AgCartesianSeriesOptions,
  AgChartOptions,
  AgTooltipRendererResult,
} from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';
import { TFunction, useTranslation } from 'translations/hooks';

import { useExcavationBuildTimeFiltersValue } from 'business/production-and-performance/hooks/excavation-build-time-filters-context';
import { formatRingDuration } from 'business/production-and-performance/services/ring-time-format';
import { GetExcavationAndBuildingDurationResponseDto } from 'generated/apiSchemas';
import { parseDurationStringToMilliseconds } from 'technical/time-utils';

interface ExcavationBuildTimeGraphProps {
  excavationBuildTimeGraphData: GetExcavationAndBuildingDurationResponseDto['graphData'];
}
export const TIME_DAY_HOUR_MINUTE_FORMAT = 'DD:HH:mm';

const renderDurationString = ({
  datum,
  yKey,
}: AgBarSeriesTooltipRendererParams) => {
  return {
    content: formatRingDuration(datum[yKey]),
  } satisfies AgTooltipRendererResult;
};

const excavationBuildTimeSeriesRealDurationsOptions = (t: TFunction) =>
  [
    {
      type: 'bar',
      xKey: 'ringNumber',
      yKey: 'realExcavationDuration',
      yName: t(
        'productionAndPerformance.excavationBuildTime.graph.seriesNames.realExcavationDuration',
      ),
      stackGroup: 'Real',
      tooltip: {
        renderer: renderDurationString,
      },
    },
    {
      type: 'bar',
      xKey: 'ringNumber',
      yKey: 'realBuildDuration',
      yName: t(
        'productionAndPerformance.excavationBuildTime.graph.seriesNames.realBuildDuration',
      ),
      stackGroup: 'Real',
      tooltip: {
        renderer: renderDurationString,
      },
    },
  ] satisfies AgCartesianSeriesOptions[];

const excavationBuildTimeSeriesTotalDurationsOptions = (t: TFunction) =>
  [
    {
      type: 'bar',
      xKey: 'ringNumber',
      yKey: 'totalExcavationDuration',
      yName: t(
        'productionAndPerformance.excavationBuildTime.graph.seriesNames.totalExcavationDuration',
      ),
      stackGroup: 'Total',
      tooltip: {
        renderer: renderDurationString,
      },
    },
    {
      type: 'bar',
      xKey: 'ringNumber',
      yKey: 'totalBuildDuration',
      yName: t(
        'productionAndPerformance.excavationBuildTime.graph.seriesNames.totalBuildDuration',
      ),
      stackGroup: 'Total',
      tooltip: {
        renderer: renderDurationString,
      },
    },
  ] satisfies AgCartesianSeriesOptions[];

const excavationBuildTimeAxesOptions = [
  {
    type: 'category',
    position: 'bottom',
  },
  {
    type: 'number',
    position: 'left',
    label: {
      formatter: ({ value }) => {
        return formatRingDuration(value);
      },
    },
  },
] satisfies AgCartesianAxisOptions[];

const formatGraphDataWithDuration = (
  excavationBuildTimeGraphData: GetExcavationAndBuildingDurationResponseDto['graphData'],
) =>
  excavationBuildTimeGraphData.map(
    ({
      ringNumber,
      totalExcavationDuration,
      realExcavationDuration,
      totalBuildDuration,
      realBuildDuration,
    }) => {
      return {
        ringNumber: ringNumber,
        totalExcavationDuration: parseDurationStringToMilliseconds(
          totalExcavationDuration,
        ),
        realExcavationDuration: parseDurationStringToMilliseconds(
          realExcavationDuration,
        ),
        totalBuildDuration:
          parseDurationStringToMilliseconds(totalBuildDuration),
        realBuildDuration: parseDurationStringToMilliseconds(realBuildDuration),
      };
    },
  );

export const ExcavationBuildTimeGraph = ({
  excavationBuildTimeGraphData,
}: ExcavationBuildTimeGraphProps) => {
  const { t } = useTranslation();
  const filtersValue = useExcavationBuildTimeFiltersValue();
  const showTotalDurations = filtersValue?.showTotalDurations;

  const realDurationsSeries = excavationBuildTimeSeriesRealDurationsOptions(t);
  const series = realDurationsSeries;

  if (showTotalDurations) {
    const totalDurationsSeries =
      excavationBuildTimeSeriesTotalDurationsOptions(t);
    series.push(...totalDurationsSeries);
  }

  const chartOptions = {
    data: formatGraphDataWithDuration(excavationBuildTimeGraphData),
    series,
    axes: excavationBuildTimeAxesOptions,
  } satisfies AgChartOptions;

  return <AgCharts options={chartOptions} />;
};
