import { Row, Col, Statistic } from 'antd';
import { useTranslation } from 'translations/hooks';

import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';

import styles from './index.module.scss';

interface RingPerDayAveragesProps {
  averagePerDay: string | number | null;
  averagePerDayAndShift: string | number | null;
}

export const RingPerDayAverages = ({
  averagePerDayAndShift,
  averagePerDay,
}: RingPerDayAveragesProps) => {
  const { t } = useTranslation();
  return (
    <Row className={styles.row} gutter={8}>
      <Col span={6}>
        <Statistic
          title={t(
            'productionAndPerformance.ringPerDay.averages.averagePerDay',
          )}
          value={isUndefinedOrNull(averagePerDay) ? 0 : averagePerDay}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title={t(
            'productionAndPerformance.ringPerDay.averages.averagePerDayAndShift',
          )}
          value={
            isUndefinedOrNull(averagePerDayAndShift) ? 0 : averagePerDayAndShift
          }
        />
      </Col>
    </Row>
  );
};
