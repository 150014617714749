import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';

import { formatDurationToReadable } from 'business/report/services/timeOperations';
import { toPercent } from 'technical/utils/converter';

import AggregatedGraph from './AggregatedGraph';
import { getTotalTime } from './services';
import { TopLevelTasksAggregate } from './types';

interface SummaryTableProps {
  displayedTotalTime: string;
  displayedTotalCriticalTime: string;
}

const SummaryTable = ({
  displayedTotalTime,
  displayedTotalCriticalTime,
}: Readonly<SummaryTableProps>) => {
  const { t } = useTranslation();

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}>
        {t('pages.print.totalTime')}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1}>{displayedTotalTime}</Table.Summary.Cell>
      <Table.Summary.Cell index={2} />
      <Table.Summary.Cell index={3}>
        {displayedTotalCriticalTime}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={4} />
    </Table.Summary.Row>
  );
};

interface TableData {
  key: string;
  name: string;
  totalTime: number;
  relativeTime: number;
  totalCriticalTime: number;
  relativeCriticalTime: number;
}

interface ActivityAndGraphProps {
  topLevelTasksAggregate: TopLevelTasksAggregate[];
  topLevelCriticalTasksAggregate: TopLevelTasksAggregate[];
  critical: boolean;
}

export default function ActivityAndGraph({
  topLevelTasksAggregate,
  topLevelCriticalTasksAggregate,
  critical,
}: Readonly<ActivityAndGraphProps>) {
  const { t } = useTranslation();

  const totalTime = getTotalTime(topLevelTasksAggregate);
  const totalCriticalTime = getTotalTime(topLevelCriticalTasksAggregate);

  const aggregatedTasksWithoutCritical = topLevelTasksAggregate.reduce(
    (acc, task) => {
      const relativeTime = toPercent(task.total / totalTime);
      return [
        ...acc,
        {
          key: task.id,
          name: task.name,
          totalTime: task.total,
          relativeTime: task.total ? relativeTime : 0,
          totalCriticalTime: 0,
          relativeCriticalTime: 0,
        },
      ];
    },
    [] as TableData[],
  );

  const aggregatedTasks = topLevelCriticalTasksAggregate.reduce((acc, task) => {
    const existingTableTaskEntry = acc.find(({ name }) => name === task.name);
    const relativeCriticalTime = toPercent(task.total / totalCriticalTime);
    if (existingTableTaskEntry) {
      const existingEntryIndex = acc.findIndex(
        ({ name }) => name === task.name,
      );
      acc[existingEntryIndex] = {
        ...existingTableTaskEntry,
        totalCriticalTime: task.total,
        relativeCriticalTime: task.total ? relativeCriticalTime : 0,
      };
      return acc;
    }
    return [
      ...acc,
      {
        key: task.id,
        name: task.name,
        totalTime: 0,
        relativeTime: 0,
        totalCriticalTime: task.total,
        relativeCriticalTime: task.total ? relativeCriticalTime : 0,
      },
    ];
  }, aggregatedTasksWithoutCritical);

  const tableColumnsWithDescription: ColumnsType<TableData> = [
    {
      title: t('pages.print.taskName'),
      dataIndex: 'name',
    },
    {
      title: t('pages.print.taskTime'),
      dataIndex: 'totalTime',
      render: (node: any) => formatDurationToReadable(node),
    },
    {
      title: t('pages.print.relativeTime'),
      dataIndex: 'relativeTime',
      render: (node: any) => `${node}%`,
    },
    {
      title: t('pages.print.taskCriticalTime'),
      dataIndex: 'totalCriticalTime',
      render: (node: any) => formatDurationToReadable(node),
    },
    {
      title: t('pages.print.relativeCriticalTime'),
      dataIndex: 'relativeCriticalTime',
      render: (node: any) => `${node}%`,
    },
  ];

  const displayedTotalTime = formatDurationToReadable(
    dayjs.duration(totalTime, 'minutes').asMinutes(),
  );
  const displayedTotalCriticalTime = formatDurationToReadable(
    dayjs.duration(totalCriticalTime, 'minutes').asMinutes(),
  );

  return (
    <>
      <div key="activity-container" className="bloc">
        <h1>
          {t('pages.print.activityNotes', {
            context: critical ? 'critical' : '',
          })}
        </h1>
        <Table
          columns={tableColumnsWithDescription}
          dataSource={aggregatedTasks}
          bordered
          pagination={false}
          size="small"
          summary={() => (
            <SummaryTable
              displayedTotalTime={displayedTotalTime}
              displayedTotalCriticalTime={displayedTotalCriticalTime}
            />
          )}
        />
      </div>
      <AggregatedGraph
        topLevelTasksAggregate={topLevelCriticalTasksAggregate}
        critical
      />
    </>
  );
}
