import { Card } from 'antd';
import classNames from 'classnames';

import styles from './index.module.scss';

interface Props {
  children: any;
  className?: string;
}

export const SimpleCard = ({ className, children }: Props) => {
  return (
    <Card bordered={false} className={classNames(styles.card, className)}>
      {children}
    </Card>
  );
};
