import { Descriptions } from 'antd';
import { useTranslation } from 'translations/hooks';

import { AggregatedReportType } from 'business/report/types';
import { AggregatedShiftReportFragment } from 'generated/graphql';

import { calculAverage } from './services';

interface ChainingTableProps {
  type: AggregatedReportType;
  report: AggregatedShiftReportFragment;
  nbDays: number;
  nbWeeks: number;
}

function displayAverage(average: number, distanceUnit: string) {
  return Number.isNaN(average) ? 'N/A' : `${average}${distanceUnit}`;
}

export default function ChainingTable(props: ChainingTableProps) {
  const { type, report, nbDays, nbWeeks } = props;
  const { t } = useTranslation();

  return (
    <Descriptions
      layout="horizontal"
      size="small"
      className="header-info"
      column={6}
    >
      {/* distance */}
      <Descriptions.Item span={2} label={t('pages.print.startMetricPoint')}>
        {report.startMetricPoint}
      </Descriptions.Item>
      <Descriptions.Item span={2} label={t('pages.print.endMetricPoint')}>
        {report.endMetricPoint}
      </Descriptions.Item>
      <Descriptions.Item span={2} label={t('pages.print.totalDistance')}>
        {report.distance + report.distanceUnit}
      </Descriptions.Item>
      {(type === 'week' || type === 'month') && (
        <Descriptions.Item
          span={type === 'week' ? 6 : 3}
          label={t('pages.print.averageDistancePerDay')}
        >
          {displayAverage(
            calculAverage(report.distance, nbDays),
            report.distanceUnit,
          )}
        </Descriptions.Item>
      )}
      {type === 'month' && (
        <Descriptions.Item
          span={3}
          label={t('pages.print.averageDistancePerWeek')}
        >
          {displayAverage(
            calculAverage(report.distance, nbWeeks),
            report.distanceUnit,
          )}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
}
