import { PropsWithChildren, useContext, useReducer } from 'react';

import {
  ExcavationBuildTimeFiltersValueContext,
  ExcavationBuildTimeUpdateFiltersContext,
} from 'business/production-and-performance/hooks/excavation-build-time-filters-context/contexts';
import { initialFilters } from 'business/production-and-performance/hooks/excavation-build-time-filters-context/filter-values';
import { filtersReducer } from 'business/production-and-performance/hooks/excavation-build-time-filters-context/reducer';
import {
  ExcavationBuildTimeDispatchActionTypes,
  ExcavationBuildTimeFilter,
} from 'business/production-and-performance/hooks/excavation-build-time-filters-context/types';

const ExcavationBuildTimeFiltersProvider = ({
  children,
}: PropsWithChildren) => {
  const [filters, dispatchFilters] = useReducer(filtersReducer, initialFilters);

  return (
    <ExcavationBuildTimeFiltersValueContext.Provider value={filters}>
      <ExcavationBuildTimeUpdateFiltersContext.Provider value={dispatchFilters}>
        {children}
      </ExcavationBuildTimeUpdateFiltersContext.Provider>
    </ExcavationBuildTimeFiltersValueContext.Provider>
  );
};

function useExcavationBuildTimeFiltersValue() {
  return useContext(ExcavationBuildTimeFiltersValueContext);
}

function useExcavationBuildTimeFiltersDispatch() {
  return useContext(ExcavationBuildTimeUpdateFiltersContext);
}

export {
  ExcavationBuildTimeFiltersProvider,
  useExcavationBuildTimeFiltersValue,
  useExcavationBuildTimeFiltersDispatch,
  ExcavationBuildTimeDispatchActionTypes,
  type ExcavationBuildTimeFilter,
};
