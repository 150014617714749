/**
 * Utility function to create a number formatter based on language and formatting options
 * @param language locale or user locals
 */
export const createNumberFormatter = (
  language: string | string[],
): Intl.NumberFormat => {
  return new Intl.NumberFormat(language, {
    maximumFractionDigits: 3, // Limits the number of fraction digits to 3
  });
};
