import { useTranslation } from 'translations/hooks';

import { ExcavationBuildTimeFilters } from 'business/production-and-performance/components/excavation-build-time-filters';
import { ExcavationBuildTimeGraphContainer } from 'business/production-and-performance/components/excavation-build-time-graph-container';
import { ExcavationBuildTimeHeader } from 'business/production-and-performance/components/excavation-build-time-header';
import { ProdPerfPageTemplate } from 'business/production-and-performance/components/prod-perf-page-template';
import { ExcavationBuildTimeFiltersProvider } from 'business/production-and-performance/hooks/excavation-build-time-filters-context';
import { FilterCard } from 'ui/filter-card';

const ExcavationBuildTimePage = () => {
  const { t } = useTranslation();

  return (
    <ExcavationBuildTimeFiltersProvider>
      <ProdPerfPageTemplate
        title={t('productionAndPerformance.excavationBuildTime.title')}
        header={<ExcavationBuildTimeHeader />}
        filters={
          <FilterCard>
            <ExcavationBuildTimeFilters />
          </FilterCard>
        }
      >
        <ExcavationBuildTimeGraphContainer />
      </ProdPerfPageTemplate>
    </ExcavationBuildTimeFiltersProvider>
  );
};

export default ExcavationBuildTimePage;
