import { Spin } from 'antd';

import Flex from 'ui/flex';

import styles from './index.module.scss';

interface Props {
  label?: string;
}

const Loader = ({ label }: Props) => (
  <Flex className={styles.loader} column>
    <Spin />
    <h1>{label}</h1>
  </Flex>
);

export default Loader;
