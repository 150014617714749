import { Flex, Typography } from 'antd';
import classNames from 'classnames';

import Card from 'ui/card';

import styles from './index.module.scss';

interface CardValueProps {
  title: string;
  value: string;
  footer?: string;
  fullWidth?: boolean;
}

const CardValue: React.FC<CardValueProps> = ({
  title,
  value,
  footer,
  fullWidth = false,
}) => {
  return (
    <Card
      type={'inner'}
      className={classNames([
        styles.cardValue,
        { [styles.fullWidth]: fullWidth },
      ])}
    >
      <Flex vertical gap={10}>
        <Typography.Text className={styles.title}>{title}</Typography.Text>
        <Typography.Text className={styles.value}>{value}</Typography.Text>
        {footer ? (
          <Typography.Text className={styles.footer}>{footer}</Typography.Text>
        ) : null}
      </Flex>
    </Card>
  );
};

export default CardValue;
