import React from 'react';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';

import Page from './page';

interface Props {
  header?: React.ReactNode;
  children?: React.ReactNode;
  img?: { src: string; alt: string };
  footer?: React.ReactNode;
}

function ConnectedPage(props: Props) {
  const { isConnected } = useAppContext();
  const { t } = useTranslation();

  const reportTitle = t('pages.report.navReport');

  return <Page {...props} isConnected={isConnected} title={reportTitle} />;
}

export default ConnectedPage;
