import {
  ConstructionSiteFieldsFragment,
  CommonUserFieldsFragment,
  Module_Enum,
} from 'generated/graphql';

import { ROLES } from './config';

export const userHasRole = (
  role: ROLES,
  constructionSite: ConstructionSiteFieldsFragment | null,
  user?: CommonUserFieldsFragment,
  // Default to ShiftReport for backward compatibility
  module: Module_Enum = Module_Enum.ShiftReport,
) => {
  if (!user || !constructionSite) {
    return false;
  }
  return constructionSite.userPermissions.some(
    (permission) =>
      permission.userId === user.id &&
      permission.permissionType.name === role &&
      permission.module === module,
  );
};

export const userIsDataManager = (allowedRoles: ROLES[]) =>
  allowedRoles.includes(ROLES.DATA_MANAGER);
