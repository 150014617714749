import logger from 'technical/logger';

export function downloadFile(url: string, name?: string) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobURL;
      a.download = name ?? 'defaultFileName.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
    .catch((e) => logger.error(e));
}
