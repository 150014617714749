import config from 'config';

const PROD_LOGGER = {
  info: () => {},
  error: () => {},
  warn: () => {},
};
const logger = config.env !== 'production' ? console : PROD_LOGGER;

export default logger;
