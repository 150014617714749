import Icon from '@ant-design/icons/lib/components/Icon';

import { CustomIconProps } from 'ui/icons/types';

const PlotterSvg = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <title>plotter icon</title>
    <path
      d="M1.75 7H3.5L5.25 1.75L8.75 12.25L10.5 7H12.25"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PlotterIcon = (props: CustomIconProps) => (
  <Icon component={PlotterSvg} {...props} />
);

export default PlotterIcon;
