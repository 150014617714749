import React, { useEffect } from 'react';

function usePersistedStringState(
  key: string,
  defaultValue?: string,
): [
  string | undefined,
  React.Dispatch<React.SetStateAction<string | undefined>>,
] {
  const [state, setState] = React.useState<string | undefined>(() => {
    const item = localStorage.getItem(key);
    return item ?? defaultValue;
  });
  useEffect(() => {
    if (state) {
      localStorage.setItem(key, state);
    } else {
      localStorage.removeItem(key);
    }
  }, [key, state]);
  return [state, setState];
}

export default usePersistedStringState;
