import { Flex, Typography } from 'antd';

import style from './index.module.scss';

export interface CardHeaderProps {
  title: string;
  actions?: React.ReactNode;
}

export const CardHeader: React.FC<CardHeaderProps> = ({ title, actions }) => {
  return (
    <Flex className={style.cardHeader}>
      <div className={style.cardTitle}>
        <Typography.Title className={style.title} level={5}>
          {title}
        </Typography.Title>
        <div className={style.underline} />
      </div>
      {actions ? <div className={style.cardActions}>{actions}</div> : null}
    </Flex>
  );
};
