import classnames from 'classnames';
import React from 'react';

import styles from './sectionTitle.module.scss';

interface Props extends React.HTMLProps<HTMLHeadingElement> {
  label: string;
  leftButton?: React.ReactNode;
}

function SectionTitle({
  label,
  className,
  leftButton,
  ...rest
}: Readonly<Props>) {
  return (
    <div className={classnames(className, styles.sectionTitleContainer)}>
      {leftButton && <span className={styles.leftButton}>{leftButton}</span>}
      <h1 {...rest} className={styles.sectionTitle}>
        {label}
      </h1>
      <div className={styles.lineCenter} />
    </div>
  );
}

export default SectionTitle;
