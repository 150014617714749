import Chart from 'business/data-analysis/components/graphCard/components/chart';
import { EmptyGraph } from 'business/data-analysis/components/graphCard/components/emptyGraph';
import GraphTableView from 'business/data-analysis/components/graphCard/components/graph-table-view';
import { PlotterMode } from 'business/data-analysis/constants';
import { DisplayType } from 'business/data-analysis/pages/graph/types';
import { GraphStateDto } from 'generated/apiSchemas';

import styles from './index.module.scss';

interface Props {
  graphState: GraphStateDto;
  mode: PlotterMode;
}

const GraphSection = ({ graphState, mode }: Props) => {
  if (!graphState.parameters.some((param) => param?.values?.length)) {
    return (
      <div className={styles.graphSection}>
        <EmptyGraph />
      </div>
    );
  }

  return (
    <div className={styles.graphSection}>
      {graphState.displayType === DisplayType.graph ? (
        <Chart graphState={graphState} mode={mode} />
      ) : (
        <GraphTableView graphState={graphState} mode={mode} />
      )}
    </div>
  );
};

export default GraphSection;
