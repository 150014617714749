import { Alert } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';

import config from 'config';

const MAINTENANCE_END_HOUR = 12;

const MaintenanceAlert = () => {
  const { t } = useTranslation();

  if (!config.features.maintenanceDate) {
    return null;
  }

  const maintenanceDate = dayjs(config.features.maintenanceDate).hour(
    MAINTENANCE_END_HOUR,
  );

  if (maintenanceDate.isBefore(new Date())) {
    return null;
  }

  return (
    <Alert
      message={t('common.maintenance', {
        date: maintenanceDate.format('L'),
      })}
      type="warning"
      showIcon
    />
  );
};

export default MaintenanceAlert;
