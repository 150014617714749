import { Flex, Typography } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { useCheckPermission } from 'business/contextProviders/usePermissions';
import {
  FilterByRangesInputs,
  RangeFilters,
} from 'business/production-and-performance/components/filter-by-ranges-inputs';
import { RingsTableContainer } from 'business/production-and-performance/components/ring-table-container';
import styles from 'business/production-and-performance/production-and-performance.module.scss';
import Routes from 'config/routes';
import { Module_Enum } from 'generated/graphql';
import { FilterCard } from 'ui/filter-card';
import { DateSelectionTypeEnum } from 'ui/form/temporality-select-input';
import { SimpleCard } from 'ui/SimpleCard';

const ShiftReportPermissions = [Module_Enum.ShiftReport];

export const RingTablePage = () => {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();
  const permissionCheck = useCheckPermission(ShiftReportPermissions);

  const [filters, setFilters] = useState<RangeFilters>({
    filterType: 'date',
    values: [null, null],
  });

  if (currentConstructionSite === null) {
    return null;
  }

  if (!permissionCheck) {
    return <Navigate replace to={Routes.NOT_FOUND} />;
  }

  const handleFilterChange = (newFilter: RangeFilters) => {
    setFilters(newFilter);
  };

  return (
    <div className={classNames(styles.page, 'page-appear')}>
      <Flex
        align="stretch"
        justify="center"
        className={styles.container}
        vertical
        gap={20}
      >
        <Typography>{t('pages.ring.title')}</Typography>

        <FilterCard>
          <FilterByRangesInputs
            onFilterChange={handleFilterChange}
            filter={filters}
            temporalitySelectInputProps={{
              excludedOptions: [DateSelectionTypeEnum.Date],
            }}
          />
        </FilterCard>

        <SimpleCard>
          <RingsTableContainer
            constructionSiteId={currentConstructionSite.id}
            filters={filters}
          />
        </SimpleCard>
      </Flex>
    </div>
  );
};

export default RingTablePage;
