import {
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Result,
  Row,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import {
  Navigate,
  useNavigate,
  useParams,
  generatePath,
} from 'react-router-dom';
import invariant from 'tiny-invariant';
import { i18n } from 'translations';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { QUERY_SHIFT_REPORT_METADATA } from 'business/report/pages/ReportEdit/query.gql';
import { getLastFinishedRing } from 'business/report/services/tasksOperations';
import Routes from 'config/routes';
import {
  useFinishReportManagerMutation,
  useFinishReportMutation,
  useGenerateShiftReportPdfMutation,
  useGetEndReportMetaQuery,
} from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import {
  negativeProgressionCheck,
  requiredRule,
} from 'technical/validation/rules';
import Button from 'ui/button';
import CapCard from 'ui/CapCard/CapCard';
import Flex from 'ui/flex';
import Loader from 'ui/loader';

import './index.scss';

interface FormValues {
  endMetricPoint?: number;
  note?: string;
}

function ReportEditFinishingPage() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  invariant(id, "id isn't set within the route");
  const { isConstructionSiteManager, currentConstructionSite } =
    useAppContext();
  const navigate = useNavigate();

  const [progressionCheckValid, setProgressionCheckValid] =
    useState<boolean>(false);

  const { data, loading, error } = useGetEndReportMetaQuery({
    variables: { id },
  });
  const [finishReport, { error: finishReportError }] =
    useFinishReportMutation();
  const [finishReportManager, { error: finishReportErrorManager }] =
    useFinishReportManagerMutation();

  const [generatePdf] = useGenerateShiftReportPdfMutation();

  const [form] = Form.useForm();

  useEffect(
    function alertUser() {
      if (finishReportError || finishReportErrorManager) {
        message.error(t('errors.mutation_failed'));
      }
    },
    [error, finishReportError, finishReportErrorManager, t],
  );

  if (loading) {
    return <Loader />;
  }
  if (!data?.shiftReport) {
    return <Navigate to="/404" />;
  }

  const { shiftReport } = data;

  const generateShiftReportPdf = async () => {
    try {
      await generatePdf({
        variables: {
          id: shiftReport.id,
          language: i18n.language,
        },
      });
    } catch (err) {
      logger.error(err);
      errorReporting.error(err as Error);
    }
  };

  const onFinish = async (values: FormValues) => {
    const reportEndFieldValues: any = {
      id,
      endMetricPoint: values.endMetricPoint,
      note: values.note,
      lastFinishedRing: getLastFinishedRing(shiftReport.tasks),
    };
    const queryMetadata = {
      query: QUERY_SHIFT_REPORT_METADATA,
      variables: { shiftReportId: id },
    };
    try {
      if (isConstructionSiteManager) {
        await finishReportManager({
          variables: {
            ...reportEndFieldValues,
            managerValidation:
              !!currentConstructionSite?.managerValidationRequired,
          },
          refetchQueries: [queryMetadata],
        });

        generateShiftReportPdf();
      } else {
        await finishReport({
          variables: reportEndFieldValues,
          refetchQueries: [queryMetadata],
        });

        if (currentConstructionSite?.managerValidationRequired === false) {
          generateShiftReportPdf();
        }
      }

      navigate(generatePath(Routes.ReportEditEnd, { id }));
    } catch (err) {
      logger.error(err);
    }
  };

  function checkProgression(value: Store) {
    setProgressionCheckValid(
      shiftReport.startMetricPoint === value.endMetricPoint,
    );
  }

  return (
    <Flex className={classNames('main-bg-image', 'cap-end-report-form')}>
      <CapCard title={t('pages.report.chart.operatorValidation.endFormTitle')}>
        {!shiftReport.operatorValidation ? (
          !loading && (
            <Form
              form={form}
              onFinish={onFinish}
              initialValues={{
                endMetricPoint:
                  shiftReport?.endMetricPoint ||
                  shiftReport?.startMetricPoint ||
                  0,
                note: shiftReport?.note || null,
              }}
              onValuesChange={(value) => checkProgression(value)}
              layout="vertical"
            >
              <Row>
                <Col span={8}>
                  <Form.Item
                    name="endMetricPoint"
                    label={t('pages.form.endMetricPoint.label')}
                    rules={[
                      requiredRule,
                      negativeProgressionCheck(shiftReport.startMetricPoint),
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }} step={0.001} />
                  </Form.Item>
                  {progressionCheckValid && (
                    <p className="warning">{t('errors.no_progression')}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="note" label={t('pages.form.note.label')}>
                    <Input.TextArea
                      style={{ width: '100%' }}
                      placeholder={t('pages.form.note.shiftReportPlaceholder')}
                      autoSize={{ minRows: 4, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Form.Item>
                  <Popconfirm
                    title={t(
                      'pages.report.chart.operatorValidation.endFormConfirmation',
                    )}
                    onConfirm={() => {
                      form.submit();
                    }}
                    onCancel={() => {}}
                    okText={t('common.confirm')}
                    cancelText={t('common.cancel')}
                  >
                    <Button type="primary">
                      {t('pages.report.chart.operatorValidation.endFormButton')}
                    </Button>
                  </Popconfirm>
                </Form.Item>
              </Row>
              <Row justify="center">
                <Form.Item>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    {t(
                      'pages.report.chart.operatorValidation.endFormSecondary',
                    )}
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          )
        ) : (
          <Result
            status="success"
            title={t('pages.report.chart.alreadyValidated')}
            extra={[
              <Button key="1" type="primary" onClick={() => navigate(-1)}>
                {t('common.go_back')}
              </Button>,
            ]}
          />
        )}
      </CapCard>
    </Flex>
  );
}

export default ReportEditFinishingPage;
