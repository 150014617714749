import { Typography } from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import Button, { ButtonProps } from 'ui/button';
import Flex from 'ui/flex';

import styles from './index.module.scss';

interface Props extends ButtonProps {
  route: string;
  icon: ReactNode;
  title: string;
  description: string[];
  variant?: 'green' | 'orange';
}

const ModeSelectionButton = ({
  route,
  icon,
  title,
  description,
  variant,
  ...rest
}: Props) => {
  return (
    <Link to={route} className={styles.link}>
      <Button
        className={classNames(
          styles.modeButton,
          variant ? styles[variant] : '',
        )}
        {...rest}
      >
        <Flex
          column
          className={styles.buttonContent}
          alignItems="center"
          justify="center"
        >
          <Flex
            alignItems="center"
            justify="center"
            className={classNames(styles.icon, variant ? styles[variant] : '')}
          >
            {icon}
          </Flex>
          <Typography className={styles.title}>{title}</Typography>
          <Flex column className={styles.description}>
            {description.map((part) => (
              <Typography key={part}>{part}</Typography>
            ))}
          </Flex>
        </Flex>
      </Button>
    </Link>
  );
};

export default ModeSelectionButton;
