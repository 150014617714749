import { message } from 'antd';
import { Chart } from 'react-google-charts';
import { useTranslation } from 'translations/hooks';

import { formatPieChartData } from 'business/task/pages/TasksAnalyzer/services/pieChartServices';
import { useGetShiftProductionTimeQuery } from 'generated/graphql';
import Loader from 'ui/loader';

interface Props {
  reportId: string;
  critical?: boolean;
}

function ShiftProductionGraph({ reportId, critical }: Props) {
  const { t } = useTranslation();

  const { data, loading, error } = useGetShiftProductionTimeQuery({
    variables: { id: reportId, critical },
  });

  if (error) {
    message.error(t('errors.error_generic'));
    return (
      <div style={{ textAlign: 'center' }}>{t('errors.no_data_available')}</div>
    );
  }

  const graphData = formatPieChartData(
    { t, isExportData: false },
    data?.shiftReportTasksPieChartData,
  );
  const options = {
    height: 500,
  };

  return (
    <div>
      {loading && <Loader />}
      {graphData && !loading && (
        <div id="pdfReportPrint">
          <Chart
            chartType="PieChart"
            data={graphData}
            options={{ ...options, maintainAspectRatio: false }}
            rootProps={{ 'data-testid': 'react-google-chart-1' }}
            chartPackages={['corechart', 'controls', 'charteditor']}
          />
        </div>
      )}
    </div>
  );
}

export default ShiftProductionGraph;
