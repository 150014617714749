import * as d3 from 'd3';
import { i18n } from 'translations';

import { TaskFullFragment } from 'generated/graphql';

import { ordinalScale } from './axis';
import {
  getActivityTotalText,
  getActivityTotalXPos,
  getActivityTotalYPos,
} from './metadata/activityTotal';
import { ACTIVITY_TOTAL_LABEL_CLASS, getPaddingLeft } from './metadata/chart';
import {
  CreateElementGroup,
  UpdateElementGroup,
  UsedActivity,
} from './metadata/types';

export const createActivitiesTotalLabel = (
  chart: number,
  width: number,
  mode: string,
) => {
  const label = d3
    .selectAll(`#report-chart-${mode}-${chart}`)
    .append('g')
    .attr('class', ACTIVITY_TOTAL_LABEL_CLASS)
    .attr(
      'transform',
      // we remove 40px to offset the label to the left
      `translate(${getActivityTotalXPos(width + getPaddingLeft() - 50)()}, 10)`,
    )
    .append('text')
    .attr('dominant-baseline', 'hanging');
  label
    .append('tspan')
    .attr('x', 0)
    .attr('y', 0)
    .text(i18n.t('chart.total.label1'));
  label
    .append('tspan')
    .attr('x', 0)
    .attr('y', '1.2em')
    .text(i18n.t('chart.total.label2'));
};

export const updateActivitiesTotalLabel = (
  chart: number,
  width: number,
  mode: string,
) => {
  d3.selectAll(`#report-chart-${mode}-${chart}`)
    .selectAll(`.${ACTIVITY_TOTAL_LABEL_CLASS}`)
    .remove();
  createActivitiesTotalLabel(chart, width, mode);
};

export const createActivitiesTotal = (
  allUsedActivityListGroup: CreateElementGroup<UsedActivity>,
  tasks: TaskFullFragment[],
  yScale: ordinalScale,
  width: number,
) => {
  allUsedActivityListGroup
    .append('text')
    .attr('x', getActivityTotalXPos(width))
    .attr('y', getActivityTotalYPos(yScale))
    .text(getActivityTotalText(tasks))
    .attr('dominant-baseline', 'middle');
};

export const updateActivitiesTotal = (
  allUsedActivityListGroup: UpdateElementGroup<UsedActivity>,
  tasks: TaskFullFragment[],
  yScale: ordinalScale,
  width: number,
) => {
  allUsedActivityListGroup
    .select('text')
    .attr('x', getActivityTotalXPos(width))
    .attr('y', getActivityTotalYPos(yScale))
    .text(getActivityTotalText(tasks))
    .transition();

  allUsedActivityListGroup.exit().remove();
};
