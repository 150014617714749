import { FileExcelOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Alert,
  Col,
  Descriptions,
  Divider,
  Row,
  Typography,
  message,
} from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import Chart from 'react-google-charts';
import { Link } from 'react-router-dom';
import { ILanguage } from 'translations';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import config from 'config';
import Routes from 'config/routes';
import {
  ShiftReportGroupByEnum,
  useGenerateExcelExportMutation,
  useIndicatorAnalysisDataQuery,
} from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import { useMediaType } from 'technical/media/hooks';
import { downloadFile } from 'technical/print';
import AppLogo from 'ui/appLogo';
import HeaderRow from 'ui/BiHeader';
import Button from 'ui/button';
import CheckableTree from 'ui/CheckableTree';
import Loader from 'ui/loader';

import styles from './index.module.scss';
import Inputs from './Inputs';
import {
  computeChartData,
  getCurrentAnalysisType,
  useAvailableIndicatorSelection,
} from './service';
import { CHART_TYPES, IndicatorAnalyzerOptions } from './types';

const handlePrint = () => {
  window.print();
};

export default function IndicatorDataViewer() {
  const { t, language } = useTranslation();

  const { currentConstructionSite } = useAppContext();
  const { isTablet } = useMediaType();

  const { name, logo } = currentConstructionSite ?? {};

  const [title, setTitle] = useState<string>(t('common.title'));
  const [options, setOptions] = useState<IndicatorAnalyzerOptions>({
    filterBy: 'date',
    dateRange: [dayjs().subtract(1, 'week'), dayjs()],
    ringRange: [1, 1000],
    groupBy: ShiftReportGroupByEnum.Day,
    chartType: CHART_TYPES[0].type,
  });
  const analysisType = getCurrentAnalysisType(
    options.filterBy,
    options.groupBy,
  );

  const indicators = useAvailableIndicatorSelection(
    currentConstructionSite?.id,
  );

  const { data, loading } = useIndicatorAnalysisDataQuery({
    variables: {
      analysisType,
      constructionSiteId: currentConstructionSite?.id,
      indicatorsIds: indicators.selection,
      startDate: options.dateRange?.[0],
      endDate: options.dateRange?.[1],
      startRing: options.ringRange?.[0] as number,
      endRing: options.ringRange?.[1] as number,
    },
    fetchPolicy: 'no-cache',
    skip: indicators.selection.length === 0 && !currentConstructionSite?.id,
  });

  const globalIndicatorResults = data?.indicatorAnalysisData?.data?.total;
  const aggregatedIndicatorResults =
    data?.indicatorAnalysisData?.data?.aggregated;

  const chart = useMemo(() => {
    if (loading) {
      return { data: undefined, options: {} };
    }
    if (
      !aggregatedIndicatorResults ||
      aggregatedIndicatorResults.length === 0
    ) {
      return { data: undefined, options: {} };
    }
    return computeChartData({
      indicators: Object.fromEntries(
        indicators.list.map((indicator) => [indicator.key, indicator]),
      ),
      selected: indicators.selection,
      axis: {
        x: t(`indicators.chart.axis.x.${analysisType}`),
        y: t(`indicators.chart.axis.y`),
      },
      results: aggregatedIndicatorResults,
      transform: (group) => {
        if (analysisType === 'ring' || analysisType === 'report') {
          return group;
        }
        if (analysisType === 'month') {
          return dayjs.utc(group).format('MMM');
        }
        if (analysisType === 'week') {
          return dayjs.utc(group).format('ww');
        }
        return dayjs.utc(group).format('L');
      },
    });
  }, [
    t,
    analysisType,
    indicators.selection,
    indicators.list,
    aggregatedIndicatorResults,
    loading,
  ]);

  const [generateExcel] = useGenerateExcelExportMutation();

  const handleExcelExport = async () => {
    try {
      if (chart.data) {
        const totals = Object.fromEntries(
          globalIndicatorResults?.map((result) => [result.id, result]) ?? [],
        );
        const jsonData = JSON.stringify([
          ...chart.data,
          ['Total', ...indicators.selection.map((id) => totals[id].value ?? 0)],
        ]);
        const { data: excel } = await generateExcel({
          variables: {
            fileName: 'export',
            graphData: jsonData,
            locale: language,
          },
        });
        if (excel?.generateExcelFile?.url && options.dateRange) {
          downloadFile(
            excel.generateExcelFile.url,
            `${currentConstructionSite?.name}_${dayjs
              .tz(options.dateRange[0])
              .format('L')}>${dayjs.tz(options.dateRange[1]).format('L')}.xlsx`,
          );
        }
      }
    } catch (excelGenerationError) {
      message.error(t('pages.manager.excelReport.fail'));

      if (excelGenerationError instanceof Error) {
        errorReporting.error(excelGenerationError);
      }
    }
  };

  const isChartDataEmpty = !chart.data || chart.data?.length === 0;

  return (
    <div className={styles.container}>
      <div className={styles.aside}>
        <Link to={{ pathname: Routes.Home, search: '?tab=analyze' }}>
          <Button className={styles.goBack}>{t('common.to_main_page')}</Button>
        </Link>
        <Divider />
        <div className={styles.tree}>
          <CheckableTree
            title={t('indicators.labels.indicators')}
            data={indicators.list}
            loading={indicators.loading}
            error={indicators.error}
            checkedKeys={indicators.selection}
            setCheckedKeys={indicators.select}
          />
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.printHeader}>
          <div className="header-left">
            <AppLogo />
            {logo && (
              <AppLogo
                alt="Construction site logo"
                path={`${config.gcp.publicUri}/${logo}`}
              />
            )}
          </div>
          <h1 id="pdfReportPrint">{name}</h1>
          {options.filterBy === 'ring' ? (
            <h2>
              {t('pages.print.rangeTitle', {
                startDate: options.ringRange?.[0],
                endDate: options.ringRange?.[1],
              })}
            </h2>
          ) : (
            <h2>
              {t('pages.print.rangeTitle', {
                startDate: dayjs(options.dateRange?.[0]).format('L'),
                endDate: dayjs(options.dateRange?.[1]).format('L'),
              })}
            </h2>
          )}
        </div>

        <div className={styles.tabBar}>
          <HeaderRow
            className={styles['print:hidden']}
            title={
              <Typography.Title
                level={3}
                editable={{
                  onChange: setTitle,
                }}
              >
                {title}
              </Typography.Title>
            }
            button1={
              <Button
                className={styles.cta}
                onClick={handlePrint}
                disabled={isChartDataEmpty || isTablet}
                icon={<PrinterOutlined />}
              >
                {t('common.print')}
              </Button>
            }
            button2={
              <Button
                className={styles.cta}
                onClick={handleExcelExport}
                disabled={isChartDataEmpty}
                icon={<FileExcelOutlined />}
              >
                {t('common.export')}
              </Button>
            }
          />
          <Row>
            <Col md={12} lg={15} className={styles['print:hidden']}>
              <Inputs options={options} setOptions={setOptions} />
            </Col>
            <Col md={12} lg={9} className={styles.indicators}>
              {globalIndicatorResults ? (
                <>
                  <Descriptions
                    layout="horizontal"
                    size="small"
                    className={classNames(styles['print:hidden'])}
                    column={1}
                  >
                    {globalIndicatorResults.map((indicator) => (
                      <Descriptions.Item
                        key={indicator.id}
                        span={1}
                        label={
                          {
                            [ILanguage.fr]: indicator.nameFr,
                            [ILanguage.enGB]: indicator.nameEn,
                            [ILanguage.enUS]: indicator.nameEn,
                            [ILanguage.es]: indicator.nameEs,
                          }[language]
                        }
                      >
                        {indicator.value != null
                          ? +indicator.value.toFixed(2)
                          : '- '}
                        {indicator.unit}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                  <Descriptions
                    layout="horizontal"
                    size="small"
                    className={classNames(styles.hidden, styles['print:show'])}
                    column={2}
                  >
                    {globalIndicatorResults.map((indicator) => (
                      <Descriptions.Item
                        key={indicator.id}
                        span={1}
                        label={
                          {
                            [ILanguage.fr]: indicator.nameFr,
                            [ILanguage.enGB]: indicator.nameEn,
                            [ILanguage.enUS]: indicator.nameEn,
                            [ILanguage.es]: indicator.nameEs,
                          }[language]
                        }
                      >
                        {indicator.value != null
                          ? +indicator.value.toFixed(2)
                          : '- '}
                        {indicator.unit}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </>
              ) : (
                <Alert
                  showIcon
                  message={t('indicators.errors.title')}
                  type="warning"
                />
              )}
            </Col>
          </Row>
        </div>

        <div className={styles.graphContainer}>
          {loading && <Loader />}
          {!isChartDataEmpty && !loading && (
            <Chart
              height="350px"
              width={window.screen.width <= 1024 ? '500px' : '1024px'}
              chartType={options.chartType}
              loader={<Loader />}
              data={chart.data}
              options={{
                ...chart.options,
                title,
                maintainAspectRatio: false,
                chartArea: {
                  width: '70%',
                },
                legend: {
                  maxLines: 2,
                  textStyle: {
                    fontSize: 11,
                  },
                },
              }}
              rootProps={{ 'data-testid': 'react-google-chart-1' }}
              chartPackages={['corechart', 'controls', 'charteditor']}
            />
          )}
          {isChartDataEmpty && (
            <Alert
              showIcon
              message={t('indicators.errors.title')}
              type="warning"
            />
          )}
        </div>
      </div>
    </div>
  );
}
