import { Popover, Space } from 'antd';
import { PopoverProps } from 'antd/es/popover';

interface MenuPopoverProps
  extends Pick<
    PopoverProps,
    'content' | 'children' | 'open' | 'onOpenChange'
  > {}

const MenuPopover = ({ children, ...otherProps }: MenuPopoverProps) => {
  return (
    <Space wrap>
      <Popover
        {...otherProps}
        trigger="click"
        arrow={false}
        placement="bottomRight"
      >
        {/**
         * A native html component is required as a direct children of Popover
         * cf doc: https://ant.design/components/popover#why-does-the-warning-finddomnode-is-deprecated-some-times-appear-in-strict-mode
         */}
        <div>{children}</div>
      </Popover>
    </Space>
  );
};

export default MenuPopover;
