import { MessageInstance } from 'antd/lib/message/interface';
import { useTranslation } from 'translations/hooks';
import { getLocale } from 'translations/locale';

import type { useFilters } from 'business/data-analysis/components/filterCard/hooks/use-filters';
import { PlotterMode } from 'business/data-analysis/constants';
import config from 'config';
import {
  useGraphSetExportControllerGenerateGraphSetPeriodExport,
  useGraphSetExportControllerGenerateGraphSetRingExport,
} from 'generated/apiComponents';
import { GraphSetStateDto } from 'generated/apiSchemas';
import { downloadFile } from 'technical/print';
import { notEmpty } from 'technical/utils/not-empty';

export const useExportGraphSet = (messageApi: MessageInstance) => {
  const { t } = useTranslation();

  const { mutateAsync: generateGraphSetPeriodExport } =
    useGraphSetExportControllerGenerateGraphSetPeriodExport({
      onSuccess: (data) => {
        downloadFile(`${config.gcp.publicUri}/${data.url}`, data.fileName);
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: t('errors.export_download_error'),
        });
      },
    });

  const { mutateAsync: generateGraphSetRingExport } =
    useGraphSetExportControllerGenerateGraphSetRingExport({
      onSuccess: (data) => {
        downloadFile(`${config.gcp.publicUri}/${data.url}`, data.fileName);
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: t('errors.export_download_error'),
        });
      },
    });

  const generateGraphSetExportByMode = (
    mode: PlotterMode,
    constructionSiteId: string,
    graphSetState: GraphSetStateDto,
    filters: ReturnType<typeof useFilters>['filters'],
  ) => {
    if (mode === PlotterMode.TIME_PERIOD) {
      return generateGraphSetPeriodExport({
        body: {
          constructionSiteId,
          startDate: filters.dateFilters.startDate.toISOString(),
          endDate: filters.dateFilters.endDate.toISOString(),
          graphSetState: graphSetState,
          locale: getLocale(),
        },
      });
    }

    if (
      mode === PlotterMode.RING &&
      filters.ringFilters &&
      notEmpty(filters.ringFilters[0]) &&
      notEmpty(filters.ringFilters[1])
    ) {
      return generateGraphSetRingExport({
        body: {
          constructionSiteId,
          startRing: filters.ringFilters[0],
          endRing: filters.ringFilters[1],
          graphSetState: graphSetState,
          locale: getLocale(),
        },
      });
    }
  };

  return {
    generateGraphSetExportByMode,
  };
};
