import { Drawer } from 'antd';
import { PropsWithChildren } from 'react';

interface Props {
  title: string;
  visible: boolean;
  onClose: () => void;
}

const GraphSetSideBar = ({
  title,
  visible,
  onClose,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Drawer
      onClose={onClose}
      // it is actually closable, but this remove the default close icon
      closable={false}
      className="drawer-sidebar"
      title={title}
      open={visible}
      height="auto"
      width={'35vw'}
    >
      {children}
    </Drawer>
  );
};

export default GraphSetSideBar;
