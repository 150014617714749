import { SVG } from '@svgdotjs/svg.js';

import { UsedActivity } from './types';

export const SELECTOR = 'shift-report-chart';
export const SVG_CHART_CLASS = 'chart break-inside';
export const CHART_GROUP_CLASS = 'task-chart';
export const TASK_GROUP_CLASS = 'task';
export const TOOLTIP_CLASS = 'chart-tooltip';
export const ACTIVITY_TOTAL_LABEL_CLASS = 'activity-total-label';
export const ACTIVITY_TOTAL_CLASS = 'activity-total';
export const EMPTY_ZONE_GROUP_CLASS = 'empty-zone';
export const TOP_LEVEL_ACTIVITY_TEXT_CLASS = 'top-level-activity-text';
export const VERTICAL_SPACE = 10;
export const MAXIMUM_ACTIVITY_NAME_LENGTH = 50;

export const margin = {
  top: 60,
  bottom: 0,
};

export const padding = {
  right: 90,
  left: 210,
};

const getLonguestActivity = (splittedActivities: string[]) =>
  splittedActivities.reduce((longuest, activity) => {
    return longuest.length > activity.length ? longuest : activity;
  }, '');

let paddingLeft = 0;

export const setPaddingLeft = (activitiesList: UsedActivity[]) => {
  const splittedActivities = activitiesList.flatMap(({ names }) => {
    // Easy way to tell typescript the second name cannot be undefined
    if (names[1]) {
      return [names[0], names[1]];
    }
    return [names[0]];
  });
  const longestActivityString = getLonguestActivity(splittedActivities);

  const activityRuler = document.createElement('span');
  activityRuler.setAttribute('id', 'ruler');
  document.body.append(activityRuler);
  activityRuler.innerHTML = longestActivityString;
  if (activityRuler.clientWidth === 0) {
    paddingLeft = 40;
  } else {
    paddingLeft = activityRuler.clientWidth + 40;
  }
  document.body.removeChild(activityRuler);
};

export const getPaddingLeft = () => paddingLeft;

// Hatch patterns
const diagonalHatchPatternSvg = SVG()
  .addTo('body')
  .size(300, 300)
  // display is disable in ../../index.scss
  .addClass('diagonal-hatch-pattern-svg');

diagonalHatchPatternSvg
  // Define empty zone hatch pattern
  .defs()
  .svg(
    `
    <pattern
      id="empty-zone-hatch-pattern"
      x="0"
      y="0"
      patternUnits="userSpaceOnUse"
      width="6"
      height="6"
      viewBox="0 0 6 6"
    >
      <svg
        width="6px"
        height="6px"
        viewBox="0 0 6 6"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g fill="#da291c">
            <polygon points="5 0 6 0 0 6 0 5"></polygon>
            <polygon points="6 5 6 6 5 6"></polygon>
          </g>
        </g>
      </svg>
    </pattern>
  `,
  )
  // Define task to validate hatch pattern
  .defs()
  .svg(
    `
    <pattern
      id="task-to-validate-hatch-pattern"
      x="0"
      y="0"
      patternUnits="userSpaceOnUse"
      width="6"
      height="6"
      viewBox="0 0 6 6"
    >
      <svg
        width="6px"
        height="6px"
        viewBox="0 0 6 6"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g fill="#ff9839">
            <polygon points="5 1 6 0 0 6 0 5"></polygon>
            <polygon points="6 5 6 6 5 6"></polygon>
          </g>
        </g>
      </svg>
    </pattern>
  `,
  );

// Load the pattern by id
export const emptyZoneHatchPattern = SVG('#empty-zone-hatch-pattern');
export const taskToValidatePattern = SVG('#task-to-validate-hatch-pattern');
