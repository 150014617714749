import { Row, Col, Statistic } from 'antd';
import { useTranslation } from 'translations/hooks';

import { useExcavationBuildTimeFiltersValue } from 'business/production-and-performance/hooks/excavation-build-time-filters-context';
import { formatRingDuration } from 'business/production-and-performance/services/ring-time-format';
import { GetExcavationAndBuildingDurationResponseDto } from 'generated/apiSchemas';

import styles from './index.module.scss';

interface Props {
  data: GetExcavationAndBuildingDurationResponseDto['averages'];
}

export const ExcavationBuildTimeAverages = ({ data }: Props) => {
  const { t } = useTranslation();

  const context = useExcavationBuildTimeFiltersValue();
  const showTotalDurations = context?.showTotalDurations;

  return (
    <Row className={styles.row} gutter={8}>
      <Col span={6}>
        <Statistic
          title={t(
            'productionAndPerformance.excavationBuildTime.averages.avgRealExcavationDuration',
          )}
          value={formatRingDuration(data.avgRealExcavationDuration)}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title={t(
            'productionAndPerformance.excavationBuildTime.averages.avgRealBuildDuration',
          )}
          value={formatRingDuration(data.avgRealBuildDuration)}
        />
      </Col>
      {showTotalDurations ? (
        <>
          <Col span={6}>
            <Statistic
              title={t(
                'productionAndPerformance.excavationBuildTime.averages.avgTotalExcavationDuration',
              )}
              value={formatRingDuration(data.avgTotalExcavationDuration)}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title={t(
                'productionAndPerformance.excavationBuildTime.averages.avgTotalBuildDuration',
              )}
              value={formatRingDuration(data.avgTotalBuildDuration)}
            />
          </Col>
        </>
      ) : null}
    </Row>
  );
};
