import { CloseCircleOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import { useTranslation } from 'translations/hooks';

import Flex from 'ui/flex';

const NoConstructionSite = () => {
  const { t } = useTranslation();
  return (
    <Flex
      style={{ flex: 1, padding: '20px' }}
      justify="center"
      alignItems="center"
    >
      <Result
        icon={<CloseCircleOutlined />}
        title={t('errors.no_construction_site_title')}
        subTitle={t('errors.no_construction_site')}
      />
    </Flex>
  );
};

export default NoConstructionSite;
