import { TopLevelTasksAggregate } from './types';

export function getTotalTime(tasks: TopLevelTasksAggregate[]) {
  return tasks.reduce((acc, task) => {
    return acc + task.total;
  }, 0);
}

// More information here for decimal and EPSILON https://stackoverflow.com/a/11832950
export function calculAverage(quantity: number, total: number) {
  if (total === 0) {
    return Number.NaN;
  }

  return Math.round((quantity / total + Number.EPSILON) * 100) / 100;
}
