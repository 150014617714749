import { PropsWithChildren } from 'react';

import { useCheckPermission } from 'business/contextProviders/usePermissions';
import Flex from 'ui/flex';

import styles from './index.module.scss';

interface Props {
  permissions: string[];
}

const MenuColumn = ({ children, permissions }: PropsWithChildren<Props>) => {
  const permissionCheck = useCheckPermission(permissions);
  if (!permissionCheck) {
    return null;
  }
  return (
    <Flex column className={styles.container}>
      {children}
    </Flex>
  );
};

export default MenuColumn;
