import { useEffect } from 'react';

import { login } from 'business/user/services/authentication';
import Loader from 'ui/loader';

function LandingPage() {
  useEffect(() => {
    login();
  }, []);
  return <Loader />;
}
export default LandingPage;
