import { Flex, Form, Switch, SwitchProps, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { RadioChangeEvent } from 'antd/es/radio';
import dayjs from 'dayjs';
import { useTranslation } from 'translations/hooks';

import { ShiftOrCalendarSelector } from 'business/production-and-performance/components/shift-or-calendar-selector';
import {
  ExcavationBuildTimeDispatchActionTypes,
  ExcavationBuildTimeFilter,
  useExcavationBuildTimeFiltersDispatch,
  useExcavationBuildTimeFiltersValue,
} from 'business/production-and-performance/hooks/excavation-build-time-filters-context';
import { DateRangeType } from 'technical/types';
import TemporalitySelectInput, {
  DateSelectionTypeEnum,
} from 'ui/form/temporality-select-input';

export const ExcavationBuildTimeFilters = () => {
  const { t } = useTranslation();

  const filters = useExcavationBuildTimeFiltersValue();
  const dispatch = useExcavationBuildTimeFiltersDispatch();

  const toggleShowTotalDurations: SwitchProps['onChange'] = () => {
    dispatch({
      type: ExcavationBuildTimeDispatchActionTypes.ToggleTotalDurations,
    });
  };

  const ondDayModeChange = (e: RadioChangeEvent) => {
    const dayMode: 'shift' | 'calendar' = e.target.value;
    dispatch({
      type: ExcavationBuildTimeDispatchActionTypes.ChangeDayMode,
      dayMode,
    });
  };

  const onDateChange = (dateRange: DateRangeType) => {
    dispatch({
      type: ExcavationBuildTimeDispatchActionTypes.UpdateDateFilter,
      dateRange,
    });
  };

  const [form] = useForm<ExcavationBuildTimeFilter>();

  return (
    <>
      <Form<ExcavationBuildTimeFilter>
        layout={'inline'}
        form={form}
        initialValues={filters}
      >
        <Form.Item name="dateRange">
          <TemporalitySelectInput
            onChange={onDateChange}
            allowClear={false}
            allowEmpty={false}
            value={filters.dateRange}
            defaultDate={dayjs()}
            excludedOptions={[DateSelectionTypeEnum.Date]}
          />
        </Form.Item>
        <Form.Item<ExcavationBuildTimeFilter> name="dayMode">
          <ShiftOrCalendarSelector
            onChange={ondDayModeChange}
            value={filters.dayMode}
          />
        </Form.Item>
      </Form>
      <Flex gap={8} align="center">
        <Switch
          onChange={toggleShowTotalDurations}
          checked={filters.showTotalDurations}
          size="small"
        ></Switch>
        <Typography.Text>
          {t(
            'productionAndPerformance.excavationBuildTime.filters.toggleTotalAverages',
          )}
        </Typography.Text>
      </Flex>
    </>
  );
};
