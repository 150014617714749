/**
 * Checks if a value is undefined or null.
 *
 * @param value - The value to check.
 * @returns True if the value is undefined or null, false otherwise.
 */
export const isUndefinedOrNull = <T>(
  value: T | null | undefined,
): value is null | undefined => {
  return value === undefined || value === null;
};
