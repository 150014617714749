import { GraphFilters } from 'business/data-analysis/pages/graph/types';
import {
  DataAnalysisControllerGraphByPeriodVariables,
  DataAnalysisControllerGraphByRingVariables,
} from 'generated/apiComponents';
import { GraphSetStateDto, ParameterDto } from 'generated/apiSchemas';

type GraphParametersFlatArray = {
  id: string;
  index: number;
  aggregationType?: ParameterDto['aggregationType'];
  computeType?: ParameterDto['computeType'];
};

export const useQueryParams = (
  constructionSiteId: string,
  filters: GraphFilters,
  graphSet: GraphSetStateDto,
): {
  queryParamsForGraphByPeriod: DataAnalysisControllerGraphByPeriodVariables;
  queryParamsForGraphByRing: DataAnalysisControllerGraphByRingVariables;
} => {
  const graphParametersFlatArray = Array.from(
    new Set(
      graphSet.graphs.flatMap((graphState) =>
        graphState.parameters.map(
          (parameter) =>
            ({
              id: parameter.id.toString(),
              aggregationType: parameter.aggregationType,
              computeType: parameter.computeType,
              index: parameter.index,
            }) satisfies GraphParametersFlatArray,
        ),
      ),
    ),
  );

  const queryParamsForGraphByPeriod = {
    queryParams: {
      id: constructionSiteId,
      startDate: filters.dateFilters.startDate.toISOString(),
      endDate: filters.dateFilters.endDate.toISOString(),
      parameterIds: graphParametersFlatArray.map(({ id }) => id),
    },
  };

  const queryParamsForGraphByRing = {
    body: {
      constructionSiteId: constructionSiteId,
      startRing: filters.ringFilters ? filters.ringFilters[0] || 0 : 0,
      endRing: filters.ringFilters ? filters.ringFilters[1] || 0 : 0,
      parametersWithAggregation: graphParametersFlatArray
        .filter(
          (parameter): parameter is Required<GraphParametersFlatArray> =>
            !!parameter.aggregationType,
        )
        .map((parameter) => ({
          id: Number(parameter.id),
          index: parameter.index,
          aggregationType: parameter.aggregationType,
          computeType: parameter.computeType,
        })),
    },
  } satisfies DataAnalysisControllerGraphByRingVariables;

  return {
    queryParamsForGraphByPeriod,
    queryParamsForGraphByRing,
  };
};
