import { DateRangeType } from 'technical/types';

export enum ExcavationBuildTimeDispatchActionTypes {
  ToggleTotalDurations = 'toggleTotalDurations',
  UpdateDateFilter = 'updateDateFilter',
  ChangeDayMode = 'changeDayMode',
}

export type ActionType =
  | {
      type: ExcavationBuildTimeDispatchActionTypes.ToggleTotalDurations;
    }
  | {
      type: ExcavationBuildTimeDispatchActionTypes.UpdateDateFilter;
      dateRange: DateRangeType;
    }
  | {
      type: ExcavationBuildTimeDispatchActionTypes.ChangeDayMode;
      dayMode: 'calendar' | 'shift';
    };

export interface ExcavationBuildTimeFilter {
  showTotalDurations: boolean;
  dateRange: DateRangeType;
  dayMode: 'shift' | 'calendar';
}
