import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
  FileImageOutlined,
} from '@ant-design/icons';
import {
  Descriptions,
  Form,
  Popconfirm,
  Space,
  Typography,
  message,
} from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { formatReportDateStringToLocalizedDateString } from 'business/report/services/timeOperations';
import Routes from 'config/routes';
import {
  ShiftReportCardInfoFragment,
  useDeleteShiftReportMutation,
} from 'generated/graphql';
import logger from 'technical/logger';
import { formatUserName } from 'technical/string/formatter';
import Button from 'ui/button';
import CapCard from 'ui/CapCard/CapCard';
import Flex from 'ui/flex';
import './index.scss';

const getMessageKey = (id: string) => `delete-shift-report:${id}`;

interface Props {
  report: ShiftReportCardInfoFragment;
  refetchList: () => Promise<unknown>;
}

function ReportCard({ report, refetchList }: Props) {
  const { t } = useTranslation();
  const {
    user,
    hasManagerReadPermission,
    hasManagerEditPermission,
    isConstructionSiteFullReader,
  } = useAppContext();
  const [deleteReport] = useDeleteShiftReportMutation();

  const isUserReport = report.userId === user?.id;
  const isFinished = report.operatorValidation || isConstructionSiteFullReader;
  const isValidated = report.managerValidation;
  const reportTitle = t(`pages.report.title`, {
    date: formatReportDateStringToLocalizedDateString(report.date),
    shiftName: report.shift.name,
    context: isFinished ? 'prev' : '',
  });
  const ctaLink =
    !report.operatorValidation ||
    (!report.managerValidation && hasManagerReadPermission)
      ? Routes.ReportEdit.replace(':id', report.id)
      : Routes.ReportView.replace(':id', report.id);

  const fileCount = report.tasks_aggregate.files?.count ?? 0;

  const removeReport = async () => {
    try {
      message.loading({
        content: t('pages.report.list.deleteMessage'),
        key: getMessageKey(report.id),
        // No auto-dismiss
        duration: 0,
      });
      const { data, errors } = await deleteReport({
        variables: {
          id: report.id,
        },
      });
      message.destroy(getMessageKey(report.id));

      if (errors || !data?.delete_shiftReport_by_pk?.id) {
        message.warning(t('pages.report.list.deleteReportFail'));
        logger.error(errors);
      } else {
        message.success(t('pages.report.list.deleteReportSuccess'));
        await refetchList();
      }
    } catch (error) {
      message.error(t('pages.report.list.deleteReportFail'));
      logger.error(error);
    }
  };

  return (
    <div className="cap-report">
      <CapCard
        title={
          <>
            {reportTitle}
            {fileCount > 0 ? (
              <FileImageOutlined style={{ marginLeft: 8 }} />
            ) : null}
          </>
        }
        isFinished={isFinished}
        isValidated={isValidated}
      >
        {!isFinished && <EditOutlined className="edit-icon" />}
        {isValidated && <CheckCircleOutlined className="check-icon" />}
        <Flex>
          <Descriptions column={1} className="first-info-block-width">
            <Descriptions.Item
              label={
                <Typography.Text strong>
                  {t('pages.form.creator.label')}
                </Typography.Text>
              }
            >
              {report.userName}
            </Descriptions.Item>

            <Descriptions.Item
              label={
                <Typography.Text strong>
                  {t('pages.form.operator.label')}
                </Typography.Text>
              }
            >
              {report.operator?.name}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Typography.Text strong>
                  {t('pages.form.shiftLeader.label')}
                </Typography.Text>
              }
            >
              {report.shiftLeader?.name}
            </Descriptions.Item>

            {(report.shiftEngineer || report.shiftManager) && (
              <>
                {report.shiftEngineer && (
                  <Descriptions.Item
                    label={
                      <Typography.Text strong>
                        {t('pages.form.shiftEngineer.label')}
                      </Typography.Text>
                    }
                  >
                    {formatUserName(report.shiftEngineer)}
                  </Descriptions.Item>
                )}
                {report.shiftManager && (
                  <Descriptions.Item
                    label={
                      <Typography.Text strong>
                        {t('pages.form.shiftManager.label')}
                      </Typography.Text>
                    }
                  >
                    {formatUserName(report.shiftManager)}
                  </Descriptions.Item>
                )}
              </>
            )}
          </Descriptions>

          <Descriptions column={1} size="small">
            <Descriptions.Item
              label={
                <Typography.Text strong>
                  {t('pages.form.startMetricPoint.label')}
                </Typography.Text>
              }
            >
              {report.startMetricPoint}
            </Descriptions.Item>
            {report.endMetricPoint && (
              <Descriptions.Item
                label={
                  <Typography.Text strong>
                    {t('pages.form.endMetricPoint.label')}
                  </Typography.Text>
                }
              >
                {report.endMetricPoint}
              </Descriptions.Item>
            )}

            {report.lastFinishedRing !== null &&
              report.lastFinishedRing !== -1 && (
                <Descriptions.Item
                  label={
                    <Typography.Text strong>
                      {t('pages.form.lastFinishedRing.label')}
                    </Typography.Text>
                  }
                >
                  {report.lastFinishedRing}
                </Descriptions.Item>
              )}

            <Descriptions.Item
              label={
                <Typography.Text strong>
                  {t('pages.form.note.label')}
                </Typography.Text>
              }
              className="report-list-report-card-description-comment"
            >
              {report.note ? report.note : 'N/A'}
            </Descriptions.Item>
          </Descriptions>
        </Flex>

        <Form.Item className="button">
          <Space>
            {!isFinished && (hasManagerEditPermission || isUserReport) && (
              <Popconfirm
                title={t('pages.report.list.deletePopup')}
                icon={<ExclamationCircleFilled style={{ color: 'red' }} />}
                onConfirm={removeReport}
                okText={t('pages.report.list.delete')}
                okButtonProps={{ danger: true }}
              >
                <Button icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
            <Link to={ctaLink}>
              <Button
                type="primary"
                htmlType="submit"
                icon={isFinished ? <EyeOutlined /> : <EditOutlined />}
              >
                {t('pages.report.ctaButton', {
                  context: isUserReport && !isFinished ? 'edit' : 'read',
                })}
              </Button>
            </Link>
          </Space>
        </Form.Item>
      </CapCard>
    </div>
  );
}

export default ReportCard;
