import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { formatReportDateStringToLocalizedDateString } from 'business/report/services/timeOperations';
import { useGetShiftReportNameQuery } from 'generated/graphql';

import Page from './page';

interface Props {
  header?: React.ReactNode;
  children?: React.ReactNode;
  img?: { src: string; alt: string };
  footer?: React.ReactNode;
}

function ShiftConnectedPage(props: Props) {
  const { isConnected } = useAppContext();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const { data } = useGetShiftReportNameQuery({
    variables: { id },
    skip: !id,
  });

  let reportTitle = t('pages.report.navReport');

  if (data?.shiftReport) {
    const { shiftReport } = data;
    reportTitle = `${reportTitle} - ${formatReportDateStringToLocalizedDateString(
      shiftReport.date,
    )} - ${shiftReport.shift.name}`;
  }

  return (
    <Page
      {...props}
      isConnected={isConnected}
      title={reportTitle}
      constructionSiteId={data?.shiftReport?.constructionSite?.id}
    />
  );
}

export default ShiftConnectedPage;
