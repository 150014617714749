import { Row, Col } from 'antd';
import React from 'react';

const BiHeader = ({
  title,
  button1,
  button2,
  className,
}: {
  title: React.ReactNode;
  button1: React.ReactNode;
  button2: React.ReactNode;
  className?: string;
}) => (
  <Row className={className}>
    <Col xl={16} lg={16} md={10} sm={24}>
      {title}
    </Col>
    <Col
      xl={{ span: 3, offset: 1 }}
      lg={{ span: 3, offset: 1 }}
      md={{ span: 3, offset: 7 }}
      sm={{ span: 3, offset: 1 }}
    >
      {button1}
    </Col>
    <Col
      xl={{ span: 3, offset: 1 }}
      lg={{ span: 3, offset: 1 }}
      md={{ span: 3, offset: 1 }}
      sm={{ span: 3, offset: 1 }}
    >
      {button2}
    </Col>
  </Row>
);

export default BiHeader;
