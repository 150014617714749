import dayjs, { Dayjs } from 'dayjs';

import timezoneManagement from 'technical/time-utils/dayjs.timezone';

function parseShiftTimeString(timeString: string) {
  return dayjs.tz(timeString, 'HH:mm:ss', timezoneManagement.getDefaultTz());
}

export function parseShiftTimeStringWithReportDate(
  shiftTimeString: string,
  reportDate: Dayjs,
) {
  const reportYear = reportDate.year();
  const reportDayOfYear = reportDate.dayOfYear();

  const shiftTimeNotOnDate = parseShiftTimeString(shiftTimeString);
  return shiftTimeNotOnDate.year(reportYear).dayOfYear(reportDayOfYear);
}
