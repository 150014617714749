import dayjs from 'dayjs';
import timezonePlugin from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezonePlugin);

class TimezoneManagement {
  private defaultTz: string = dayjs.tz.guess();

  setDefaultTz = (timezone: string) => {
    dayjs.tz.setDefault(timezone);
    this.defaultTz = timezone;
    return timezone;
  };

  getDefaultTz = () => {
    return this.defaultTz;
  };
}

const timezoneManagement = new TimezoneManagement();

export default timezoneManagement;
