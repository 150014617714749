import { BarChartOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'translations/hooks';

import Routes from 'config/routes';
import { Module_Enum } from 'generated/graphql';
import ListIcon from 'ui/icons/list';
import PlotterIcon from 'ui/icons/plotter';
import ShiftReportIcon from 'ui/icons/shift-report';
import MenuColumn from 'ui/navBar/components/menu/menu-column';
import MenuContent from 'ui/navBar/components/menu/menu-content';
import MenuEntry from 'ui/navBar/components/menu/menu-entry';
import MenuSection from 'ui/navBar/components/menu/menu-section';

import style from './index.module.scss';

const ShiftReportPermissions = [Module_Enum.ShiftReport];
const DataAnalysisPermissions = [Module_Enum.DataAnalysis];
const prodPerfPermissions = [Module_Enum.ProdPerf];

const ReportingSectionPermissions = [...ShiftReportPermissions];
const DataAnalysisSectionPermissions = [...DataAnalysisPermissions];
const prodPerfSectionPermissions = [...prodPerfPermissions];

const MenuContentPermissions = [
  ...ReportingSectionPermissions,
  ...DataAnalysisSectionPermissions,
  ...prodPerfSectionPermissions,
];

interface SectionProps {
  onClick: () => void;
}

const DataAnalysisSection = ({ onClick }: SectionProps) => {
  const { t } = useTranslation();

  return (
    <MenuSection
      title={t('user.menu.sections.dataAnalysis.title')}
      permissions={DataAnalysisSectionPermissions}
    >
      <MenuEntry
        route={Routes.DataAnalysisModeSelection}
        icon={<PlotterIcon />}
        title={t('user.menu.sections.dataAnalysis.plotter.title')}
        description={t('user.menu.sections.dataAnalysis.plotter.description')}
        permissions={DataAnalysisPermissions}
        onClick={onClick}
      />
    </MenuSection>
  );
};

const ReportingSection = ({ onClick }: SectionProps) => {
  const { t } = useTranslation();

  return (
    <MenuSection
      title={t('user.menu.sections.reporting.title')}
      permissions={ReportingSectionPermissions}
    >
      <MenuEntry
        route={Routes.Home}
        icon={<ShiftReportIcon />}
        title={t('user.menu.sections.reporting.shiftReport.title')}
        description={t('user.menu.sections.reporting.shiftReport.description')}
        permissions={ShiftReportPermissions}
        onClick={onClick}
      />
    </MenuSection>
  );
};

const ProdPerfSection = ({ onClick }: SectionProps) => {
  const { t } = useTranslation();

  return (
    <MenuSection
      title={t('user.menu.sections.prodPerf.title')}
      permissions={ReportingSectionPermissions}
    >
      <MenuEntry
        route={Routes.ProdPerfRingPerDay}
        icon={<BarChartOutlined className={style.icon} />}
        title={t('user.menu.sections.prodPerf.ringPerDay.title')}
        description={t('user.menu.sections.prodPerf.ringPerDay.description')}
        permissions={ShiftReportPermissions}
        onClick={onClick}
      />
      <MenuEntry
        route={Routes.ProdPerfExcavationBuildTime}
        icon={<ClockCircleOutlined className={style.icon} />}
        title={t('user.menu.sections.prodPerf.excavationBuildTime.title')}
        description={t(
          'user.menu.sections.prodPerf.excavationBuildTime.description',
        )}
        permissions={ShiftReportPermissions}
        onClick={onClick}
      />
      <MenuEntry
        route={Routes.ProdPerfRingList}
        icon={<ListIcon />}
        title={t('user.menu.sections.prodPerf.ringList.title')}
        description={t('user.menu.sections.prodPerf.ringList.description')}
        permissions={ShiftReportPermissions}
        onClick={onClick}
      />
    </MenuSection>
  );
};

const Menu = () => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <MenuContent
      permissions={MenuContentPermissions}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <MenuColumn permissions={DataAnalysisSectionPermissions}>
        <DataAnalysisSection onClick={hide} />
      </MenuColumn>
      <MenuColumn permissions={ReportingSectionPermissions}>
        <ReportingSection onClick={hide} />
      </MenuColumn>
      <MenuColumn permissions={prodPerfSectionPermissions}>
        <ProdPerfSection onClick={hide} />
      </MenuColumn>
    </MenuContent>
  );
};

export default Menu;
