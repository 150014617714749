import {
  ConstructionSiteFieldsFragment,
  ShiftReportChartViewFragment,
  CommonUserFieldsFragment,
} from 'generated/graphql';

export enum ShiftReportOperations {
  EDIT_META,
  TASK,
  AUTOFILL,
  EDIT_NOTE,
  PREVIEW,
  OPERATOR_VALIDATION,
  MANAGER_VALIDATION,
  USER_INVALIDATION,
}

const DEFAULT_ALLOWED_OPERATIONS: ShiftReportOperations[] = [];

const DEFAULT_EDIT_OPERATIONS = [
  ShiftReportOperations.EDIT_META,
  ShiftReportOperations.TASK,
  ShiftReportOperations.EDIT_NOTE,
  ShiftReportOperations.PREVIEW,
];

export const checkOperationFromList =
  (operationToCheck: ShiftReportOperations) =>
  (enabledOperations: ShiftReportOperations[]) => {
    if (enabledOperations.includes(operationToCheck)) {
      return true;
    }
    return false;
  };

export const isTaskOperationEnabled = checkOperationFromList(
  ShiftReportOperations.TASK,
);

export const isAutofillOperationEnabled = checkOperationFromList(
  ShiftReportOperations.AUTOFILL,
);

export const isNoteEditionOperationEnabled = checkOperationFromList(
  ShiftReportOperations.EDIT_NOTE,
);

export const isEditShiftReportMetaEnabled = checkOperationFromList(
  ShiftReportOperations.EDIT_META,
);

export const isPreviewEnabled = checkOperationFromList(
  ShiftReportOperations.PREVIEW,
);

export const isOnlyOperatorValidationEnabled = (
  enabledOperations: ShiftReportOperations[],
) =>
  checkOperationFromList(ShiftReportOperations.OPERATOR_VALIDATION)(
    enabledOperations,
  ) &&
  !checkOperationFromList(ShiftReportOperations.MANAGER_VALIDATION)(
    enabledOperations,
  );

export const isManagerValidationEnabled = checkOperationFromList(
  ShiftReportOperations.MANAGER_VALIDATION,
);

export const isUserInvalidationEnabled = checkOperationFromList(
  ShiftReportOperations.USER_INVALIDATION,
);

export const getAuthorizedOperations = ({
  user,
  report,
  constructionSite,
  isConstructionSiteUser,
  hasManagerEditPermission,
}: {
  user?: CommonUserFieldsFragment;
  report?: ShiftReportChartViewFragment;
  constructionSite?: ConstructionSiteFieldsFragment;
  isConstructionSiteUser: boolean;
  hasManagerEditPermission: boolean;
}): ShiftReportOperations[] => {
  if (!user || !report || !constructionSite || constructionSite.isCompleted) {
    return DEFAULT_ALLOWED_OPERATIONS;
  }

  if (hasManagerEditPermission) {
    if (report.userId === user.id && !report.operatorValidation) {
      return [
        ...DEFAULT_EDIT_OPERATIONS,
        ShiftReportOperations.AUTOFILL,
        ShiftReportOperations.OPERATOR_VALIDATION,
      ];
    }
    if (
      report.operatorValidation &&
      !report.managerValidation &&
      constructionSite.managerValidationRequired
    ) {
      return [
        ...DEFAULT_EDIT_OPERATIONS,
        ShiftReportOperations.MANAGER_VALIDATION,
        ShiftReportOperations.USER_INVALIDATION,
      ];
    }
  }
  if (isConstructionSiteUser) {
    if (report.userId === user.id && !report.operatorValidation) {
      return [
        ...DEFAULT_EDIT_OPERATIONS,
        ShiftReportOperations.AUTOFILL,
        ShiftReportOperations.OPERATOR_VALIDATION,
      ];
    }
  }

  return DEFAULT_ALLOWED_OPERATIONS;
};
