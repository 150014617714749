import { blue, green, red, gold } from '@ant-design/colors';

// Colors from default theme of Antd
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
// https://ant.design/docs/spec/colors
// @todo: update src/business/App.scss

const colors = {
  primaryColor: '#012169',
  primaryRed: '#da291c',
  infoColor: blue[5],
  successColor: green[5],
  processingColor: blue[5],
  errorColor: red[4],
  highlightColor: red[4],
  warningColor: gold[5],
  normalColor: '#d9d9d9',
  white: '#fff',
  black: '#000',
};

export default colors;
