import { EditOutlined } from '@ant-design/icons';
import { Button, DatePicker, message, Result } from 'antd';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import ReportList, {
  DEFAULT_PAGE_LIMIT,
  DEFAULT_PAGE_NUMBER,
} from 'business/report/components/ReportList';
import Routes from 'config/routes';
import { useGetReportsQuery } from 'generated/graphql';
import useSearchParamQuery from 'technical/router/hooks/useSearchParamQuery';

import styles from './index.module.scss';

function ReportListPage() {
  const { t } = useTranslation();
  const [dateFilter, setDateFilter] = useState<Dayjs | null>(null);
  const searchParamsQuery = useSearchParamQuery();
  const { currentConstructionSite } = useAppContext();

  function filterResults(value: Dayjs | null) {
    setDateFilter(value);
  }

  const { loading, error, data, refetch } = useGetReportsQuery({
    variables: {
      constructionSiteId: currentConstructionSite?.id,
      limit: DEFAULT_PAGE_LIMIT,
      offset:
        (Number(searchParamsQuery.get('page') ?? DEFAULT_PAGE_NUMBER) - 1) *
        DEFAULT_PAGE_LIMIT,
      date: dateFilter,
    },
    fetchPolicy: 'network-only',
    skip: !currentConstructionSite?.id,
  });

  useEffect(
    function alertUser() {
      if (error) {
        message.error(error.message);
      }
    },
    [error],
  );

  const emptyListComponent = (
    <Result
      status="404"
      title={t('pages.report.list.filterNotFound.title')}
      subTitle={
        dateFilter === null
          ? t('pages.report.list.noReport.label')
          : t('pages.report.list.filterNotFound.label')
      }
      extra={
        <Button onClick={() => setDateFilter(null)}>
          {t('pages.report.list.filterNotFound.button')}
        </Button>
      }
    />
  );

  return (
    <div className={classNames(styles.reportListPage, 'page-appear')}>
      <DatePicker
        className={classNames(styles.searchBox, 'button-center')}
        format="L"
        onChange={filterResults as any}
        allowClear
      />
      <ReportList
        loading={loading}
        refetchList={refetch}
        shiftReports={data?.shiftReport}
        paginationMeta={{
          shiftReportCount: data?.shiftReportAggregate.aggregate?.count || 0,
        }}
        emptyListComponent={emptyListComponent}
      />
      {!currentConstructionSite?.isCompleted && (
        <Link to={Routes.Home} className="button-center">
          <Button type="primary" icon={<EditOutlined />}>
            {t('pages.report.startNewReport')}
          </Button>
        </Link>
      )}
    </div>
  );
}

export default ReportListPage;
