import { FloatButton, Layout } from 'antd';
import React from 'react';

import NavBar from 'ui/navBar';

import styles from './index.module.scss';

const { Content, Footer } = Layout;

interface Props {
  children?: React.ReactNode;
  img?: { src: string; alt: string };
  footer?: React.ReactNode;
  isConnected: boolean;
  title: string;
  constructionSiteId?: string;
}

function Page({
  children = null,
  footer = null,
  img,
  isConnected,
  title,
  constructionSiteId,
}: Props) {
  return (
    <Layout className={styles.layout}>
      <NavBar
        isConnected={isConnected}
        title={title}
        constructionSiteId={constructionSiteId}
      />
      {img ? <img src={img.src} alt={img.alt} /> : null}
      <Content className={styles.content}>
        {children}
        <FloatButton.BackTop />
      </Content>
      {footer ? <Footer className={styles.footer}>{footer}</Footer> : null}
    </Layout>
  );
}

export default Page;
