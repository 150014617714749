import { GraphSetTypeEnum_Enum } from 'generated/graphql';

export enum PlotterMode {
  TIME_PERIOD = 'time-period',
  RING = 'ring',
}

export const graphSetStateVersion = 1;

export const mapGraphSetTypeToPlotterMode = {
  [GraphSetTypeEnum_Enum.Period]: PlotterMode.TIME_PERIOD,
  [GraphSetTypeEnum_Enum.Ring]: PlotterMode.RING,
};

export const mapPlotterModeToGraphSetType = {
  [PlotterMode.TIME_PERIOD]: GraphSetTypeEnum_Enum.Period,
  [PlotterMode.RING]: GraphSetTypeEnum_Enum.Ring,
};
