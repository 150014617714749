import { Result } from 'antd';
import React, { PropsWithChildren } from 'react';
import { i18n } from 'translations';

import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import Button from 'ui/button';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends PropsWithChildren {}

interface State {
  hasError: false;
  error?: Error;
}

class ErrorBoundaryComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    logger.error(error);
    errorReporting.warning(error);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <Result
          status="warning"
          title="There are some problems with your operation."
          extra={
            <Button
              type="primary"
              onClick={() => {
                window.location.replace('/');
              }}
            >
              {i18n.t('common.to_main_page')}
            </Button>
          }
        />
      );
    }

    return children;
  }
}

const ErrorBoundary = ErrorBoundaryComponent;

export default ErrorBoundary;
