import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  Input,
  Popconfirm,
  Space,
  Statistic,
  Tooltip,
  Typography,
  message,
} from 'antd';
import groupBy from 'lodash.groupby';
import isEqual from 'lodash.isequal';
import { useEffect, useState } from 'react';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { i18n } from 'translations';
import { useTranslation } from 'translations/hooks';

import { nameWithPreviousActivityNameAndDate } from 'business/activity/services';
import { useAppContext } from 'business/contextProviders/useAppContext';
import ReportChart from 'business/report/components/ReportChart';
import EmptyZoneModal from 'business/report/components/ReportChart/EmptyZoneModal';
import { EmptyZone } from 'business/report/components/ReportChart/services/metadata/types';
import ReportMetadataDrawer from 'business/report/components/ReportMetadataDrawer';
import SmallScreen from 'business/report/components/SmallScreenWarning/SmallScreenWarning';
import { getLastFinishedRing } from 'business/report/services/tasksOperations';
import { parseReportDateString } from 'business/report/services/timeOperations';
import NewTaskDrawer from 'business/task/components/NewTaskDrawer';
import Routes from 'config/routes';
import {
  ActivityInvertedTreeFullFragment,
  Maybe,
  Task,
  TaskFullFragment,
  useDeleteTaskMutation,
  useGenerateShiftReportPdfMutation,
  useGetPreviousReportTasksQuery,
  useGetShiftReportMetadataQuery,
  useInvalidateOperatorValidationMutation,
  useManagerReportUpdateMutation,
  useShiftReportNoteUpdateMutation,
} from 'generated/graphql';
import logger from 'technical/logger';
import { NotFound } from 'technical/router/switch/reporting-no-match';
import Button from 'ui/button';
import Loader from 'ui/loader';

import AutofillButton from './autofillButton';
import { formatRingInvalidText, getShiftTotalTime } from './chartPageServices';
import ReportChartHeaderInfo from './headerInfo';
import './index.scss';
import {
  getAuthorizedOperations,
  isAutofillOperationEnabled,
  isEditShiftReportMetaEnabled,
  isManagerValidationEnabled,
  isNoteEditionOperationEnabled,
  isOnlyOperatorValidationEnabled,
  isPreviewEnabled,
  isTaskOperationEnabled,
  isUserInvalidationEnabled,
} from './permissionsServices';

const { Title } = Typography;

type UniqueTask = {
  activityId: string;
  critical: boolean;
  duration?: Maybe<number>;
  endDate: string;
  ended: boolean;
  isValidated: boolean;
  ring: number;
  startDate: string;
};

const fields: (keyof UniqueTask)[] = [
  'activityId',
  'critical',
  'duration',
  'endDate',
  'ended',
  'isValidated',
  'ring',
  'startDate',
];

function ReportEditPage() {
  const { t } = useTranslation();
  const {
    user,
    isConstructionSiteUser,
    hasManagerEditPermission,
    currentConstructionSite,
    constructionSites,
  } = useAppContext();
  const navigate = useNavigate();
  const { id: shiftReportId } = useParams<{ id: string }>();
  invariant(shiftReportId, "id isn't set within the route");

  let lastCriticalTask: TaskFullFragment | undefined;
  let totalCriticalTasksTimeInMinutes = 0;
  let shiftTimeInMinutes = 0;

  const [taskDrawerVisibility, setTaskDrawerVisibility] =
    useState<boolean>(false);
  const [drawerTask, setDrawerTask] = useState<TaskFullFragment>();
  const [drawerTaskTime, setDrawerTaskTime] = useState<[string, string]>();
  const [drawerTaskActivity, setDrawerTaskActivity] =
    useState<ActivityInvertedTreeFullFragment>();
  const [emptyZoneModalVisibility, setEmptyZoneModalVisibility] =
    useState(false);
  const [emptyZoneModalData, setEmptyZoneModalData] = useState<EmptyZone>();
  const [reportMetadataDrawerVisibility, setReportMetadataDrawerVisibility] =
    useState<boolean>(false);
  const [reportNote, setReportNote] = useState<string>('');
  const [criticalPathValid, setCriticalPathValid] = useState<boolean>(true);
  const [ringInvalid, setRingInvalid] = useState<string[] | undefined>(
    undefined,
  );
  const [notValidatedTasks, setNotValidatedTasks] = useState<
    string[] | undefined
  >(undefined);
  const [deleteTask] = useDeleteTaskMutation();

  const {
    loading,
    error,
    data,
    refetch: refreshReportData,
  } = useGetShiftReportMetadataQuery({
    variables: {
      shiftReportId,
      constructionSiteId: currentConstructionSite?.id,
    },
    skip: !currentConstructionSite?.id,
    fetchPolicy: 'cache-and-network',
  });

  const uploadedTaskFiles =
    data?.shiftReport?.tasks?.map((task) => ({
      taskId: task.id,
      uploadedFilesCount: task.files?.length ?? 0,
    })) ?? [];

  const [shiftReportNoteUpdate] = useShiftReportNoteUpdateMutation();
  const [managerValidation] = useManagerReportUpdateMutation();
  const [invalidateOperatorValidation] =
    useInvalidateOperatorValidationMutation();

  const [generatePdf] = useGenerateShiftReportPdfMutation();

  useEffect(
    function alertUser() {
      if (error) {
        message.error(t('errors.error_generic'));
      }
    },
    [error, t],
  );

  const report = data?.shiftReport ? data.shiftReport : undefined;

  useEffect(() => {
    if (report && drawerTask) {
      const reportTask = report.tasks.find(({ id }) => id === drawerTask.id);
      const equalResult = isEqual(reportTask, drawerTask);
      if (!equalResult) {
        setDrawerTask(reportTask);
      }
    }
  }, [report, drawerTask]);

  const { data: dataPrevious } = useGetPreviousReportTasksQuery({
    variables: {
      constructionSiteId: currentConstructionSite?.id,
      date: report?.date,
      shiftReportId: report?.id,
      shiftStartTime: report?.shift.startTime,
    },
    skip: !currentConstructionSite?.id,
  });

  const previousReportLastFinishedRing =
    dataPrevious &&
    dataPrevious.shiftReport.length > 0 &&
    dataPrevious.shiftReport[0].lastFinishedRing != null
      ? dataPrevious.shiftReport[0].lastFinishedRing
      : undefined;

  const removeTask = async (taskId: string) => {
    try {
      await deleteTask({
        variables: {
          id: taskId,
        },
      });
    } catch (newTaskError) {
      message.error(
        t('pages.report.chart.drawer.taskMutationFailed', {
          context: 'delete',
        }),
      );
      logger.error(newTaskError);
    }
  };

  const isTaskUnique = (testedTask: UniqueTask, uniqueTasks: UniqueTask[]) =>
    !uniqueTasks.some((uniqueTask) =>
      fields.every((field) => testedTask[field] === uniqueTask[field]),
    );

  const removeTaskDuplicates = async () => {
    const tasks = report?.tasks ?? [];
    const uniqueTasks: typeof tasks = [];
    const duplicatedTaskIds = tasks.reduce((acc, currentTask) => {
      if (isTaskUnique(currentTask, uniqueTasks)) {
        uniqueTasks.push(currentTask);
        return acc;
      }
      return [...acc, currentTask.id];
    }, [] as string[]);

    if (duplicatedTaskIds.length > 0) {
      try {
        await Promise.all(duplicatedTaskIds.map(removeTask));
        refreshReportData();
      } catch (e) {
        logger.error(e);
      }
    }
  };

  const checkRingProgression = () => {
    const tasks = report?.tasks ?? [];
    const { criticalTasks = [], nonCriticalTasks = [] } = groupBy(
      tasks,
      (task) => (task.critical ? 'criticalTasks' : 'nonCriticalTasks'),
    );
    const invalidTasks: typeof tasks = [];

    const lastFinishedRing = previousReportLastFinishedRing;
    let previousTaskRing = -Infinity;
    criticalTasks.forEach((task) => {
      // critical tasks should always have a ring number equal or greater than the preceding critical task
      if (
        (lastFinishedRing !== undefined && task.ring <= lastFinishedRing) ||
        task.ring < previousTaskRing
      ) {
        invalidTasks.push(task);
      }
      previousTaskRing = task.ring;
    });

    function isBetween(date: Date, range: { start: Date; end: Date }) {
      return date >= range.start && date <= range.end;
    }
    function hasSameRingNumber(
      task: Pick<Task, 'ring' | 'startDate' | 'endDate'>,
    ) {
      return (criticalTask: Pick<Task, 'ring' | 'startDate' | 'endDate'>) => {
        const areConcurrentTasks =
          isBetween(task.startDate, {
            start: criticalTask.startDate,
            end: criticalTask.endDate,
          }) ||
          isBetween(task.endDate, {
            start: criticalTask.startDate,
            end: criticalTask.endDate,
          }) ||
          isBetween(criticalTask.startDate, {
            start: task.startDate,
            end: task.endDate,
          }) ||
          isBetween(criticalTask.endDate, {
            start: task.startDate,
            end: task.endDate,
          });

        if (!areConcurrentTasks) {
          return false;
        }

        return task.ring === criticalTask.ring;
      };
    }
    nonCriticalTasks.forEach((task) => {
      // non critical tasks should always have a ring number equal to one of the concurrent critical task
      if (!criticalTasks.some(hasSameRingNumber(task))) {
        invalidTasks.push(task);
      }
    });

    if (invalidTasks.length > 0) {
      setRingInvalid(
        invalidTasks.map(
          (task) =>
            `[${nameWithPreviousActivityNameAndDate(task).join(' / ')}]`,
        ),
      );
    } else if (ringInvalid && invalidTasks.length === 0) {
      setRingInvalid(undefined);
    }

    const tasksWithoutValidation = tasks.filter(
      (task) => task.isValidated === false,
    );
    if (tasksWithoutValidation.length > 0) {
      setNotValidatedTasks(
        tasksWithoutValidation.map(
          (task) =>
            `[${nameWithPreviousActivityNameAndDate(task).join(' / ')}]`,
        ),
      );
    }
  };

  // WARNING: i disabled this because i'm not confident enough to add all the required dependencies
  // for this use effect, it may lead to some bugs, and now it works as intended
  useEffect(() => {
    removeTaskDuplicates()
      .catch((e) => logger.error(e))
      .finally(() => checkRingProgression());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const criticalTasks = report?.tasks.filter((task) => task.critical);
  if (report) {
    criticalTasks?.forEach((task) => {
      if (task.duration) {
        totalCriticalTasksTimeInMinutes += task.duration;
      }
    });

    shiftTimeInMinutes = getShiftTotalTime(
      parseReportDateString(report.date),
      report.shift,
    );
    lastCriticalTask =
      criticalTasks && criticalTasks.length > 0
        ? criticalTasks[criticalTasks.length - 1]
        : undefined;
  }

  // @solal
  const handleManagerShiftReportValidation = async () => {
    const note = reportNote || report?.note;
    const lastFinishedRing = getLastFinishedRing(report?.tasks ?? []);
    try {
      await managerValidation({
        variables: { id: shiftReportId, note, lastFinishedRing },
      });
      message.success(t('pages.report.chart.updateShiftReportSuccess'));

      generatePdf({
        variables: {
          id: shiftReportId,
          language: i18n.language,
        },
      }).catch((mutationError) => logger.error(mutationError));

      navigate(generatePath(Routes.ReportEditEnd, { id: shiftReportId }));
    } catch (err) {
      message.error(t('pages.report.chart.updateShiftReportFailed'));
      logger.error(err);
    }
  };

  const handleInvalidateOperatorValidation = async () => {
    try {
      const { errors: invalidateErrors } = await invalidateOperatorValidation({
        variables: { id: shiftReportId },
      });
      if (invalidateErrors) {
        message.error(t('pages.report.chart.invalidateShiftReportFailed'));
        logger.error(invalidateErrors);
      } else {
        message.success(t('pages.report.chart.invalidateShiftReportSuccess'));
        await refreshReportData();
      }
    } catch (err) {
      message.error(t('pages.report.chart.invalidateShiftReportFailed'));
      logger.error(err);
    }
  };

  const handleNoteUpdate = async (value: string) => {
    setReportNote(value);
    try {
      await shiftReportNoteUpdate({
        variables: { id: shiftReportId, note: value },
      });
    } catch (err) {
      message.error(t('pages.report.chart.updateShiftReportNoteFailed'));
      logger.error(err);
    }
  };

  const getWarningText = (totalTimeInvalid: boolean) => {
    if (notValidatedTasks) {
      return t('pages.report.chart.operatorConfirmToValidate', {
        string: formatRingInvalidText(notValidatedTasks),
      });
    }
    if (totalTimeInvalid && ringInvalid) {
      return t('pages.report.chart.operatorConfirmTimeAndRing', {
        string: formatRingInvalidText(ringInvalid),
        count: ringInvalid.length,
      });
    }
    if (ringInvalid) {
      return t('pages.report.chart.operatorConfirmRing', {
        string: formatRingInvalidText(ringInvalid),
        count: ringInvalid.length,
      });
    }
    return t('pages.report.chart.operatorConfirmTime');
  };

  const handleEmptyZoneClick = (_event: any, emptyZoneData: EmptyZone) => {
    setEmptyZoneModalVisibility(true);
    setEmptyZoneModalData(emptyZoneData);
  };

  const handleTaskEdit = (_event: any, task: TaskFullFragment) => {
    if (task) {
      setDrawerTask(task);
      setTaskDrawerVisibility(true);
    }
  };

  const duplicateTask = () => {
    setDrawerTaskActivity(drawerTask?.activity);
    setDrawerTask(undefined);
    setDrawerTaskTime(undefined);
  };

  const newTaskFromTime = (startTime: string, endTime: string) => {
    if (startTime && endTime) {
      setDrawerTaskTime([startTime, endTime]);
      setTaskDrawerVisibility(true);
    }
  };

  const closeTaskDrawer = () => {
    setTaskDrawerVisibility(false);
  };

  const closeReportMetadataDrawer = () => {
    refreshReportData();
    setReportMetadataDrawerVisibility(false);
  };

  const resetDrawer = () => {
    setDrawerTask(undefined);
    setDrawerTaskTime(undefined);
    setDrawerTaskActivity(undefined);
  };

  const isReportComplete = () => {
    const isCriticalTimeValid =
      totalCriticalTasksTimeInMinutes === shiftTimeInMinutes;
    const hasNonValidatedTask = report?.tasks.some(
      ({ isValidated }) => isValidated === false,
    );

    return isCriticalTimeValid && !ringInvalid && !hasNonValidatedTask;
  };

  const enabledOperations = getAuthorizedOperations({
    user,
    report,
    constructionSite: currentConstructionSite || undefined,
    isConstructionSiteUser,
    hasManagerEditPermission,
  });

  if (
    !loading &&
    !report?.constructionSite &&
    !constructionSites.find(({ id }) => id === report?.constructionSite.id)
  ) {
    return <NotFound />;
  }

  return (
    <div className="report-edit-page page-appear">
      {/* Header Informations */}
      {report && (
        <>
          <ReportChartHeaderInfo report={report} />
          <div className="wrapper-stats-tasks-cta">
            {isTaskOperationEnabled(enabledOperations) && (
              <div className="item-spacer" />
            )}
            <Statistic
              className="stats-info"
              title={t('pages.report.chart.totalTime')}
              value={totalCriticalTasksTimeInMinutes}
              suffix={`/ ${shiftTimeInMinutes} min`}
              prefix={<ClockCircleOutlined />}
              groupSeparator=" "
            />
            {(isTaskOperationEnabled(enabledOperations) ||
              isAutofillOperationEnabled(enabledOperations)) && (
              <div className="item-tasks-cta">
                {isTaskOperationEnabled(enabledOperations) && (
                  <Button
                    onClick={() => setTaskDrawerVisibility(true)}
                    type="primary"
                    className="task-cta"
                    icon={<PlusCircleOutlined />}
                  >
                    {t('pages.report.chart.addNewTaskBtnLabel')}
                  </Button>
                )}
                {isAutofillOperationEnabled(enabledOperations) &&
                  currentConstructionSite?.isAutofillEnabled && (
                    <div className="wrapper-column-task-cta">
                      <AutofillButton
                        shiftReportId={shiftReportId}
                        report={report}
                        refreshReportData={refreshReportData}
                      />
                    </div>
                  )}
              </div>
            )}
          </div>
        </>
      )}

      {/* Shift Report Chart */}
      {!data && loading && <Loader />}
      {data && report && (
        <ReportChart
          shift={report.shift}
          date={report.date}
          activities={data.activities}
          tasks={report.tasks}
          externalEventHandlers={
            isTaskOperationEnabled(enabledOperations)
              ? {
                  handleTaskMouseClick: handleTaskEdit,
                  handleEmptyZoneClick,
                }
              : undefined
          }
          criticalPathValid={criticalPathValid}
        />
      )}

      {/* Notes */}
      {isNoteEditionOperationEnabled(enabledOperations) && (
        <div className="footer-info">
          <Title level={4}>{t('pages.form.note.label')}</Title>
          {!loading && (
            <Input.TextArea
              defaultValue={report?.note || reportNote}
              onBlur={(e) => handleNoteUpdate(e.currentTarget.innerHTML)}
              style={{ width: '100%' }}
              placeholder={t('pages.form.note.shiftReportPlaceholder')}
              autoSize={{ minRows: 3, maxRows: 8 }}
            />
          )}
        </div>
      )}

      {/* Footer options */}
      <Space direction="horizontal" align="center" className="button-center">
        {/* Validate to end form (user + manager author) */}
        {isOnlyOperatorValidationEnabled(enabledOperations) && (
          <>
            {isReportComplete() ? (
              // Validate to end form (report is complete)
              <Link
                to={Routes.ReportEditFinishing.replace(':id', shiftReportId)}
              >
                <Button type="primary" icon={<CheckOutlined />}>
                  {t('pages.report.chart.validate')}
                </Button>
              </Link>
            ) : (
              // @solal
              // Popup confirm if not complete
              <Popconfirm
                title={getWarningText(
                  totalCriticalTasksTimeInMinutes !== shiftTimeInMinutes,
                )}
                onCancel={() => {}}
                okText={t('pages.report.chart.managerValidate')}
                cancelText={t('common.cancel')}
                okButtonProps={{
                  disabled: true,
                }}
              >
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  onClick={() => {
                    setCriticalPathValid(
                      totalCriticalTasksTimeInMinutes === shiftTimeInMinutes,
                    );
                  }}
                >
                  {t('pages.report.chart.validate')}
                </Button>
              </Popconfirm>
            )}
          </>
        )}

        {/* Manager validation */}
        {isManagerValidationEnabled(enabledOperations) && (
          <Popconfirm
            title={t('pages.report.chart.managerConfirm')}
            onConfirm={handleManagerShiftReportValidation}
            cancelText={t('common.cancel')}
          >
            <Button type="primary" icon={<CheckOutlined />}>
              {t('pages.report.chart.managerValidate')}
            </Button>
          </Popconfirm>
        )}

        {/* Open metadata editor drawer */}
        {isEditShiftReportMetaEnabled(enabledOperations) && (
          <Button
            icon={<EditOutlined />}
            onClick={() => setReportMetadataDrawerVisibility(true)}
          >
            {t('pages.report.chart.editReportMetadataFields')}
          </Button>
        )}

        {/* Preview report */}
        {isPreviewEnabled(enabledOperations) && (
          <Link to={Routes.ReportView.replace(':id', shiftReportId)}>
            <Button type="default" icon={<EyeOutlined />}>
              {t('pages.report.chart.preview')}
            </Button>
          </Link>
        )}

        {/* Close button */}
        <Link to={Routes.Home}>
          <Button type="default" icon={<CloseOutlined />}>
            {t('pages.report.chart.close')}
          </Button>
        </Link>
      </Space>

      <Space direction="horizontal" align="center" className="button-center">
        {/* Invalidate operator validation */}
        {isUserInvalidationEnabled(enabledOperations) && (
          <Tooltip
            placement="top"
            title={t('pages.report.chart.invalidateOperatorValidationHelper')}
            arrowPointAtCenter
          >
            <Button
              icon={<RollbackOutlined />}
              onClick={handleInvalidateOperatorValidation}
            >
              {t('pages.report.chart.invalidateOperatorValidationButton')}
            </Button>
          </Tooltip>
        )}
      </Space>

      {/* Modals/Drawers definition */}
      {report && (
        <>
          <EmptyZoneModal
            emptyZoneData={emptyZoneModalData}
            visible={emptyZoneModalVisibility}
            closeModal={() => setEmptyZoneModalVisibility(false)}
            newTaskFromTime={newTaskFromTime}
          />
          <NewTaskDrawer
            visible={taskDrawerVisibility}
            closeDrawer={closeTaskDrawer}
            resetDrawer={resetDrawer}
            duplicateTask={duplicateTask}
            shiftReportId={shiftReportId}
            activities={data?.activities}
            excavationOrBuildActivities={data?.excavationOrBuildActivities}
            task={drawerTask}
            previousTask={lastCriticalTask}
            criticalTasks={criticalTasks}
            previousRing={previousReportLastFinishedRing}
            taskTime={drawerTaskTime}
            taskActivity={drawerTaskActivity}
            date={parseReportDateString(report.date)}
            shift={report.shift}
            uploadedTaskFiles={uploadedTaskFiles}
          />
          <ReportMetadataDrawer
            shiftReport={report}
            visible={reportMetadataDrawerVisibility}
            closeEndFieldsDrawer={closeReportMetadataDrawer}
            constructionSiteId={currentConstructionSite?.id}
          />
        </>
      )}
      <SmallScreen />
    </div>
  );
}

export default ReportEditPage;
