import FullNavBar from './components/fullNavBar';

interface Props {
  isConnected?: boolean;
  title: string;
  constructionSiteId?: string;
}

export default function NavBar({
  isConnected,
  title,
  constructionSiteId,
}: Props) {
  return (
    <FullNavBar
      isConnected={isConnected}
      title={title}
      constructionSiteId={constructionSiteId}
    />
  );
}
