import { Form, Input } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'translations/hooks';

import GraphSetSideBar from 'business/data-analysis/components/graph-set-side-bar';
import { SaveGraphSetForm } from 'business/data-analysis/pages/graph/types';
import Button from 'ui/button';
import Flex from 'ui/flex';

import styles from './index.module.scss';

interface Props {
  title: string;
  visible: boolean;
  savePending: boolean;
  onSave: (values: SaveGraphSetForm) => void;
  onCloseSaveSideBar: () => void;
}

const SaveSideBar = ({
  title,
  visible,
  savePending,
  onSave,
  onCloseSaveSideBar,
}: Props) => {
  const { t } = useTranslation();
  const [form] = useForm<SaveGraphSetForm>();
  const [submittable, setSubmittable] = useState(false);

  const values = useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <GraphSetSideBar
      title={title}
      visible={visible}
      onClose={onCloseSaveSideBar}
    >
      <Form form={form} onFinish={() => onSave(values)} layout="vertical">
        <Form.Item
          label={t('dataAnalysis.saveSideBar.setTitle')}
          name="title"
          rules={[
            {
              required: true,
              message: t('dataAnalysis.saveSideBar.requiredTitle'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Flex className={styles.actionsContainer}>
          <Form.Item>
            <Button onClick={() => onCloseSaveSideBar()}>
              {t('dataAnalysis.saveSideBar.cancelAction')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              loading={savePending}
              htmlType="submit"
              disabled={!submittable}
              type="primary"
            >
              {t('dataAnalysis.saveSideBar.saveAction')}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </GraphSetSideBar>
  );
};

export default SaveSideBar;
