import { Button, Flex } from 'antd';

import CardValue from 'business/dashboard/components/card-value';
import Card from 'ui/card';

import styles from './index.module.scss';
const ConstructionDashboardPage = () => {
  return (
    <Flex className={styles.page}>
      <div className={styles.grid}>
        <div className={styles.tunnelBoringMachine}>
          <Card className={styles.card}>
            <Card.Header title={'TBM'} />
          </Card>
        </div>
        <div className={styles.dailyProgress}>
          <Card className={styles.card}>
            <Card.Header
              title={'Daily Progress'}
              actions={<Button title={'actions'}>actions</Button>}
            />
            <Flex vertical gap={10}>
              <Flex gap={10} justify={'stretch'} align={'stretch'}>
                <CardValue title="Creusé" value="14 mètres" fullWidth />
                <CardValue title="Anneaux posés" value="20" fullWidth />
              </Flex>

              <Flex gap={10}>
                <CardValue title="Post 1" value="4 anneaux" fullWidth />
                <CardValue title="Post 2" value="10 anneaux" fullWidth />
                <CardValue title="Post 3" value="6 anneaux" fullWidth />
              </Flex>
            </Flex>
          </Card>
        </div>
        <div className={styles.progressHistory}>
          <Card className={styles.card}>
            <Card.Header
              title={'Progress history'}
              actions={<Button title={'actions'}>actions</Button>}
            />
          </Card>
        </div>
        <div className={styles.performance}>
          <Card className={styles.card}>
            <Card.Header
              title={'Performance'}
              actions={<Button title={'actions'}>actions</Button>}
            />
            <Flex vertical gap={10}>
              <Flex gap={10}>
                <CardValue
                  title="Creusement d'anneau le plus rapide"
                  value="30 min"
                  footer="HT-94839"
                />
                <CardValue
                  title="Pose d'anneau la plus rapide"
                  value="26 min"
                  footer="HT-7863"
                />
                <CardValue
                  title="Pose d'anneau la plus rapide"
                  value="26 min"
                  footer="HT-7863"
                />
              </Flex>
              <Flex gap={10}>
                <CardValue
                  title="Creusement d'anneau le plus rapide"
                  value="30 min"
                  footer="HT-94839"
                />
                <CardValue
                  title="Pose d'anneau la plus rapide"
                  value="26 min"
                  footer="HT-7863"
                />
              </Flex>
            </Flex>
          </Card>
        </div>
      </div>
    </Flex>
  );
};

export default ConstructionDashboardPage;
