import { Typography } from 'antd';
import { useTranslation } from 'translations/hooks';

import Flex from 'ui/flex';
import GraphEmptyState from 'ui/icons/graphEmptyState';

import styles from './index.module.scss';

export const EmptyGraph = () => {
  const { t } = useTranslation();
  return (
    <Flex column className={styles.graph}>
      <Flex
        column
        alignItems="center"
        className={styles.helper}
        justify="space-around"
      >
        <GraphEmptyState />
        <Typography.Text>
          {t('dataAnalysis.emptyGraph.helper.instruction')}
        </Typography.Text>
        <Flex className={styles.instruction}>
          <Typography.Text strong>1.</Typography.Text>
          <Typography.Text>
            {t('dataAnalysis.emptyGraph.helper.subInstruction1')}
          </Typography.Text>
        </Flex>
        <Flex className={styles.instruction}>
          <Typography.Text strong>2.</Typography.Text>
          <Typography.Text>
            {t('dataAnalysis.emptyGraph.helper.subInstruction2')}
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
