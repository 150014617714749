import Icon from '@ant-design/icons/lib/components/Icon';

import { CustomIconProps } from 'ui/icons/types';

const MenuSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <title>menu icon</title>
    <path
      d="M14 20.4V14.6C14 14.2686 14.2686 14 14.6 14H20.4C20.7314 14 21 14.2686 21 14.6V20.4C21 20.7314 20.7314 21 20.4 21H14.6C14.2686 21 14 20.7314 14 20.4Z"
      strokeWidth="1.5"
    />
    <path
      d="M3 20.4V14.6C3 14.2686 3.26863 14 3.6 14H9.4C9.73137 14 10 14.2686 10 14.6V20.4C10 20.7314 9.73137 21 9.4 21H3.6C3.26863 21 3 20.7314 3 20.4Z"
      strokeWidth="1.5"
    />
    <path
      d="M14 9.4V3.6C14 3.26863 14.2686 3 14.6 3H20.4C20.7314 3 21 3.26863 21 3.6V9.4C21 9.73137 20.7314 10 20.4 10H14.6C14.2686 10 14 9.73137 14 9.4Z"
      strokeWidth="1.5"
    />
    <path
      d="M3 9.4V3.6C3 3.26863 3.26863 3 3.6 3H9.4C9.73137 3 10 3.26863 10 3.6V9.4C10 9.73137 9.73137 10 9.4 10H3.6C3.26863 10 3 9.73137 3 9.4Z"
      strokeWidth="1.5"
    />
  </svg>
);

const MenuIcon = (props: CustomIconProps) => (
  <Icon component={MenuSvg} {...props} />
);

export default MenuIcon;
