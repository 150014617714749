import dayjs from 'dayjs';

import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';

/**
 * This function must be equivalent to the back function `formatRingDuration`
 * in back/custom-server/src/business/production-and-performance/production-and-performance-helper.service.ts
 * */
export const formatRingDuration = (
  durationString: string | null | undefined,
) => {
  if (isUndefinedOrNull(durationString)) {
    return '-';
  }

  const duration = dayjs.duration(durationString);

  if (!dayjs.isDuration(duration)) {
    return '-';
  }

  const hours = duration.asHours().toFixed(0);
  const minutes = duration.minutes().toString().padStart(2, '0');

  const formattedDuration = `${hours}:${minutes}`;

  return formattedDuration;
};
