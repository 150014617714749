import { FileFilled, PieChartFilled } from '@ant-design/icons';
import { message, Tabs, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import MaintenanceAlert from 'business/user/components/maintenanceAlert';
import Routes from 'config/routes';
import {
  useAvailableCustomIndicatorsQuery,
  useCountShiftReportToValidateQuery,
} from 'generated/graphql';
import useSearchParamQuery from 'technical/router/hooks/useSearchParamQuery';
import Flex from 'ui/flex';

import aggregatedReport from './assets/aggregatedReport.svg';
import bi from './assets/bi.svg';
import historyIcon from './assets/history.svg';
import newReport from './assets/newReport.svg';
import pendingReport from './assets/pendingReport.svg';
import waitingValidationIcon from './assets/waitingValidation.svg';
import weekReport from './assets/weekReport.svg';
import DashboardTile from './DashboardTile';
import styles from './index.module.scss';

const { Text } = Typography;

type TabKeys = 'report' | 'analyze';

function getTabKeyFromQueryParam(tabParam: string | null): TabKeys {
  if (tabParam === 'report' || tabParam === 'analyze') {
    return tabParam;
  }
  return 'report';
}

function DashboardPage() {
  const { t } = useTranslation();
  const searchParamsQuery = useSearchParamQuery();
  const {
    currentConstructionSite,
    hasManagerReadPermission,
    hasManagerEditPermission,
  } = useAppContext();
  const navigate = useNavigate();
  const [currentTabKey, setCurrentTabKey] = useState<TabKeys>(
    getTabKeyFromQueryParam(searchParamsQuery.get('tab')),
  );

  const managerValidationRequired =
    currentConstructionSite?.managerValidationRequired;
  const id = currentConstructionSite?.id;

  const countShiftReportToValidateQuery = useCountShiftReportToValidateQuery({
    variables: { constructionSiteId: id },
    fetchPolicy: 'network-only',
    skip: !hasManagerReadPermission,
  });

  const indicatorQuery = useAvailableCustomIndicatorsQuery({
    variables: { constructionSiteId: id },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (countShiftReportToValidateQuery.error || indicatorQuery.error) {
      message.error(t('errors.error_generic'));
    }
  }, [countShiftReportToValidateQuery.error, indicatorQuery.error, t]);

  const handleChangeTab = (key: string) => {
    setCurrentTabKey(key as TabKeys);
    navigate({ search: `?tab=${key}` });
  };

  const loading =
    countShiftReportToValidateQuery.loading || indicatorQuery.loading;

  const count =
    countShiftReportToValidateQuery.data?.shiftReportCount.aggregate?.count;
  const shiftReportToValidationText = countShiftReportToValidateQuery.data
    ?.shiftReportCount.aggregate ? (
    <Text>
      {t('pages.manager.validation.text', {
        count: count || 0,
        context: hasManagerEditPermission ? 'manager' : 'reader',
      })}
    </Text>
  ) : (
    <Text>{t('pages.manager.validation.textError')}</Text>
  );

  return (
    <Tabs
      tabPosition="left"
      tabBarStyle={{ backgroundColor: 'white', padding: '15px' }}
      activeKey={currentTabKey}
      onTabClick={handleChangeTab}
      items={[
        {
          label: (
            <>
              <FileFilled />
              {t('pages.manager.tabs.visualize')}
            </>
          ),
          key: 'report',
          children: (
            <div className={styles.container}>
              <MaintenanceAlert />
              <Flex
                justify="flex-start"
                alignContent="flex-start"
                wrap="wrap"
                className={styles.menuContainer}
              >
                {managerValidationRequired &&
                  hasManagerReadPermission &&
                  !currentConstructionSite?.isCompleted && (
                    <DashboardTile
                      title={t('pages.manager.validation.title')}
                      icon={waitingValidationIcon}
                      loading={loading}
                      text={shiftReportToValidationText}
                      route={Routes.ReportListToValidate}
                      ctaLabel={t('pages.manager.validation.button')}
                    />
                  )}
                {hasManagerReadPermission &&
                  !currentConstructionSite?.isCompleted && (
                    <DashboardTile
                      title={t('pages.manager.pending.title')}
                      icon={pendingReport}
                      loading={false}
                      route={Routes.ReportListInProgress}
                      ctaLabel={t('pages.manager.pending.button')}
                    />
                  )}
                <DashboardTile
                  title={t('pages.manager.history.title')}
                  icon={historyIcon}
                  loading={false}
                  route={Routes.ReportListValidated}
                  ctaLabel={t('pages.manager.history.button')}
                />
                {hasManagerEditPermission &&
                  !currentConstructionSite?.isCompleted && (
                    <DashboardTile
                      title={t('pages.manager.new.title')}
                      icon={newReport}
                      loading={false}
                      route={Routes.ReportCreate}
                      ctaLabel={t('pages.manager.new.button')}
                    />
                  )}
              </Flex>
            </div>
          ),
        },
        {
          key: 'analyze',
          label: (
            <>
              <PieChartFilled />
              {t('pages.manager.tabs.analyze')}
            </>
          ),
          children: (
            <div className={styles.container}>
              <MaintenanceAlert />
              <Flex
                justify="flex-start"
                alignContent="flex-start"
                wrap="wrap"
                className={styles.menuContainer}
              >
                <DashboardTile
                  title={t('pages.manager.aggregatedReport.title')}
                  icon={aggregatedReport}
                  loading={false}
                  route={Routes.ReportExportAggregated}
                  ctaLabel={t('pages.manager.aggregatedReport.button')}
                />
                <DashboardTile
                  title={t('pages.manager.batchReport.title')}
                  icon={weekReport}
                  loading={false}
                  route={Routes.ReportExportBatch}
                  ctaLabel={t('pages.manager.batchReport.button')}
                />
                <DashboardTile
                  title={t('pages.manager.taskAnalyzer.title')}
                  icon={bi}
                  loading={false}
                  route={Routes.TaskAnalyzer}
                  ctaLabel={t('pages.manager.taskAnalyzer.button')}
                />
                {indicatorQuery.data?.indicator.length ? (
                  <DashboardTile
                    title={t('pages.manager.indicators.title')}
                    icon={bi}
                    loading={false}
                    route={Routes.Indicators}
                    ctaLabel={t('pages.manager.indicators.button')}
                  />
                ) : null}
                <DashboardTile
                  title={t('pages.manager.taskExport.title')}
                  icon={bi}
                  loading={false}
                  route={Routes.TaskExport}
                  ctaLabel={t('pages.manager.taskExport.button')}
                />
              </Flex>
            </div>
          ),
        },
      ]}
    />
  );
}

export default DashboardPage;
