import { useTranslation } from 'translations/hooks';

import AggregatedProductionGraph from 'business/report/components/AggregatedProductionGraph';
import { toPercent } from 'technical/utils/converter';

import { getTotalTime } from './services';
import { TopLevelTasksAggregate } from './types';

interface AggregatedGraphProps {
  topLevelTasksAggregate: TopLevelTasksAggregate[];
  critical: boolean;
}
interface GraphData {
  key: string;
  name: string;
  totalTime: number;
  relativeTime: number;
}

export default function AggregatedGraph({
  topLevelTasksAggregate,
  critical,
}: AggregatedGraphProps) {
  const { t } = useTranslation();

  const totalTime = getTotalTime(topLevelTasksAggregate);
  const aggregatedTasks = topLevelTasksAggregate.reduce((acc, task) => {
    const relativeTime = toPercent(task.total / totalTime);
    return [
      ...acc,
      {
        key: task.id,
        name: task.name,
        totalTime: task.total,
        relativeTime: task.total ? relativeTime : 0,
      },
    ];
  }, [] as GraphData[]);

  return (
    <div key="graph-container" className="bloc">
      <h1>
        {t('pages.print.shiftProductionTime', {
          context: critical ? 'critical' : '',
        })}
      </h1>
      <AggregatedProductionGraph t={t} aggregatedTasks={aggregatedTasks} />
    </div>
  );
}
