import { gql } from '@apollo/client';

import {
  ActivityFragment,
  ActivityInvertedTreeFragment,
  ActivityWithoutHiddenTreeFragment,
} from 'business/activity/query.gql';
import { ShiftReportCardInfoFragment } from 'business/report/query.gql';

const ShiftFragment = gql`
  fragment ShiftFull on shift {
    id
    constructionSiteId
    startTime
    endTime
    name
  }
`;

export const TaskFragment = gql`
  fragment TaskFull on task {
    id
    activityId
    activity {
      ...ActivityInvertedTreeFull
    }
    startDate
    endDate
    duration
    ring
    note
    critical
    files
    ended
    isValidated
  }
  ${ActivityInvertedTreeFragment}
`;

export const ShiftReportChartViewFragment = gql`
  fragment ShiftReportChartView on shiftReport {
    ...ShiftReportCardInfo
    operatorValidation
    managerValidation
    shift {
      ...ShiftFull
    }
    tasks(order_by: { startDate: asc }) {
      ...TaskFull
    }
    note
    startMetricPoint
    endMetricPoint
    lastFinishedRing
    userId
  }
  ${ShiftReportCardInfoFragment}
  ${ShiftFragment}
  ${TaskFragment}
`;

export const QUERY_SHIFT_REPORT_METADATA = gql`
  query GetShiftReportMetadata(
    $shiftReportId: uuid!
    $constructionSiteId: uuid
  ) {
    shiftReport: shiftReport_by_pk(id: $shiftReportId) {
      ...ShiftReportChartView
      constructionSite {
        id
        name
        logo
      }
    }
    activities: activity(
      where: {
        parentId: { _is_null: true }
        constructionSiteId: { _eq: $constructionSiteId }
        hidden: { _eq: false }
      }
      order_by: { order: asc }
    ) {
      ...ActivityWithoutHiddenTreeFull
    }
    excavationOrBuildActivities: activity(
      where: {
        _or: [{ type: { _eq: "excavation" } }, { type: { _eq: "build" } }]
        constructionSiteId: { _eq: $constructionSiteId }
      }
    ) {
      ...ActivityFull
    }
  }
  ${ShiftReportChartViewFragment}
  ${ActivityWithoutHiddenTreeFragment}
  ${ActivityFragment}
`;

export const REPORT_NOTE_UPDATE = gql`
  mutation ShiftReportNoteUpdate($id: uuid!, $note: String) {
    update_shiftReport_by_pk(pk_columns: { id: $id }, _set: { note: $note }) {
      id
      note
    }
  }
`;

export const MANAGER_REPORT_UPDATE = gql`
  mutation ManagerReportUpdate(
    $id: uuid!
    $note: String
    $lastFinishedRing: Int
  ) {
    update_shiftReport_by_pk(
      pk_columns: { id: $id }
      _set: {
        managerValidation: true
        note: $note
        lastFinishedRing: $lastFinishedRing
      }
    ) {
      id
    }
  }
`;

export const INVALIDATE_OPERATOR_VALIDATION = gql`
  mutation InvalidateOperatorValidation($id: uuid!) {
    update_shiftReport_by_pk(
      pk_columns: { id: $id }
      _set: { operatorValidation: false }
    ) {
      id
    }
  }
`;

export const GET_PREVIOUS_REPORT_TASKS = gql`
  query GetPreviousReportTasks(
    $constructionSiteId: uuid
    $date: date
    $shiftReportId: uuid
    $shiftStartTime: time
  ) {
    shiftReport(
      where: {
        id: { _neq: $shiftReportId }
        constructionSiteId: { _eq: $constructionSiteId }
        _or: [
          {
            _and: [
              { date: { _eq: $date } }
              { shift: { startTime: { _lt: $shiftStartTime } } }
            ]
          }
          { date: { _lt: $date } }
        ]
      }
      order_by: [{ date: desc }, { shift: { startTime: desc } }]
      limit: 1
    ) {
      id
      lastFinishedRing
      tasks(order_by: { startDate: desc }, limit: 1) {
        id
        ring
        ended
      }
    }
  }
`;

export const SHIFT_REPORT_AUTOFILL = gql`
  mutation ShiftReportAutofill(
    $shiftReportId: ID!
    $startDate: date!
    $endDate: date!
  ) {
    shiftReportAutofill(
      input: {
        shiftReportId: $shiftReportId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      success
      count
      message
    }
  }
`;
