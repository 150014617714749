import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'translations/hooks';

import { ProdPerfPageTemplate } from 'business/production-and-performance/components/prod-perf-page-template';
import {
  RingPerDayFilter,
  RingPerDayFilters,
} from 'business/production-and-performance/components/ring-per-day-filters';
import { RingPerDayGraphContainer } from 'business/production-and-performance/components/ring-per-day-graph-container';
import { RingPerDayHeader } from 'business/production-and-performance/components/ring-per-day-header';

const RingPerDayPage = () => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<RingPerDayFilter>({
    dayMode: 'shift',
    values: [dayjs().subtract(1, 'week'), dayjs()],
  });

  const handleFilterChange = (newFilter: RingPerDayFilter) => {
    setFilters(newFilter);
  };

  return (
    <ProdPerfPageTemplate
      title={t('productionAndPerformance.ringPerDay.title')}
      header={<RingPerDayHeader filters={filters} />}
      filters={
        <RingPerDayFilters
          filters={filters}
          onFilterChange={handleFilterChange}
        />
      }
    >
      <RingPerDayGraphContainer filters={filters} />
    </ProdPerfPageTemplate>
  );
};

export default RingPerDayPage;
