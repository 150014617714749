import { defaultColors } from 'business/data-analysis/components/graphCard/constants';
import {
  GraphSetStateDto,
  GraphStateDto,
  ParameterDto,
} from 'generated/apiSchemas';
import {
  GraphSetTypeEnum_Enum,
  ParameterDefFragment,
  useParameterFamiliesQuery,
} from 'generated/graphql';

export const useParameters = (
  graphSet: GraphSetStateDto,
  updateGraph: (state: GraphStateDto) => void,
  constructionSiteId?: string,
) => {
  const { data: parameterFamilies } = useParameterFamiliesQuery({
    variables: { constructionSiteId },
    skip: !constructionSiteId,
  });

  const findGraphToUpdate = (graphIndex: number) => {
    return graphSet.graphs.find((graph) => graph.index === graphIndex);
  };

  const updateGraphParameter = (graphIndex: number) => {
    return (updatedParameter: ParameterDto) => {
      const graphStateToUpdate = findGraphToUpdate(graphIndex);
      if (!graphStateToUpdate) {
        return;
      }

      const indexToUpate = graphStateToUpdate.parameters.findIndex(
        (parameter) => parameter.index === updatedParameter.index,
      );
      graphStateToUpdate.parameters[indexToUpate] = updatedParameter;

      updateGraph({
        ...graphStateToUpdate,
      });
    };
  };

  const removeGraphParameter =
    (graphIndex: number) => (parameterIndexToRemove: number) => {
      const graphStateToUpdate = findGraphToUpdate(graphIndex);
      if (!graphStateToUpdate) {
        return;
      }

      // update graph state
      const updatedGraphParameter = graphStateToUpdate.parameters.filter(
        (parameter) => {
          return (
            parameter.index.toString() !== parameterIndexToRemove.toString()
          );
        },
      );
      updateGraph({
        ...graphStateToUpdate,
        parameters: updatedGraphParameter,
      });
    };

  const removeAllGraphParameters = (graphIndex: number) => () => {
    const graphStateToUpdate = findGraphToUpdate(graphIndex);
    if (!graphStateToUpdate) {
      return;
    }

    // update graph state
    updateGraph({ ...graphStateToUpdate, parameters: [] });
  };

  const findParametersInFamilies = (parametersIds: React.Key[]) => {
    const flatParameterArray = (
      parameterFamilies?.parameter_familyDef || []
    ).flatMap((family) => family.parameterDefs);

    return parametersIds.map((parameterId) => {
      return flatParameterArray.find(
        // convert both to string so comparison between React.Key and number can work
        (parameter) => parameter.id.toString() === parameterId.toString(),
      );
    });
  };

  const initParameterDefaultValues = (
    startIndex: number,
    plotterMode: GraphSetTypeEnum_Enum,
    allParameters: ParameterDefFragment[],
  ) =>
    allParameters.reduce<ParameterDto[]>((acc, parameter, index) => {
      acc.push({
        ...parameter,
        index,
        values: [],
        color: defaultColors[(index + startIndex) % defaultColors.length],
        ...(plotterMode === GraphSetTypeEnum_Enum.Ring
          ? { aggregationType: 'avg', computeType: 'total' }
          : {}),
      });
      return acc;
    }, []);

  const addParametersToChart =
    (graphIndex: number) => (parameterSelection: React.Key[]) => {
      const graphStateToUpdate = graphSet.graphs.find(
        (graph) => graph.index === graphIndex,
      );
      if (!graphStateToUpdate) {
        return;
      }

      const selectedParametersForGraph = parameterSelection;
      const parametersToAdd = findParametersInFamilies(
        selectedParametersForGraph,
      ).filter((parameter): parameter is ParameterDefFragment => !!parameter);

      const oldParameters = graphStateToUpdate.parameters;

      const lastIndex = oldParameters[oldParameters.length - 1]?.index ?? 0;

      const newParametersWithInitValues = initParameterDefaultValues(
        // Simulate the full list of params to not set the same color each time the user add a parameter
        oldParameters.length,
        // Originaly typed as string because of Swagger type generation
        // But the type is set in front/src/business/data-analysis/pages/graph/hooks/graphSetState.ts
        graphSet.type as GraphSetTypeEnum_Enum,
        parametersToAdd,
      ).map((param, index) => ({ ...param, index: lastIndex + index + 1 }));

      updateGraph({
        ...graphStateToUpdate,
        parameters: [...oldParameters, ...newParametersWithInitValues],
      });
    };

  const atLeastOneParameterIsSelected = graphSet.graphs.reduce(
    (acc, nextGraphState) => {
      return nextGraphState.parameters.length > 0 || acc;
    },
    false,
  );

  return {
    parameterFamilies: parameterFamilies?.parameter_familyDef || [],
    addParametersToChart,
    updateGraphParameter,
    removeGraphParameter,
    removeAllGraphParameters,
    atLeastOneParameterIsSelected,
  };
};
