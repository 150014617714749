import dayjs from 'dayjs';

import { SIMPLE_DATE_FORMAT } from 'technical/string/formatter';

export function calculateBusinessDays(
  firstDate: string,
  secondDate: string,
  nbDaysPerWeek: number,
) {
  const nbDaysPerWeekMoment = nbDaysPerWeek - 1;
  let day1 = dayjs(firstDate).startOf('day');
  let day2 = dayjs(secondDate).startOf('day');
  let adjust = 1;

  if (day1.dayOfYear() === day2.dayOfYear() && day1.year() === day2.year()) {
    return 1;
  }

  if (day2.isBefore(day1)) {
    const temp = day1;
    day1 = day2;
    day2 = temp;
  }

  // Check if first date starts on weekends
  if (day1.day() === nbDaysPerWeekMoment + 1) {
    // 1st day of weekend
    // Move date to next week monday
    day1.day(8);
  } else if (day1.day() === 0) {
    // Sunday
    // Move date to current week monday
    day1.day(1);
  }

  // Check if second date starts on weekends
  if (day2.day() === nbDaysPerWeekMoment + 1) {
    // 1st day of weekend
    // Move date to current week last working day
    day2.day(nbDaysPerWeekMoment);
  } else if (day2.day() === 0) {
    // Sunday
    // Move date to previous week last working day
    day2.day(nbDaysPerWeekMoment - 6);
  }

  const day1Week = day1.week();
  let day2Week = day2.week();

  // Check if two dates are in different week of the year
  if (day1Week !== day2Week) {
    // Check if second date's year is different from first date's year
    if (day2Week < day1Week) {
      day2Week += day1Week;
    }
    // Calculate adjust value to be substracted from difference between two dates
    adjust += (nbDaysPerWeekMoment - 6) * (day2Week - day1Week);
  }

  return day2.diff(day1, 'days') + adjust;
}

export function formatDurationToReadable(duration: number) {
  const hour = Math.floor(duration / 60);
  const min = duration % 60;
  let displayedMin = '';
  if (min === 0) {
    displayedMin = ``;
  } else if (min <= 9) {
    displayedMin = `0${min}min`;
  } else {
    displayedMin = `${min}min`;
  }
  return `${hour}h${displayedMin}`;
}

export function parseReportDateString(dateString: string) {
  return dayjs(dateString);
}

export function formatReportDateStringToLocalizedDateString(
  dateString: string,
) {
  return dayjs(dateString).format('L');
}

export function formatReportDateStringToLocalizedDateStringWithDayOfWeek(
  dateString: string,
) {
  return dayjs(dateString).format('ddd L');
}

export function parseReportDateStringStrict(dateString: string) {
  return dayjs(dateString, SIMPLE_DATE_FORMAT, true);
}
