import {
  CloudSyncOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Col, Modal, Popconfirm, Row, TimePicker, message } from 'antd';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'translations/hooks';

import { parseReportDateString } from 'business/report/services/timeOperations';
import {
  getDisabledTimeForShift,
  isTaskInShiftRange,
  setTimeRangeDate,
} from 'business/task/components/NewTaskDrawer/services';
import {
  ShiftReportChartViewFragment,
  TaskFullFragment,
  useDeleteNotValidatedTasksMutation,
  useShiftReportAutofillMutation,
} from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import { TIME_HOUR_MINUTE_FORMAT } from 'technical/string/formatter';
import Button from 'ui/button';

interface Props {
  shiftReportId: string;
  report: ShiftReportChartViewFragment;
  refreshReportData: () => void;
}

const AutofillButton = ({
  shiftReportId,
  report,
  refreshReportData,
}: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);

  const [autofill, { loading: autofillLoading }] =
    useShiftReportAutofillMutation();
  const [deleteAutofillTasks, { loading: deleteLoading }] =
    useDeleteNotValidatedTasksMutation({
      variables: { shiftReportId },
    });

  const handleClose = () => setVisible(false);

  const checkCriticalOverlap = (
    startDate: Dayjs,
    endDate: Dayjs,
    tasks: TaskFullFragment[],
  ) => {
    return tasks.some(
      (existingTask) =>
        (startDate.isSameOrAfter(existingTask.startDate) &&
          startDate.isBefore(existingTask.endDate)) ||
        (endDate.isAfter(existingTask.startDate) &&
          endDate.isSameOrBefore(existingTask.endDate)) ||
        (startDate.isSameOrBefore(existingTask.startDate) &&
          endDate.isSameOrAfter(existingTask.endDate)),
    );
  };

  const handleAutofillTasks = async () => {
    if (!report) {
      return;
    }
    if (!startTime || !endTime) {
      message.warning(t('pages.report.chart.autofill.errors.notInRange'));
      return;
    }
    const [startDate, endDate] = setTimeRangeDate(
      parseReportDateString(report.date),
      report.shift,
    )(startTime.clone(), endTime.clone());
    if (
      !startDate ||
      !endDate ||
      !isTaskInShiftRange(parseReportDateString(report.date), report.shift)(
        startDate,
        endDate,
      )
    ) {
      message.warning(t('pages.report.chart.autofill.errors.notInRange'));
      return;
    }

    if (
      checkCriticalOverlap(
        startDate,
        endDate,
        report.tasks.filter(
          ({ activity: { type } }) => type === 'excavation' || type === 'build',
        ),
      )
    ) {
      message.warning(t('pages.report.chart.autofill.errors.overlap'));
      return;
    }

    handleClose();
    try {
      const { data: autofillData } = await autofill({
        variables: {
          shiftReportId,
          startDate,
          endDate,
        },
      });

      if (!autofillData) {
        throw new Error('autofill-mutation-no-response');
      }

      const {
        shiftReportAutofill: {
          success,
          count,
          message: autofillMutationMessage,
        },
      } = autofillData;

      if (!success) {
        throw new Error(
          autofillMutationMessage || 'autofill-mutation-no-message',
        );
      }

      message.info(
        t('pages.report.chart.autofill.result', {
          count,
          context: count === 0 ? 'noTask' : undefined,
        }),
      );
      refreshReportData();
    } catch (e) {
      message.error(t('pages.report.chart.autofill.errors.mutation'));
      if (e instanceof Error) {
        errorReporting.warning(e);
      }
    }
  };

  const handleDeleteAllAutofillTasks = () =>
    deleteAutofillTasks()
      .then(() => refreshReportData())
      .then(() => handleClose())
      .catch((error) => {
        errorReporting.warning(error);
        message.error(t('errors.deletion_failed'));
      });

  return (
    <>
      <Button
        className="task-cta"
        onClick={() => setVisible(true)}
        loading={autofillLoading}
      >
        {t('pages.report.chart.autofill.cta')}
      </Button>
      <Modal
        title={t('pages.report.chart.autofill.modalTitle')}
        open={visible}
        onCancel={handleClose}
        footer={null}
        className="autofill-modal"
      >
        <Row>
          <Col span={11}>
            <TimePicker
              value={startTime}
              onChange={(value) => setStartTime(value)}
              needConfirm={false}
              hideDisabledOptions
              format={TIME_HOUR_MINUTE_FORMAT}
              disabledTime={() =>
                getDisabledTimeForShift(
                  report.shift,
                  parseReportDateString(report.date),
                )
              }
              style={{ width: '100%' }}
              placeholder={t('pages.form.startTime.placeholder')}
            />
          </Col>
          <Col span={11} offset={2}>
            <TimePicker
              value={endTime}
              onChange={(value) => setEndTime(value)}
              needConfirm={false}
              hideDisabledOptions
              format={TIME_HOUR_MINUTE_FORMAT}
              disabledTime={() =>
                getDisabledTimeForShift(
                  report.shift,
                  parseReportDateString(report.date),
                )
              }
              style={{ width: '100%' }}
              placeholder={t('pages.form.endTime.placeholder')}
            />
          </Col>
        </Row>
        <Button
          type="primary"
          style={{ width: '100%' }}
          onClick={handleAutofillTasks}
          icon={<CloudSyncOutlined />}
        >
          {t('pages.report.chart.autofill.cta')}
        </Button>
        <Popconfirm
          title={t('pages.report.chart.autofill.deletePopconfirm')}
          okButtonProps={{ danger: true }}
          onConfirm={handleDeleteAllAutofillTasks}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
          <Button
            type="default"
            style={{
              width: '100%',
              backgroundColor: '#eca52b',
              color: '#fff',
            }}
            loading={deleteLoading}
            icon={<DeleteOutlined />}
          >
            {t('pages.report.chart.autofill.deleteCta')}
          </Button>
        </Popconfirm>
      </Modal>
    </>
  );
};

export default AutofillButton;
