import { UploadFile } from 'antd/lib/upload/interface';

import { UploadMetadata } from './types';

export const FILES_TOTAL_MB_MAX = 10;
export const MB_SIZE = 1024 * 1024;
export const TASK_FILES_UPLOAD_LIMIT = 5;
export const REPORT_FILES_UPLOAD_LIMIT = 10;

export const headers = {
  'Content-Type': 'application/octet-stream',
};

export const extractNameFromUrl = (url: string) => {
  return decodeURI(
    new URL(url).pathname
      .slice(1) // remove forward '/'
      .split('/')
      .slice(1) // remove the first substring (the bucket name)
      .join('/'),
  );
};

export const getBucketFileName = (
  response?: any, // response type in Antd UploadFile type is any
) => {
  if (typeof response === 'string') {
    return response;
  }
  if (response) {
    return extractNameFromUrl(response.url);
  }
  return '';
};

export const formatFileListToFileUploadValue = (
  files: UploadMetadata[],
): UploadFile[] =>
  files.map(({ name, size, type, bucketFileName }, id) => ({
    // Unique id to not interfer with Antd generated ids,
    uid: `${0 - id}`,
    name,
    type,
    size,
    status: 'done',
    // Used to re-import data into the database
    response: bucketFileName,
  }));

export const formatFileUploadValueToDatabaseMetadata = (
  files: UploadFile<Response>[] | undefined,
): UploadMetadata[] =>
  files?.map(({ name, size, type, xhr, response }) => ({
    name,
    size,
    type,
    bucketFileName: getBucketFileName(xhr || response),
  })) ?? [];
