import {
  ActionType,
  ExcavationBuildTimeDispatchActionTypes,
  ExcavationBuildTimeFilter,
} from './types';

export const filtersReducer = (
  filters: ExcavationBuildTimeFilter,
  action: ActionType,
): ExcavationBuildTimeFilter => {
  switch (action.type) {
    case ExcavationBuildTimeDispatchActionTypes.ToggleTotalDurations: {
      return { ...filters, showTotalDurations: !filters.showTotalDurations };
    }
    case ExcavationBuildTimeDispatchActionTypes.UpdateDateFilter: {
      return { ...filters, dateRange: action.dateRange };
    }
    case ExcavationBuildTimeDispatchActionTypes.ChangeDayMode: {
      return { ...filters, dayMode: action.dayMode };
    }
  }
};
