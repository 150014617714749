import {
  Alert,
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useState } from 'react';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { checkForExistingReport } from 'business/report/services/reportOperations';
import { parseReportDateString } from 'business/report/services/timeOperations';
import { NewShiftReportFormValues } from 'business/report/types';
import { parseTaskDateString } from 'business/task/services/timeOperations';
import {
  ShiftReportChartViewFragment,
  useCheckNewReportLazyQuery,
  useEditEndFieldsReportMutation,
  useEditEndFieldsReportUserMutation,
  useEditTasksDateMutation,
  useGetNewReportFieldsQuery,
} from 'generated/graphql';
import logger from 'technical/logger';
import { formatUserName, SIMPLE_DATE_FORMAT } from 'technical/string/formatter';
import {
  negativeProgressionCheck,
  positiveNumberRule,
  requiredRule,
} from 'technical/validation/rules';
import './index.scss';

const { Option } = Select;

const inputStyle = { width: '200px' };

interface Props {
  shiftReport: ShiftReportChartViewFragment;
  visible: boolean;
  closeEndFieldsDrawer: () => void;
  constructionSiteId: string;
}
interface FormValues {
  startMetricPoint?: number;
  endMetricPoint?: number;
  date?: string;
  operatorId?: string;
  shiftLeaderId?: string;
  shiftEngineerId?: string;
  shiftManagerId?: string;
}

function ReportMetadataDrawer({
  shiftReport,
  visible,
  closeEndFieldsDrawer,
  constructionSiteId,
}: Props) {
  const { currentConstructionSite } = useAppContext();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [progressionCheckValid, setProgressionCheckValid] =
    useState<boolean>(false);

  const { loading, error, data } = useGetNewReportFieldsQuery({
    variables: {
      constructionSiteId: currentConstructionSite?.id,
    },
    skip: !currentConstructionSite?.id,
  });
  const [checkReport, { data: dataCheck }] = useCheckNewReportLazyQuery();
  const [managerReportUpdate] = useEditEndFieldsReportMutation();
  const [userReportUpdate] = useEditEndFieldsReportUserMutation();
  const [managerTaskUpdate] = useEditTasksDateMutation();

  if (loading) {
    return null;
  }
  if (
    error ||
    !currentConstructionSite ||
    !data?.operators ||
    !data.shiftLeaders ||
    !data.shifts
  ) {
    return <Alert message={t('errors.error_generic')} type="error" />;
  }

  function checkProgression(value: Store) {
    setProgressionCheckValid(value.startMetricPoint === value.endMetricPoint);
  }

  const checkReportValidity = (
    _changedValues: NewShiftReportFormValues,
    values: NewShiftReportFormValues,
  ) => {
    if (shiftReport.date !== values.date?.format(SIMPLE_DATE_FORMAT)) {
      checkForExistingReport(
        t,
        checkReport,
        constructionSiteId,
        values.date,
        shiftReport.shift.id,
      );
    }
  };

  const onFinish = async (values: FormValues) => {
    if (!values.date) {
      // should never happend due to form validation
      // but enforce here for typescript typing
      return;
    }
    // if a report doesn't already exist at this date
    if (dataCheck && dataCheck?.shiftReport?.length > 0) {
      Modal.warning({
        title: t('pages.report.new.existingReport.title'),
        content: t('pages.report.new.existingReport.content.cancel'),
      });
    } else {
      const dayDiff = parseReportDateString(values.date).diff(
        parseReportDateString(shiftReport.date),
        'days',
      );

      try {
        // If the date was changed update all the dependant tasks
        if (dayDiff !== 0) {
          const actions = shiftReport.tasks.map(async (task) => {
            managerTaskUpdate({
              variables: {
                id: task.id,
                startDate: parseTaskDateString(task.startDate).add(
                  dayDiff,
                  'day',
                ),
                endDate: parseTaskDateString(task.endDate).add(dayDiff, 'day'),
              },
            });
          });

          await Promise.all(actions);
        }
        // When update the report
        if (shiftReport.operatorValidation) {
          await managerReportUpdate({
            variables: {
              id: shiftReport.id,
              startMetricPoint: values.startMetricPoint,
              endMetricPoint: values.endMetricPoint,
              date: parseReportDateString(values.date).format(
                SIMPLE_DATE_FORMAT,
              ),
              operatorId: values.operatorId,
              shiftLeaderId: values.shiftLeaderId,
              shiftEngineerId: values.shiftEngineerId,
              shiftManagerId: values.shiftManagerId,
            },
          });
        } else {
          await userReportUpdate({
            variables: {
              id: shiftReport.id,
              startMetricPoint: values.startMetricPoint,
              date: parseReportDateString(values.date).format(
                SIMPLE_DATE_FORMAT,
              ),
              operatorId: values.operatorId,
              shiftLeaderId: values.shiftLeaderId,
              shiftEngineerId: values.shiftEngineerId,
              shiftManagerId: values.shiftManagerId,
            },
          });
        }
        message.success(t('pages.report.chart.updateShiftReportSuccess'));
        closeEndFieldsDrawer();
      } catch (err) {
        message.error(t('pages.report.chart.updateShiftReportFailed'));
        logger.error(err);
      }
    }
  };

  const closeDrawer = () => {
    closeEndFieldsDrawer();
  };

  const { operators, shiftLeaders, shiftEngineers, shiftManagers } = data;
  const {
    isManagerRequiredInReport: isManagerRequired,
    isEngineerRequiredInReport: isEngineerRequired,
  } = currentConstructionSite;

  return (
    <Drawer
      forceRender
      title={t('pages.report.chart.reportMetadataFields')}
      placement="top"
      open={visible}
      getContainer={false}
      bodyStyle={{ textAlign: 'center' }}
      headerStyle={{ textAlign: 'center' }}
      height="auto"
      onClose={closeEndFieldsDrawer}
      footer={
        <Form
          form={form}
          layout="inline"
          name="reportMetadataFooter"
          onFinish={onFinish}
        >
          <Space style={{ margin: '0 auto' }}>
            <Button onClick={() => closeDrawer()}>{t('common.cancel')}</Button>
            <Button type="primary" htmlType="submit">
              {t('common.confirm')}
            </Button>
          </Space>
        </Form>
      }
    >
      <Form
        form={form}
        initialValues={{
          startMetricPoint: shiftReport.startMetricPoint || 0,
          endMetricPoint: shiftReport.endMetricPoint ?? 0,
          date: parseReportDateString(shiftReport.date) || null,
          operatorId: shiftReport.operator?.id || null,
          shiftLeaderId: shiftReport.shiftLeader?.id || null,
          shiftEngineerId: shiftReport.shiftEngineer?.id || null,
          shiftManagerId: shiftReport.shiftManager?.id || null,
        }}
        onValuesChange={(changedValues, values) => {
          checkReportValidity(changedValues, values);
          checkProgression(form.getFieldsValue());
        }}
        layout="vertical"
        className="report-metadata-drawer"
      >
        <Space direction="vertical">
          <Space size="large">
            <Form.Item
              name="startMetricPoint"
              label={t('pages.form.startMetricPoint.label')}
              rules={[requiredRule, positiveNumberRule]}
            >
              <InputNumber step={0.001} size="middle" style={inputStyle} />
            </Form.Item>
            {shiftReport.operatorValidation && (
              <>
                <Form.Item
                  name="endMetricPoint"
                  label={t('pages.form.endMetricPoint.label')}
                  rules={[
                    requiredRule,
                    negativeProgressionCheck(shiftReport.startMetricPoint),
                  ]}
                >
                  <InputNumber step={0.001} size="middle" style={inputStyle} />
                </Form.Item>
                {progressionCheckValid && (
                  <p className="warning">{t('errors.no_progression')}</p>
                )}
              </>
            )}
            <Form.Item
              label={t('pages.form.date.label')}
              name="date"
              rules={[{ required: true, message: t('pages.form.date.alert') }]}
            >
              <DatePicker
                allowClear
                size="middle"
                style={inputStyle}
                format="L"
              />
            </Form.Item>
          </Space>
          <Space>
            <Form.Item
              label={t('pages.form.operator.label')}
              name="operatorId"
              rules={[
                { required: true, message: t('pages.form.operator.alert') },
              ]}
            >
              <Select
                showSearch
                placeholder={t('pages.form.operator.placeholder')}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={inputStyle}
              >
                {operators.map((operator) => (
                  <Option value={operator.id} key={operator.id}>
                    {operator.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('pages.form.shiftLeader.label')}
              name="shiftLeaderId"
              rules={[
                {
                  required: true,
                  message: t('pages.form.shiftLeader.alert'),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={t('pages.form.shiftLeader.placeholder')}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={inputStyle}
              >
                {shiftLeaders.map((shiftLeader) => (
                  <Option value={shiftLeader.id} key={shiftLeader.id}>
                    {shiftLeader.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {isEngineerRequired && (
              <Form.Item
                label={t('pages.form.shiftEngineer.label')}
                name="shiftEngineerId"
                rules={[
                  {
                    required: true,
                    message: t('pages.form.shiftLeader.alert'),
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={t('pages.form.shiftEngineer.placeholder')}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  style={inputStyle}
                >
                  {shiftEngineers.map(({ id, ...rest }) => (
                    <Option value={id} key={id}>
                      {formatUserName(rest)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {isManagerRequired && (
              <Form.Item
                label={t('pages.form.shiftManager.label')}
                name="shiftManagerId"
                rules={[
                  {
                    required: true,
                    message: t('pages.form.shiftManager.alert'),
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={t('pages.form.shiftManager.placeholder')}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  style={inputStyle}
                >
                  {shiftManagers.map(({ id, ...rest }) => (
                    <Option value={id} key={id}>
                      {formatUserName(rest)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Space>
        </Space>
      </Form>
    </Drawer>
  );
}

export default ReportMetadataDrawer;
