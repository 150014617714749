import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'translations/hooks';

import {
  PlotterMode,
  mapPlotterModeToGraphSetType,
} from 'business/data-analysis/constants';
import { DisplayType } from 'business/data-analysis/pages/graph/types';
import {
  GraphSetStateDto,
  GraphStateDto,
  ParameterDto,
} from 'generated/apiSchemas';
import { GraphSetTypeEnum_Enum } from 'generated/graphql';

const isEmptyState = (
  currentGraphSet: GraphSetStateDto,
  emptySetState: GraphStateDto,
) => {
  const graphs = currentGraphSet.graphs;

  return (
    graphs.length === 1 &&
    graphs[0].index === emptySetState.index &&
    graphs[0].title === emptySetState.title &&
    graphs[0].parameters.length === 0
  );
};

const getEmptyStates = (
  defaultGraphTitle: string,
  graphSetType: GraphSetTypeEnum_Enum,
) => {
  const emptyGraphState = {
    index: 0,
    title: `${defaultGraphTitle} 1`,
    displayType: DisplayType.graph,
    parameters: [],
  } satisfies GraphStateDto;

  const emptyGraphSetState = {
    title: '',
    graphs: [emptyGraphState],
    type: graphSetType,
  } satisfies GraphSetStateDto;

  return { emptyGraphSetState, emptyGraphState };
};

export const useGraphSetState = (mode: PlotterMode) => {
  const { t } = useTranslation();

  const graphSetType = mapPlotterModeToGraphSetType[mode];
  const defaultGraphTitle = t('dataAnalysis.labels.newGraph');
  const { emptyGraphSetState, emptyGraphState } = useMemo(
    () => getEmptyStates(defaultGraphTitle, graphSetType),
    // Not including the defaultGraphTitle to not reset the graphSet if the
    // user change the language
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [graphSetType],
  );

  const [graphSet, setGraphSet] =
    useState<GraphSetStateDto>(emptyGraphSetState);
  const [savedGraphSet, setSavedGraphSet] = useState<GraphSetStateDto>();
  const [disabledDeletion, setDisabledDeletion] = useState(false);
  const [currentGraphIndex, setCurrentGraphIndex] = useState(0);
  const [currentGraphSetId, setCurrentGraphSetId] = useState<string | null>(
    null,
  );

  // Reset the state & saved state after change of graph set type
  useEffect(() => {
    setGraphSet(emptyGraphSetState);
    setSavedGraphSet(undefined);
    setCurrentGraphIndex(0);
    setCurrentGraphSetId(null);
  }, [graphSetType, emptyGraphSetState]);

  if (!disabledDeletion && isEmptyState(graphSet, emptyGraphState)) {
    setDisabledDeletion(true);
  }
  if (disabledDeletion && !isEmptyState(graphSet, emptyGraphState)) {
    setDisabledDeletion(false);
  }

  const addNewGraph = () => {
    if (graphSet.graphs.length === 0) {
      return;
    }
    // The user friendly value of the last present graph index (user friendly => graph.index + 1)
    const index = graphSet.graphs.at(-1)!.index + 1;
    const title = `${defaultGraphTitle} ${index + 1}`;
    const displayType = DisplayType.graph;
    const parameters: ParameterDto[] = [];

    setGraphSet({
      ...graphSet,
      graphs: [...graphSet.graphs, { index, title, displayType, parameters }],
    });
  };

  const updateFullGraphSet = (newGraphSet: GraphSetStateDto) => {
    setGraphSet(newGraphSet);
  };

  const updateGraph = (state: GraphStateDto) => {
    setGraphSet({
      ...graphSet,
      graphs: graphSet.graphs.map((graph) => {
        if (graph.index === state.index) {
          return state;
        }
        return graph;
      }),
    });
  };

  const updateAllGraphs = (graphs: GraphStateDto[]) => {
    setGraphSet({
      ...graphSet,
      graphs,
    });
  };

  const deleteGraph = (index: number) => {
    if (graphSet.graphs.length === 1) {
      setGraphSet({ ...graphSet, graphs: [emptyGraphState] });
      return;
    }

    setGraphSet({
      ...graphSet,
      graphs: graphSet.graphs.filter((graph) => graph.index !== index),
    });
  };

  const deleteGraphSet = () => {
    setGraphSet(emptyGraphSetState);
  };

  const extractGraphSetValues = (
    graphSetToSave: GraphSetStateDto,
  ): GraphSetStateDto => {
    return {
      ...graphSetToSave,
      type: graphSetToSave.type,
      graphs: graphSetToSave.graphs.map((graph) => ({
        ...graph,
        parameters: graph.parameters.map((parameter) => ({
          ...parameter,
          // We don't want to save this field in db, only necessary for the front state
          values: [],
        })),
      })),
    };
  };

  return {
    graphSet,
    setGraphSet,
    savedGraphSet,
    setSavedGraphSet,
    disabledDeletion,
    addNewGraph,
    updateFullGraphSet,
    updateGraph,
    updateAllGraphs,
    deleteGraph,
    deleteGraphSet,
    extractGraphSetValues,
    currentGraphIndex,
    setCurrentGraphIndex,
    currentGraphSetId,
    setCurrentGraphSetId,
  };
};
