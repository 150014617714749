/**
 * Generated by @openapi-codegen
 *
 * @version 1.0
 */
import * as reactQuery from '@tanstack/react-query';
import { useApiContext, ApiContext } from './apiContext';
import type * as Fetcher from './apiFetcher';
import { apiFetch } from './apiFetcher';
import type * as Schemas from './apiSchemas';

export type DataAnalysisControllerGraphByPeriodQueryParams = {
  id: string;
  startDate: string;
  endDate: string;
  parameterIds: string[];
};

export type DataAnalysisControllerGraphByPeriodError =
  Fetcher.ErrorWrapper<undefined>;

export type DataAnalysisControllerGraphByPeriodVariables = {
  queryParams: DataAnalysisControllerGraphByPeriodQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDataAnalysisControllerGraphByPeriod = (
  variables: DataAnalysisControllerGraphByPeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GraphByPeriodResponseDto,
    DataAnalysisControllerGraphByPeriodError,
    undefined,
    {},
    DataAnalysisControllerGraphByPeriodQueryParams,
    {}
  >({
    url: '/data-analysis/graph-by-period',
    method: 'get',
    ...variables,
    signal,
  });

export const useDataAnalysisControllerGraphByPeriod = <
  TData = Schemas.GraphByPeriodResponseDto,
>(
  variables: DataAnalysisControllerGraphByPeriodVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.GraphByPeriodResponseDto,
      DataAnalysisControllerGraphByPeriodError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.GraphByPeriodResponseDto,
    DataAnalysisControllerGraphByPeriodError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/data-analysis/graph-by-period',
      operationId: 'dataAnalysisControllerGraphByPeriod',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDataAnalysisControllerGraphByPeriod(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type DataAnalysisControllerGraphByRingError =
  Fetcher.ErrorWrapper<undefined>;

export type DataAnalysisControllerGraphByRingVariables = {
  body: Schemas.GetGraphByRingDto;
} & ApiContext['fetcherOptions'];

export const fetchDataAnalysisControllerGraphByRing = (
  variables: DataAnalysisControllerGraphByRingVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GraphByRingResponseDto,
    DataAnalysisControllerGraphByRingError,
    Schemas.GetGraphByRingDto,
    {},
    {},
    {}
  >({
    url: '/data-analysis/graph-by-ring',
    method: 'post',
    ...variables,
    signal,
  });

export const useDataAnalysisControllerGraphByRing = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GraphByRingResponseDto,
      DataAnalysisControllerGraphByRingError,
      DataAnalysisControllerGraphByRingVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GraphByRingResponseDto,
    DataAnalysisControllerGraphByRingError,
    DataAnalysisControllerGraphByRingVariables
  >({
    mutationFn: (variables: DataAnalysisControllerGraphByRingVariables) =>
      fetchDataAnalysisControllerGraphByRing({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type DataAnalysisControllerSaveGraphSetError =
  Fetcher.ErrorWrapper<undefined>;

export type DataAnalysisControllerSaveGraphSetVariables = {
  body: Schemas.SaveGraphSetDto;
} & ApiContext['fetcherOptions'];

export const fetchDataAnalysisControllerSaveGraphSet = (
  variables: DataAnalysisControllerSaveGraphSetVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.SaveGraphSetResponse,
    DataAnalysisControllerSaveGraphSetError,
    Schemas.SaveGraphSetDto,
    {},
    {},
    {}
  >({ url: '/data-analysis/graph-set', method: 'post', ...variables, signal });

export const useDataAnalysisControllerSaveGraphSet = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SaveGraphSetResponse,
      DataAnalysisControllerSaveGraphSetError,
      DataAnalysisControllerSaveGraphSetVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.SaveGraphSetResponse,
    DataAnalysisControllerSaveGraphSetError,
    DataAnalysisControllerSaveGraphSetVariables
  >({
    mutationFn: (variables: DataAnalysisControllerSaveGraphSetVariables) =>
      fetchDataAnalysisControllerSaveGraphSet({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type DataAnalysisControllerDuplicateGraphSetQueryParams = {
  id: string;
};

export type DataAnalysisControllerDuplicateGraphSetError =
  Fetcher.ErrorWrapper<{
    status: 409;
    payload: {
      /**
       * @example 409
       */
      statusCode: number;
      /**
       * @example Conflict
       */
      message: string;
      /**
       * @example Conflict
       */
      error?: string;
    };
  }>;

export type DataAnalysisControllerDuplicateGraphSetVariables = {
  queryParams: DataAnalysisControllerDuplicateGraphSetQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDataAnalysisControllerDuplicateGraphSet = (
  variables: DataAnalysisControllerDuplicateGraphSetVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.DuplicationResponse,
    DataAnalysisControllerDuplicateGraphSetError,
    undefined,
    {},
    DataAnalysisControllerDuplicateGraphSetQueryParams,
    {}
  >({
    url: '/data-analysis/duplicate-graph-set',
    method: 'post',
    ...variables,
    signal,
  });

export const useDataAnalysisControllerDuplicateGraphSet = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DuplicationResponse,
      DataAnalysisControllerDuplicateGraphSetError,
      DataAnalysisControllerDuplicateGraphSetVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.DuplicationResponse,
    DataAnalysisControllerDuplicateGraphSetError,
    DataAnalysisControllerDuplicateGraphSetVariables
  >({
    mutationFn: (variables: DataAnalysisControllerDuplicateGraphSetVariables) =>
      fetchDataAnalysisControllerDuplicateGraphSet({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type DataAnalysisControllerGetCurrentRingNumberQueryParams = {
  constructionSiteId: string;
};

export type DataAnalysisControllerGetCurrentRingNumberError =
  Fetcher.ErrorWrapper<undefined>;

export type DataAnalysisControllerGetCurrentRingNumberVariables = {
  queryParams: DataAnalysisControllerGetCurrentRingNumberQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDataAnalysisControllerGetCurrentRingNumber = (
  variables: DataAnalysisControllerGetCurrentRingNumberVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GetCurrentRingNumberResponse,
    DataAnalysisControllerGetCurrentRingNumberError,
    undefined,
    {},
    DataAnalysisControllerGetCurrentRingNumberQueryParams,
    {}
  >({
    url: '/data-analysis/current-ring',
    method: 'get',
    ...variables,
    signal,
  });

export const useDataAnalysisControllerGetCurrentRingNumber = <
  TData = Schemas.GetCurrentRingNumberResponse,
>(
  variables: DataAnalysisControllerGetCurrentRingNumberVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.GetCurrentRingNumberResponse,
      DataAnalysisControllerGetCurrentRingNumberError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.GetCurrentRingNumberResponse,
    DataAnalysisControllerGetCurrentRingNumberError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/data-analysis/current-ring',
      operationId: 'dataAnalysisControllerGetCurrentRingNumber',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDataAnalysisControllerGetCurrentRingNumber(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GraphSetExportControllerGenerateGraphSetPeriodExportError =
  Fetcher.ErrorWrapper<undefined>;

export type GraphSetExportControllerGenerateGraphSetPeriodExportVariables = {
  body: Schemas.GenerateGraphSetPeriodExportDto;
} & ApiContext['fetcherOptions'];

export const fetchGraphSetExportControllerGenerateGraphSetPeriodExport = (
  variables: GraphSetExportControllerGenerateGraphSetPeriodExportVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GenerateGraphSetExportResponseDto,
    GraphSetExportControllerGenerateGraphSetPeriodExportError,
    Schemas.GenerateGraphSetPeriodExportDto,
    {},
    {},
    {}
  >({
    url: '/data-analysis/generate-graph-set-period-export',
    method: 'post',
    ...variables,
    signal,
  });

export const useGraphSetExportControllerGenerateGraphSetPeriodExport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GenerateGraphSetExportResponseDto,
      GraphSetExportControllerGenerateGraphSetPeriodExportError,
      GraphSetExportControllerGenerateGraphSetPeriodExportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GenerateGraphSetExportResponseDto,
    GraphSetExportControllerGenerateGraphSetPeriodExportError,
    GraphSetExportControllerGenerateGraphSetPeriodExportVariables
  >({
    mutationFn: (
      variables: GraphSetExportControllerGenerateGraphSetPeriodExportVariables,
    ) =>
      fetchGraphSetExportControllerGenerateGraphSetPeriodExport({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GraphSetExportControllerGenerateGraphSetRingExportError =
  Fetcher.ErrorWrapper<undefined>;

export type GraphSetExportControllerGenerateGraphSetRingExportVariables = {
  body: Schemas.GenerateGraphSetRingExportDto;
} & ApiContext['fetcherOptions'];

export const fetchGraphSetExportControllerGenerateGraphSetRingExport = (
  variables: GraphSetExportControllerGenerateGraphSetRingExportVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GenerateGraphSetExportResponseDto,
    GraphSetExportControllerGenerateGraphSetRingExportError,
    Schemas.GenerateGraphSetRingExportDto,
    {},
    {},
    {}
  >({
    url: '/data-analysis/generate-graph-set-ring-export',
    method: 'post',
    ...variables,
    signal,
  });

export const useGraphSetExportControllerGenerateGraphSetRingExport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GenerateGraphSetExportResponseDto,
      GraphSetExportControllerGenerateGraphSetRingExportError,
      GraphSetExportControllerGenerateGraphSetRingExportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GenerateGraphSetExportResponseDto,
    GraphSetExportControllerGenerateGraphSetRingExportError,
    GraphSetExportControllerGenerateGraphSetRingExportVariables
  >({
    mutationFn: (
      variables: GraphSetExportControllerGenerateGraphSetRingExportVariables,
    ) =>
      fetchGraphSetExportControllerGenerateGraphSetRingExport({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationQueryParams =
  {
    constructionSiteId: string;
    startDate: string;
    endDate: string;
    dayMode: 'shift' | 'calendar';
  };

export type ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationError =
  Fetcher.ErrorWrapper<undefined>;

export type ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationVariables =
  {
    queryParams: ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationQueryParams;
  } & ApiContext['fetcherOptions'];

export const fetchExcavationAndBuildingDurationControllerGetExcavationAndBuildingDuration =
  (
    variables: ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationVariables,
    signal?: AbortSignal,
  ) =>
    apiFetch<
      Schemas.GetExcavationAndBuildingDurationResponseDto,
      ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationError,
      undefined,
      {},
      ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationQueryParams,
      {}
    >({
      url: '/production-and-performance/excavation-and-building-duration',
      method: 'get',
      ...variables,
      signal,
    });

export const useExcavationAndBuildingDurationControllerGetExcavationAndBuildingDuration =
  <TData = Schemas.GetExcavationAndBuildingDurationResponseDto,>(
    variables: ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationVariables,
    options?: Omit<
      reactQuery.UseQueryOptions<
        Schemas.GetExcavationAndBuildingDurationResponseDto,
        ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationError,
        TData
      >,
      'queryKey' | 'queryFn' | 'initialData'
    >,
  ) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<
      Schemas.GetExcavationAndBuildingDurationResponseDto,
      ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationError,
      TData
    >({
      queryKey: queryKeyFn({
        path: '/production-and-performance/excavation-and-building-duration',
        operationId:
          'excavationAndBuildingDurationControllerGetExcavationAndBuildingDuration',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchExcavationAndBuildingDurationControllerGetExcavationAndBuildingDuration(
          { ...fetcherOptions, ...variables },
          signal,
        ),
      ...options,
      ...queryOptions,
    });
  };

export type ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportError =
  Fetcher.ErrorWrapper<undefined>;

export type ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportVariables =
  {
    body: Schemas.ExcavationAndBuildingDurationExportInputDto;
  } & ApiContext['fetcherOptions'];

export const fetchExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExport =
  (
    variables: ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportVariables,
    signal?: AbortSignal,
  ) =>
    apiFetch<
      Schemas.ExcavationAndBuildingDurationExportResponseDto,
      ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportError,
      Schemas.ExcavationAndBuildingDurationExportInputDto,
      {},
      {},
      {}
    >({
      url: '/production-and-performance/excavation-and-building-duration/export',
      method: 'post',
      ...variables,
      signal,
    });

export const useExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExport =
  (
    options?: Omit<
      reactQuery.UseMutationOptions<
        Schemas.ExcavationAndBuildingDurationExportResponseDto,
        ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportError,
        ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportVariables
      >,
      'mutationFn'
    >,
  ) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<
      Schemas.ExcavationAndBuildingDurationExportResponseDto,
      ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportError,
      ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportVariables
    >({
      mutationFn: (
        variables: ExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExportVariables,
      ) =>
        fetchExcavationAndBuildingDurationControllerGenerateExcavationAndBuildingDurationExport(
          { ...fetcherOptions, ...variables },
        ),
      ...options,
    });
  };

export type RingPerDayControllerRingPerDayQueryParams = {
  constructionSiteId: string;
  startDate: string;
  endDate: string;
  dayMode: 'shift' | 'calendar';
};

export type RingPerDayControllerRingPerDayError =
  Fetcher.ErrorWrapper<undefined>;

export type RingPerDayControllerRingPerDayVariables = {
  queryParams: RingPerDayControllerRingPerDayQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchRingPerDayControllerRingPerDay = (
  variables: RingPerDayControllerRingPerDayVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GetRingPerDayResponseDto,
    RingPerDayControllerRingPerDayError,
    undefined,
    {},
    RingPerDayControllerRingPerDayQueryParams,
    {}
  >({
    url: '/production-and-performance/ring-per-day',
    method: 'get',
    ...variables,
    signal,
  });

export const useRingPerDayControllerRingPerDay = <
  TData = Schemas.GetRingPerDayResponseDto,
>(
  variables: RingPerDayControllerRingPerDayVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.GetRingPerDayResponseDto,
      RingPerDayControllerRingPerDayError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.GetRingPerDayResponseDto,
    RingPerDayControllerRingPerDayError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/production-and-performance/ring-per-day',
      operationId: 'ringPerDayControllerRingPerDay',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchRingPerDayControllerRingPerDay(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type RingPerDayControllerGenerateRingPerDayExportError =
  Fetcher.ErrorWrapper<undefined>;

export type RingPerDayControllerGenerateRingPerDayExportVariables = {
  body: Schemas.RingPerDayExportInputDto;
} & ApiContext['fetcherOptions'];

export const fetchRingPerDayControllerGenerateRingPerDayExport = (
  variables: RingPerDayControllerGenerateRingPerDayExportVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GenerateExportResponseDto,
    RingPerDayControllerGenerateRingPerDayExportError,
    Schemas.RingPerDayExportInputDto,
    {},
    {},
    {}
  >({
    url: '/production-and-performance/ring-per-day/export',
    method: 'post',
    ...variables,
    signal,
  });

export const useRingPerDayControllerGenerateRingPerDayExport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GenerateExportResponseDto,
      RingPerDayControllerGenerateRingPerDayExportError,
      RingPerDayControllerGenerateRingPerDayExportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GenerateExportResponseDto,
    RingPerDayControllerGenerateRingPerDayExportError,
    RingPerDayControllerGenerateRingPerDayExportVariables
  >({
    mutationFn: (
      variables: RingPerDayControllerGenerateRingPerDayExportVariables,
    ) =>
      fetchRingPerDayControllerGenerateRingPerDayExport({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: '/data-analysis/graph-by-period';
      operationId: 'dataAnalysisControllerGraphByPeriod';
      variables: DataAnalysisControllerGraphByPeriodVariables;
    }
  | {
      path: '/data-analysis/current-ring';
      operationId: 'dataAnalysisControllerGetCurrentRingNumber';
      variables: DataAnalysisControllerGetCurrentRingNumberVariables;
    }
  | {
      path: '/production-and-performance/excavation-and-building-duration';
      operationId: 'excavationAndBuildingDurationControllerGetExcavationAndBuildingDuration';
      variables: ExcavationAndBuildingDurationControllerGetExcavationAndBuildingDurationVariables;
    }
  | {
      path: '/production-and-performance/ring-per-day';
      operationId: 'ringPerDayControllerRingPerDay';
      variables: RingPerDayControllerRingPerDayVariables;
    };
