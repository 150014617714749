import { Chart } from 'react-google-charts';
import { TFunction } from 'translations/hooks';

import { formatAggregatedPieChartData } from 'business/task/pages/TasksAnalyzer/services/pieChartServices';

interface Props {
  aggregatedTasks: any[];
  t: TFunction;
}

function AggregatedProductionGraph({ aggregatedTasks, t }: Props) {
  const graphData = formatAggregatedPieChartData(t, aggregatedTasks);
  const options = {
    height: 500,
  };

  return (
    <div>
      {graphData && (
        <div id="pdfReportPrint">
          <Chart
            chartType="PieChart"
            data={graphData}
            options={{ ...options, maintainAspectRatio: false }}
            rootProps={{ 'data-testid': 'react-google-chart-1' }}
            chartPackages={['corechart', 'controls', 'charteditor']}
          />
        </div>
      )}
    </div>
  );
}

export default AggregatedProductionGraph;
