import {
  abscissaScale,
  ordinalScale,
} from 'business/report/components/ReportChart/services/axis';
import { parseTaskDateString } from 'business/task/services/timeOperations';

import { getPaddingLeft, margin, padding } from './chart';

// SVG Meta
export const getSvgWidth = (width: number) =>
  width + getPaddingLeft() + padding.right;

export const getSvgHeight = (height: number) =>
  height + margin.top + margin.bottom;

export const getXPos = (xScale: abscissaScale, startDate: string) =>
  xScale(parseTaskDateString(startDate).toDate()) ?? 0;

export const getYPos = (yScale: ordinalScale, activityName: string) => {
  const pos = yScale(activityName);
  return pos === undefined ? 0 : pos;
};
export const getWidth = (
  xScale: abscissaScale,
  startDate: string,
  endDate: string,
) => {
  const scaledEndDate = xScale(parseTaskDateString(endDate).toDate());
  const scaledStartDate = xScale(parseTaskDateString(startDate).toDate());

  if (scaledEndDate === undefined || scaledStartDate === undefined) {
    return 1;
  }

  return Math.max(1, scaledEndDate - scaledStartDate);
};
