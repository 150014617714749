import { Card } from 'antd';
import { useTranslation } from 'translations/hooks';

import { PlotterMode } from 'business/data-analysis/constants';
import { ParameterDto } from 'generated/apiSchemas';
import Flex from 'ui/flex';

import ParameterCard from './components/parameter-card';
import styles from './index.module.scss';

interface Props {
  // the same parameter can be in multiple lists, graphTitle is used for key of parameterCards
  graphTitle: string;
  parameters: ParameterDto[];
  updateParameter: (updatedParameter: ParameterDto) => void;
  removeParameter: (parameterId: number) => void;
  mode: PlotterMode;
}

const ParameterList = ({
  parameters,
  updateParameter,
  removeParameter,
  graphTitle,
  mode,
}: Props) => {
  const { t } = useTranslation();

  return parameters.length > 0 ? (
    <Flex column justify="flex-start" className={styles.parameterList}>
      {parameters.map((parameter) => {
        return (
          <ParameterCard
            key={`${graphTitle}_${parameter.id}_${parameter.index}`}
            parameter={parameter}
            updateParameter={updateParameter}
            removeParameter={removeParameter}
            mode={mode}
          />
        );
      })}
    </Flex>
  ) : (
    <Card className={styles.missingParameters}>
      {t('dataAnalysis.emptyGraph.helper.noParameter')}
    </Card>
  );
};

export default ParameterList;
