import { Radio, Form } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio';
import React from 'react';
import { useTranslation } from 'translations/hooks';

import { DateRangeType } from 'technical/types';
import { validateRingRangeRule } from 'technical/validation/rules';
import NumberRangeInput, { NumberRangeValue } from 'ui/form/numberRangeInput';
import TemporalitySelectInput, {
  TemporalitySelectInputProps,
} from 'ui/form/temporality-select-input';

type FilterType = 'date' | 'ring';

interface DateFilter {
  filterType: 'date';
  values: DateRangeType;
}

interface RingFilter {
  filterType: 'ring';
  values: NumberRangeValue;
}

export type RangeFilters = DateFilter | RingFilter;

export interface FilterByRangeInputsProps {
  filter: RangeFilters;
  onFilterChange: (filter: RangeFilters) => void;
  temporalitySelectInputProps?: Exclude<
    TemporalitySelectInputProps,
    'onChange'
  >;
}

export const FilterByRangesInputs: React.FC<FilterByRangeInputsProps> = ({
  filter,
  onFilterChange,
  temporalitySelectInputProps,
}) => {
  const { t } = useTranslation();
  const { filterType, values } = filter;
  const handleFilterTypeChange = (e: RadioChangeEvent) => {
    const newFilterType: FilterType = e.target.value;
    if (newFilterType === 'ring') {
      onFilterChange({ filterType: 'ring', values: [null, null] });
    } else {
      onFilterChange({ filterType: 'date', values: [null, null] });
    }
  };

  const handleDateChange = (dates: DateRangeType) => {
    onFilterChange({ filterType: 'date', values: dates });
  };

  const handleRingChange = (value: NumberRangeValue) => {
    onFilterChange({ filterType: 'ring', values: value });
  };

  return (
    <Form layout={'inline'}>
      <Form.Item>
        <Radio.Group
          value={filterType}
          onChange={handleFilterTypeChange}
          optionType="button"
          buttonStyle="solid"
          options={[
            {
              label: t('indicators.filterByOptions.date'),
              value: 'date',
            },
            {
              label: t('indicators.filterByOptions.ring'),
              value: 'ring',
            },
          ]}
        />
      </Form.Item>
      {filterType === 'date' ? (
        <Form.Item name="temporalRange">
          <TemporalitySelectInput
            onChange={handleDateChange}
            {...temporalitySelectInputProps}
          />
        </Form.Item>
      ) : (
        <Form.Item
          name="ringRange"
          validateTrigger="onChange"
          rules={[validateRingRangeRule]}
        >
          <NumberRangeInput
            values={values}
            onChange={handleRingChange}
            leftInputProps={{
              min: 0,
              placeholder: t('indicators.input.ringRange.startPlaceholder'),
            }}
            rightInputProps={{
              min: 0,
              placeholder: t('indicators.input.ringRange.endPlaceholder'),
            }}
          />
        </Form.Item>
      )}
    </Form>
  );
};
