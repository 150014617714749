import MenuLink from 'ui/menu/link';

import styles from './index.module.scss';

interface Props {
  path?: string;
  alt?: string;
}

export default function AppLogo({ path, alt }: Readonly<Props>) {
  return (
    <MenuLink path="/">
      <img
        className={styles.appLogo}
        src={path ?? '/appLogo.svg'}
        alt={alt ?? 'logo'}
      />
    </MenuLink>
  );
}
