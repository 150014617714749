import { blue, gold, green, red } from '@ant-design/colors';
import { ThemeConfig, theme } from 'antd';

// This is where you overide basic theme of ant design
// change will be moved to the useToken

const defaultTheme: ThemeConfig = {
  components: {
    Layout: {
      headerHeight: 90,
      colorBgHeader: theme.defaultSeed.colorBgBase,
    },
    Card: {
      boxShadowTertiary: '0',
    },
    Button: {
      primaryShadow: '0',
      defaultShadow: '0',
      dangerShadow: '0',
    },
    Descriptions: {
      colorTextTertiary: 'rgba(0, 0, 0, 0.88)',
    },
  },
  token: {
    colorPrimary: '#012169',
    // primaryRed: '#da291c',
    colorInfo: blue[5],
    colorSuccess: green[5],
    // processingColor: blue[5],
    // colorProcessing: blue[5],
    colorError: red[4],
    colorHighlight: red[4],
    colorWarning: gold[5],
    // normalColor: '#d9d9d9',
    colorWhite: '#fff',
    // colorBlack: '#000',
  },
};

export default defaultTheme;
