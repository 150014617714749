import { GetRingsQuery } from 'generated/graphql';
import { PascalToCamelRecord } from 'technical/types';

export type RingTableRow = Omit<
  GetRingsQuery['parameter_ring'][number],
  '__typename'
>;

export const RingTableColumnKeys: Omit<
  PascalToCamelRecord<keyof RingTableRow>,
  'Id'
> = {
  RingNumber: 'ringNumber',
  KeyPosition: 'keyPosition',
  Length: 'length',
  BuildEndMetricPoint: 'buildEndMetricPoint',
  ExcavationStart: 'excavationStart',
  ExcavationEnd: 'excavationEnd',
  TotalExcavationDuration: 'totalExcavationDuration',
  RealExcavationDuration: 'realExcavationDuration',
  BuildStart: 'buildStart',
  BuildEnd: 'buildEnd',
  TotalBuildDuration: 'totalBuildDuration',
  RealBuildDuration: 'realBuildDuration',
};
