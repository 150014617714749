import {
  AgCartesianAxisOptions,
  AgCartesianSeriesOptions,
  AgChartOptions,
} from 'ag-charts-enterprise';
import { AgCharts } from 'ag-charts-react';
import { TFunction, useTranslation } from 'translations/hooks';

type RingPerDayGraphProps = {
  ringPerDayGraphData: {
    date: string;
    shift1Count: number;
    shift2Count: number;
    shift3Count: number;
  }[];
};

const ringPerDayGraphSeriesOptions = (t: TFunction) =>
  [
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'shift1Count',
      yName: t('productionAndPerformance.ringPerDay.graph.seriesNames.shift', {
        shiftKey: 1,
      }), //'Shift 1',
      stacked: true,
    },
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'shift2Count',
      yName: t('productionAndPerformance.ringPerDay.graph.seriesNames.shift', {
        shiftKey: 2,
      }),
      stacked: true,
    },
    {
      type: 'bar',
      xKey: 'date',
      yKey: 'shift3Count',
      yName: t('productionAndPerformance.ringPerDay.graph.seriesNames.shift', {
        shiftKey: 3,
      }),
      stacked: true,
    },
  ] satisfies AgCartesianSeriesOptions[];

const ringPerDayGraphAxesOptions = [
  {
    type: 'category',
    position: 'bottom',
    paddingInner: 0,
    groupPaddingInner: 0,
    paddingOuter: 0,
  },
  {
    type: 'number',
    position: 'left',
    nice: false,
    interval: {
      step: 1,
    },
  },
] satisfies AgCartesianAxisOptions[];

export function RingPerDayGraph({ ringPerDayGraphData }: RingPerDayGraphProps) {
  const { t } = useTranslation();

  const chartOptions = {
    data: ringPerDayGraphData,
    series: ringPerDayGraphSeriesOptions(t),
    axes: ringPerDayGraphAxesOptions,
  } satisfies AgChartOptions;

  return <AgCharts options={chartOptions} />;
}
