import { Flex, Typography } from 'antd';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hooks';

import { useCheckPermission } from 'business/contextProviders/usePermissions';
import Button from 'ui/button';
import MenuIcon from 'ui/icons/menu';
import MenuPopover from 'ui/navBar/components/menu/menu-popover';

import styles from './index.module.scss';

interface Props {
  permissions: string[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const MenuContent = ({
  children,
  permissions,
  open,
  onOpenChange,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const permissionCheck = useCheckPermission(permissions);
  if (!permissionCheck) {
    return null;
  }

  return (
    <MenuPopover
      open={open}
      onOpenChange={onOpenChange}
      content={
        <div className={styles.container}>
          <Typography className={styles.title}>
            {t('user.menu.title')}
          </Typography>
          <Flex gap="small">{children}</Flex>
        </div>
      }
    >
      <Button
        className={styles.menuButton}
        type="primary"
        size="large"
        icon={<MenuIcon />}
      >
        {t('user.menu.button')}
      </Button>
    </MenuPopover>
  );
};

export default MenuContent;
