import * as d3 from 'd3';

import {
  createActivitiesTotal,
  createActivitiesTotalLabel,
  updateActivitiesTotal,
  updateActivitiesTotalLabel,
} from './activityTotal';
import { initAxis, splitActivityNamesOnTwoLines } from './axis';
import { createEmptyZones, getEmptyZones, updateEmptyZones } from './emptyZone';
import {
  ACTIVITY_TOTAL_CLASS,
  CHART_GROUP_CLASS,
  EMPTY_ZONE_GROUP_CLASS,
  getPaddingLeft,
  margin,
  SELECTOR,
  SVG_CHART_CLASS,
  TASK_GROUP_CLASS,
} from './metadata/chart';
import { getSvgHeight, getSvgWidth } from './metadata/svg';
import { taskKeyFunction } from './metadata/task';
import { CreateShiftReportChartsParams } from './metadata/types';
import { createTasks, updateTasks } from './task';

const emptyZoneKeyFunction = ({ startTime, endTime }: any) => {
  return startTime + endTime;
};

export function createShiftReportCharts({
  metadata,
  shift,
  tasks,
  externalEventHandlers,
  criticalPathValid,
  date,
  isTablet,
  mode,
}: CreateShiftReportChartsParams) {
  const chartsRootElement = d3.select(`#${SELECTOR}-${mode}`);

  metadata.forEach(({ width, height, activities }, index) => {
    const {
      scales: { xScale, yScale },
      axis: { xAxis, yAxis },
      activitiesCount,
    } = initAxis([height, width], shift, tasks, activities, date);

    const chartGroupElement = chartsRootElement
      .append('svg')
      .attr('class', SVG_CHART_CLASS)
      .attr('id', `report-chart-${mode}-${index}`)
      .attr('width', getSvgWidth(width))
      .attr('height', getSvgHeight(height))
      .append('g')
      .attr('class', CHART_GROUP_CLASS)
      .attr('transform', `translate(${getPaddingLeft()}, ${margin.top})`);

    if (externalEventHandlers?.handleEmptyZoneClick) {
      // Add empty zone
      const emptyZones = getEmptyZones(shift, tasks, date, criticalPathValid);
      const allEmptyZoneGroup = chartGroupElement
        .selectAll(`#report-chart-${mode}-${index}`)
        .data(emptyZones, emptyZoneKeyFunction)
        .enter()
        .append('g')
        .attr('class', EMPTY_ZONE_GROUP_CLASS);
      createEmptyZones(
        allEmptyZoneGroup,
        xScale,
        yScale,
        activitiesCount,
        externalEventHandlers.handleEmptyZoneClick,
      );
    }

    // Add data to chart group and create a group for each task
    const allTaskGroup = chartGroupElement
      .selectAll(`#report-chart-${mode}-${index}`)
      .data(
        tasks.filter((task) =>
          activities.find(({ id }) => task.activity.id === id),
        ),
        taskKeyFunction,
      )
      .enter()
      .append('g')
      .attr('class', TASK_GROUP_CLASS);
    createTasks(
      allTaskGroup,
      xScale,
      yScale,
      isTablet,
      mode,
      externalEventHandlers,
    );

    // Add total per activity
    createActivitiesTotalLabel(index, width, mode);
    const usedActivityList = activities;
    const allUsedActivityList = chartGroupElement
      .selectAll(`#report-chart-${mode}-${index}`)
      .data(usedActivityList)
      .enter()
      .append('g')
      .attr('class', ACTIVITY_TOTAL_CLASS);
    createActivitiesTotal(allUsedActivityList, tasks, yScale, width);

    // Time axis
    chartGroupElement
      .append('g')
      .attr('class', 'x chart-axis')
      .attr('transform', `translate(0, ${height - margin.top - margin.bottom})`)
      .transition()
      .call(xAxis);

    // Tasks axis
    chartGroupElement.append('g').attr('class', 'y chart-axis').call(yAxis);
  });
}

export function updateShiftReportCharts({
  metadata,
  shift,
  tasks,
  externalEventHandlers,
  criticalPathValid,
  date,
  isTablet,
  mode,
}: CreateShiftReportChartsParams) {
  const chartsRootElement = d3.select(`#${SELECTOR}-${mode}`);

  metadata.forEach(({ width, height, activities }, index) => {
    const {
      scales: { xScale, yScale },
      axis: { xAxis, yAxis },
      activitiesCount,
    } = initAxis([height, width], shift, tasks, activities, date);

    const chartGroupElement = chartsRootElement
      .select(`#report-chart-${mode}-${index}`)
      .attr('width', getSvgWidth(width))
      .attr('height', getSvgHeight(height))
      .select(`.${CHART_GROUP_CLASS}`)
      .attr('transform', `translate(${getPaddingLeft()}, ${margin.top})`);

    // Update tasks data
    const allTaskGroup = chartGroupElement
      .selectAll(`.${TASK_GROUP_CLASS}`)
      .data(
        tasks.filter((task) =>
          activities.find(({ id }) => task.activity.id === id),
        ),
        taskKeyFunction,
      );

    // Update empty zones data
    if (externalEventHandlers?.handleEmptyZoneClick) {
      const emptyZones = getEmptyZones(shift, tasks, date, criticalPathValid);
      const allEmptyZoneGroup = chartGroupElement
        .selectAll(`.${EMPTY_ZONE_GROUP_CLASS}`)
        .data(emptyZones, emptyZoneKeyFunction);
      const allNewEmptyZoneGroup = allEmptyZoneGroup
        .enter()
        .append('g')
        .attr('class', EMPTY_ZONE_GROUP_CLASS);
      createEmptyZones(
        allNewEmptyZoneGroup,
        xScale,
        yScale,
        activitiesCount,
        externalEventHandlers.handleEmptyZoneClick,
      );
      updateEmptyZones(
        allEmptyZoneGroup,
        xScale,
        yScale,
        activitiesCount,
        externalEventHandlers.handleEmptyZoneClick,
      );
    }

    // Add new tasks
    const allNewTaskGroup = allTaskGroup
      .enter()
      .insert('g', ':first-child')
      .attr('class', TASK_GROUP_CLASS);
    createTasks(
      allNewTaskGroup,
      xScale,
      yScale,
      isTablet,
      mode,
      externalEventHandlers,
    );
    updateTasks(allTaskGroup, xScale, yScale, isTablet, mode);

    // Update total per activity
    updateActivitiesTotalLabel(index, width, mode);
    const usedActivityList = activities;
    const allUsedActivityList = chartGroupElement
      .selectAll(`.${ACTIVITY_TOTAL_CLASS}`)
      .data(usedActivityList);

    const allNewUsedActivityTotal = allUsedActivityList
      .enter()
      .insert('g', ':first-child')
      .attr('class', ACTIVITY_TOTAL_CLASS);
    createActivitiesTotal(allNewUsedActivityTotal, tasks, yScale, width);
    updateActivitiesTotal(allUsedActivityList, tasks, yScale, width);

    chartGroupElement
      .select('.x')
      .attr('transform', `translate(0, ${height - margin.top - margin.bottom})`)
      // @ts-expect-error : Not compatible for typescript but the doc say to do this way
      .call(xAxis);

    const taskAxis = chartGroupElement
      .select('.y')
      // @ts-expect-error : Not compatible for typescript but the doc say to do this way
      .call(yAxis);

    taskAxis.selectAll('.y .tick text').call(splitActivityNamesOnTwoLines);
  });
}
