import { message } from 'antd';
import { useState } from 'react';
import { LoaderFunction, redirect, useLoaderData } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import { FilterCardByMode } from 'business/data-analysis/components/filterCard';
import { useFilters } from 'business/data-analysis/components/filterCard/hooks/use-filters';
import { GraphCard } from 'business/data-analysis/components/graphCard';
import { GraphListSideBar } from 'business/data-analysis/components/graphListSideBar';
import { DataAnalysisHeader } from 'business/data-analysis/components/header';
import { ParameterSideBar } from 'business/data-analysis/components/parameterSideBar';
import { useParameters } from 'business/data-analysis/components/parameterSideBar/hooks/use-parameters';
import SaveSideBar from 'business/data-analysis/components/save-side-bar';
import {
  graphSetStateVersion,
  mapPlotterModeToGraphSetType,
  PlotterMode,
} from 'business/data-analysis/constants';
import { useExportGraphSet } from 'business/data-analysis/hooks/use-export-graph-set';
import { useGetGraphValues } from 'business/data-analysis/hooks/use-get-graph-values';
import { useGraphSetState } from 'business/data-analysis/hooks/use-graph-set-state';
import { useQueryParams } from 'business/data-analysis/hooks/use-query-params';
import {
  SaveGraphSetForm,
  UpdateGraphState,
} from 'business/data-analysis/pages/graph/types';
import Routes from 'config/routes';
import { useDataAnalysisControllerSaveGraphSet } from 'generated/apiComponents';
import { SaveGraphSetResponse } from 'generated/apiSchemas';
import {
  useGetGraphSetLazyQuery,
  useGetSavedGraphCountQuery,
} from 'generated/graphql';
import { LoaderData } from 'technical/router/types';
import Button from 'ui/button';
import Flex from 'ui/flex';

import styles from './index.module.scss';

export const dataAnalysisGraphPageLoader = (({ params }) => {
  if (
    !params.mode ||
    !Object.values(PlotterMode).includes(params.mode as any)
  ) {
    return redirect(Routes.DataAnalysisModeSelection);
  }
  return { mode: params.mode as PlotterMode };
}) satisfies LoaderFunction;

export const DataAnalysisGraphPage = () => {
  // Cast to get the correct type instead of unknown
  // It corresponds to the signature of the function that gives the data
  const { mode } = useLoaderData() as LoaderData<
    typeof dataAnalysisGraphPageLoader
  >;

  const { t } = useTranslation();

  const [messageApi, contextHolder] = message.useMessage();

  const {
    graphSet,
    setGraphSet,
    savedGraphSet,
    setSavedGraphSet,
    disabledDeletion,
    updateFullGraphSet,
    updateGraph,
    updateAllGraphs,
    addNewGraph,
    deleteGraph,
    deleteGraphSet,
    extractGraphSetValues,
    currentGraphIndex,
    setCurrentGraphIndex,
    currentGraphSetId,
    setCurrentGraphSetId,
  } = useGraphSetState(mode);

  const { currentConstructionSite, user } = useAppContext();
  invariant(user && currentConstructionSite, 'No constructionSite id');

  const { filters, onChangeFilters } = useFilters();

  const [parameterSideBarVisible, setParameterSideBarVisible] = useState(false);
  const [graphListSideBarVisible, setGraphListSideBarVisible] = useState(false);
  const [saveSideBarVisible, setSaveSideBarVisible] = useState(false);

  const {
    parameterFamilies,
    addParametersToChart,
    updateGraphParameter,
    removeGraphParameter,
    removeAllGraphParameters,
    atLeastOneParameterIsSelected,
  } = useParameters(graphSet, updateGraph, currentConstructionSite.id);

  const queryParams = useQueryParams(
    currentConstructionSite.id,
    filters,
    graphSet,
  );

  const { getUpdatedGraphByMode, isFetching } = useGetGraphValues(queryParams);

  const {
    data: graphSetCountResult,
    loading,
    refetch: refetchGraphSetCount,
  } = useGetSavedGraphCountQuery({
    variables: {
      constructionSiteId: currentConstructionSite.id,
      userId: user?.id,
      type: mapPlotterModeToGraphSetType[mode],
    },
  });

  const { mutateAsync: saveGraphSet, isPending: isSavePending } =
    useDataAnalysisControllerSaveGraphSet({
      onSuccess: () => refetchGraphSetCount(),
    });

  const [loadGraphSet] = useGetGraphSetLazyQuery();

  const { generateGraphSetExportByMode } = useExportGraphSet(messageApi);

  const onOpenParameterSideBar = (graphIndex: number) => {
    setCurrentGraphIndex(graphIndex);
    setParameterSideBarVisible(true);
  };
  const onCloseParameterSidebar = () => setParameterSideBarVisible(false);

  const onOpenGraphListSideBar = () => {
    setGraphListSideBarVisible(true);
  };
  const onCloseGraphListSideBar = () => setGraphListSideBarVisible(false);

  const onCloseSaveSideBar = () => setSaveSideBarVisible(false);

  const generateGraphs = async () => {
    const updatedGraphs = await getUpdatedGraphByMode(mode, graphSet.graphs);
    if (!updatedGraphs) {
      return;
    }

    updateAllGraphs(updatedGraphs);
  };

  const graphDeletion = (graphIndex: number) => {
    removeAllGraphParameters(graphIndex)();
    deleteGraph(graphIndex);
  };

  const generateButtonIsDisabled = isFetching || !atLeastOneParameterIsSelected;

  const onSaveGraphSetSuccess = (data: SaveGraphSetResponse) => {
    if (data?.id) {
      setCurrentGraphSetId(data.id);
    }
    onCloseSaveSideBar();
  };

  const onSave = async ({ title }: SaveGraphSetForm) => {
    const newSavedGraphSet = { ...graphSet, title };

    updateFullGraphSet(newSavedGraphSet);

    await saveGraphSet(
      {
        body: {
          id: currentGraphSetId ?? undefined,
          constructionSiteId: currentConstructionSite.id,
          graphSetState: extractGraphSetValues(newSavedGraphSet),
          userId: user.id,
          version: graphSetStateVersion,
        },
      },
      {
        onSuccess: (data) => {
          onSaveGraphSetSuccess(data);
          setSavedGraphSet(newSavedGraphSet);
        },
      },
    );
  };

  const onLoadGraphSet = async (graphSetId: string) => {
    // LazyQuery exec func does not throw but only return error property
    // https://github.com/apollographql/apollo-client/issues/11399
    const { data, error } = await loadGraphSet({
      variables: { id: graphSetId },
    });

    if (error || !data?.graphSet_by_pk) {
      messageApi.warning(t('errors.error_generic'));
      return;
    }

    const title = data.graphSet_by_pk.name;
    const loadedGraphSet = { ...data.graphSet_by_pk, title };
    setCurrentGraphSetId(graphSetId);
    setGraphSet(loadedGraphSet);
    setSavedGraphSet(loadedGraphSet);

    onCloseGraphListSideBar();
  };

  const exportGraphSet = () => {
    return generateGraphSetExportByMode(
      mode,
      currentConstructionSite.id,
      extractGraphSetValues(graphSet),
      filters,
    );
  };

  return (
    <Flex className={styles.page}>
      {contextHolder}
      <SaveSideBar
        title={t('dataAnalysis.saveSideBar.title')}
        visible={saveSideBarVisible}
        savePending={isSavePending}
        onCloseSaveSideBar={onCloseSaveSideBar}
        onSave={onSave}
      />
      <ParameterSideBar
        visible={parameterSideBarVisible}
        onCloseParameterSidebar={onCloseParameterSidebar}
        parameterFamilies={parameterFamilies}
        addParametersToChart={addParametersToChart(currentGraphIndex)}
      />
      <GraphListSideBar
        visible={graphListSideBarVisible}
        onClose={onCloseGraphListSideBar}
        onLoadGraphSet={onLoadGraphSet}
        currentGraphSetId={currentGraphSetId}
        deleteGraphSet={deleteGraphSet}
        refetchGraphSetCount={refetchGraphSetCount}
        mode={mode}
      />
      <Flex className={styles.container} column>
        <DataAnalysisHeader
          mode={mode}
          currentGraphSetId={currentGraphSetId}
          setCurrentGraphSetId={setCurrentGraphSetId}
          displaySavedGraphs={onOpenGraphListSideBar}
          displaySavedGraphsButtonIsDisabled={
            graphSetCountResult?.graphSet_aggregate.aggregate?.count === 0 ||
            loading
          }
          generateGraphs={generateGraphs}
          generateButtonIsDisabled={generateButtonIsDisabled}
          setSaveSideBarVisible={setSaveSideBarVisible}
          atLeastOneParameterIsSelected={atLeastOneParameterIsSelected}
          graphSetTitle={graphSet.title}
          exportGraphSet={exportGraphSet}
          graphSet={graphSet}
          setGraphSet={setGraphSet}
          savedGraphSet={savedGraphSet}
          setSavedGraphSet={setSavedGraphSet}
        />
        <Flex className={styles.content} column>
          <FilterCardByMode
            onChangeFilters={onChangeFilters}
            filters={filters}
            mode={mode}
          />
          {graphSet.graphs.map((graph, _index) => (
            <GraphCard
              key={`graph_${graph.index}`}
              addParameter={() => onOpenParameterSideBar(graph.index)}
              updateParameter={updateGraphParameter(graph.index)}
              removeAllParameters={removeAllGraphParameters(graph.index)}
              removeParameter={removeGraphParameter(graph.index)}
              graphState={graph}
              updateGraphState={(state: UpdateGraphState) => {
                updateGraph({ ...state, index: graph.index });
              }}
              deleteGraph={graphDeletion}
              disabledDeletion={disabledDeletion}
              mode={mode}
            />
          ))}
          {graphSet.graphs.length < 4 ? (
            <Button
              onClick={() => addNewGraph()}
              className={styles.action}
              type="primary"
            >
              {t('dataAnalysis.actions.addGraph')}
            </Button>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
};
