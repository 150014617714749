import { DatePicker, Form, Input, InputNumber, Select, TimePicker } from 'antd';
import { Rule } from 'antd/es/form';

import { TIME_HOUR_MINUTE_SEC_FORMAT } from 'business/production-and-performance/components/ring-table/constants';

type InputType = 'text' | 'number' | 'dateWithTime' | 'time' | 'select';

interface EditableCellProps<T> extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  inputType: InputType;
  record: T;
  index: number;
  defaultValue: any;
  selectOptions?: any[];
  rules?: Rule[];
}

const createInputTypes = (selectOptions?: any[]) => {
  return {
    text: <Input />,
    number: <InputNumber />,
    dateWithTime: <DatePicker showTime />,
    time: <TimePicker format={TIME_HOUR_MINUTE_SEC_FORMAT} />,
    select: <Select options={selectOptions} />,
  };
};

export const EditableCell = <T,>({
  editing,
  dataIndex,
  inputType,
  selectOptions,
  children,
  rules,
  ...restProps
}: React.PropsWithChildren<EditableCellProps<T>>) => {
  const inputTypes = createInputTypes(selectOptions);
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
            },
            ...(rules ?? []),
          ]}
        >
          {inputTypes[inputType]}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
