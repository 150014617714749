export enum ILanguage {
  enGB = 'en-GB',
  enUS = 'en-US',
  fr = 'fr',
  es = 'es',
}

export enum Locale {
  enGb = 'en-gb',
  en = 'en',
  fr = 'fr',
  es = 'es',
}
