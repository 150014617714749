import { TreeDataNode } from 'antd';
import { DataNode } from 'antd/es/tree';
import { useCallback, useMemo, useState } from 'react';

import { parameterFamilyKeyPrefix } from 'business/data-analysis/pages/graph/types';
import { ParameterFamiliesQuery } from 'generated/graphql';
import Flex from 'ui/flex';

import styles from './index.module.scss';

export const useSearchParameters = (
  parameterFamilies: ParameterFamiliesQuery['parameter_familyDef'],
) => {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [treeData, setTreeData] = useState<DataNode[]>([]);

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
  };

  const baseTreeData: TreeDataNode[] = useMemo(() => {
    return parameterFamilies.map((family) => {
      return {
        title: family.name,
        meta: family.name,
        key: `${parameterFamilyKeyPrefix}${family.id}`,
        selectable: false,
        children: family.parameterDefs.map((parameter) => {
          return {
            meta: parameter.name,
            title: (
              <Flex className={styles.parameter} justify="space-between">
                <div>{parameter.name}</div>
                <div>{parameter.unit || ''}</div>
              </Flex>
            ),
            key: `${parameter.id}`,
          };
        }),
      };
    });
  }, [parameterFamilies]);

  const baseStringData = parameterFamilies.flatMap((family) => {
    return [family.name, ...family.parameterDefs.map((param) => param.name)];
  });

  const search = (searchInput: string) => {
    return baseStringData.filter((dataString) =>
      dataString.toLowerCase().includes(searchInput.toLowerCase()),
    );
  };

  const searchParameters = (family: TreeDataNode, searchMatches: string[]) => {
    if (!family.children) {
      return;
    }
    return family.children
      .map((parameter) => {
        const meta = (parameter as unknown as { meta: string }).meta;
        if (searchMatches.includes(meta)) {
          return parameter;
        }
      })
      .filter((child): child is TreeDataNode => !!child);
  };

  const searchTreeData = useCallback(
    (searchResult: string[]) => {
      if (searchResult.length === 0) {
        return baseTreeData;
      }

      const newExpandedKeys: React.Key[] = [];

      const filtered = baseTreeData
        .map((family) => {
          // If family name matches search input, return the entire family
          const familyMeta = (family as unknown as { meta: string }).meta;
          if (searchResult.includes(familyMeta)) {
            return family;
          }

          // Map parameters to only return those matching search input
          const mappedParameters = searchParameters(family, searchResult);

          if (mappedParameters && mappedParameters.length > 0) {
            newExpandedKeys.push(family.key);
            return { ...family, children: mappedParameters };
          }
        })
        .filter((data): data is TreeDataNode => !!data);

      setExpandedKeys(newExpandedKeys);
      return filtered;
    },
    [baseTreeData],
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchInputValue(value);
    setExpandedKeys([]);

    if (!value) {
      return;
    }

    const searchResult = search(value);
    const searchTreeDataResult = searchTreeData(searchResult);
    setTreeData(searchTreeDataResult);
  };

  return {
    expandedKeys,
    onExpand,

    baseTreeData,
    onChange,
    treeData,

    searchInputValue,
    setSearchInputValue,
  };
};
