import { InfoCircleTwoTone } from '@ant-design/icons';
import {
  Button,
  Card,
  Tag,
  ColorPicker,
  Typography,
  Select,
  Form,
  ConfigProvider,
} from 'antd';
import { Color } from 'antd/es/color-picker';
import { TFunction, useTranslation } from 'translations/hooks';

import { PlotterMode } from 'business/data-analysis/constants';
import { ParameterDto } from 'generated/apiSchemas';
import Flex from 'ui/flex';
import TrashIcon from 'ui/icons/trash';

import styles from './index.module.scss';

interface Props {
  parameter: ParameterDto;
  updateParameter: (parameter: ParameterDto) => void;
  removeParameter: (parameterId: number) => void;
  mode: PlotterMode;
}

const computeTypeOptions = (t: TFunction) => [
  {
    value: 'excavation',
    label: t('dataAnalysis.ringMode.computeType.excavation'),
  },
  {
    value: 'build',
    label: t('dataAnalysis.ringMode.computeType.build'),
  },
  {
    value: 'excavation_and_build',
    label: t('dataAnalysis.ringMode.computeType.excavation_and_build'),
  },
  {
    value: 'total',
    label: t('dataAnalysis.ringMode.computeType.total'),
  },
];

const aggregationTypeOptions = (t: TFunction) => [
  {
    value: 'avg',
    label: t('dataAnalysis.ringMode.aggregationType.avg'),
  },
  {
    value: 'max',
    label: t('dataAnalysis.ringMode.aggregationType.max'),
  },
  {
    value: 'min',
    label: t('dataAnalysis.ringMode.aggregationType.min'),
  },
  {
    value: 'last',
    label: t('dataAnalysis.ringMode.aggregationType.last'),
  },
  {
    value: 'sum',
    label: t('dataAnalysis.ringMode.aggregationType.sum'),
  },
];

const ParameterCard = ({
  parameter,
  updateParameter,
  removeParameter,
  mode,
}: Props) => {
  const { t } = useTranslation();
  const changeColor = (color: Color) =>
    updateParameter({ ...parameter, color: color.toHexString() });

  const changeComputeType = (computeType: ParameterDto['computeType']) =>
    updateParameter({ ...parameter, computeType: computeType });

  const changeAggregationType = (aggType: ParameterDto['aggregationType']) =>
    updateParameter({ ...parameter, aggregationType: aggType });

  return (
    <Card className={styles.parameterCard}>
      <Flex column>
        <Flex justify="space-between" className={styles.cardContent}>
          <Flex alignItems="center" className={styles.parameterDefinition}>
            <ColorPicker
              size="small"
              onChangeComplete={changeColor}
              disabledAlpha
              value={parameter.color}
            />
            <Flex
              column
              justify="space-between"
              className={styles.parameterNameAndUnit}
            >
              <Typography>{parameter.name}</Typography>
              <Tag className={styles.unit}>{parameter.unit}</Tag>
            </Flex>
          </Flex>
          <Button
            className={styles.removeParameter}
            icon={<TrashIcon className={styles.removeParameterIcon} />}
            onClick={() => removeParameter(parameter.index)}
          />
        </Flex>
        {mode === PlotterMode.RING && (
          <ConfigProvider
            // Override default margin (24px) on Form.Item
            theme={{ components: { Form: { itemMarginBottom: 5 } } }}
          >
            <Form.Item
              label={t('dataAnalysis.ringMode.computeType.title')}
              tooltip={{
                title: t('dataAnalysis.ringMode.computeType.tooltip'),
                icon: <InfoCircleTwoTone />,
              }}
            >
              <Select
                size="small"
                value={parameter.computeType}
                onChange={changeComputeType}
                options={computeTypeOptions(t)}
              />
            </Form.Item>

            <Form.Item
              label={t('dataAnalysis.ringMode.aggregationType.title')}
              tooltip={{
                title: t('dataAnalysis.ringMode.aggregationType.tooltip'),
                icon: <InfoCircleTwoTone />,
              }}
            >
              <Select
                size="small"
                value={parameter.aggregationType}
                onChange={changeAggregationType}
                options={aggregationTypeOptions(t)}
              />
            </Form.Item>
          </ConfigProvider>
        )}
      </Flex>
    </Card>
  );
};

export default ParameterCard;
