import { Pagination } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from 'business/contextProviders/useAppContext';
import ReportCard from 'business/report/components/ReportCard';
import { ShiftReportCardInfoFragment } from 'generated/graphql';
import useSearchParamQuery from 'technical/router/hooks/useSearchParamQuery';
import Flex from 'ui/flex';
import Loader from 'ui/loader';

import styles from './index.module.scss';

export const DEFAULT_PAGE_LIMIT = 5;
export const DEFAULT_PAGE_NUMBER = 1;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  refetchList: () => Promise<unknown>;
  shiftReports?: ShiftReportCardInfoFragment[];
  paginationMeta?: {
    pageSize?: number;
    shiftReportCount: number;
  };
  emptyListComponent: React.ReactNode;
}

function ReportList({
  loading,
  refetchList,
  shiftReports,
  emptyListComponent,
  className,
  paginationMeta,
  ...divProps
}: Props) {
  const { currentConstructionSiteId } = useAppContext();
  const searchParamsQuery = useSearchParamQuery();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(
    Number(searchParamsQuery.get('page') ?? DEFAULT_PAGE_NUMBER),
  );

  const shiftReportList =
    shiftReports && shiftReports.length > 0
      ? shiftReports.map((report) => {
          return (
            <ReportCard
              key={report.id}
              report={report}
              refetchList={refetchList}
            />
          );
        })
      : emptyListComponent;

  const changePage = useCallback(
    (page: number) => {
      setCurrentPage(page);
      navigate({ search: `?page=${page}` });
    },
    [navigate],
  );

  useEffect(() => {
    changePage(DEFAULT_PAGE_NUMBER);
  }, [currentConstructionSiteId, changePage]);

  return (
    <div {...divProps} className={classNames(styles.reportList, className)}>
      <Flex column>{loading ? <Loader /> : shiftReportList}</Flex>
      {paginationMeta && (
        <Pagination
          current={currentPage}
          defaultCurrent={DEFAULT_PAGE_NUMBER}
          total={paginationMeta.shiftReportCount}
          showSizeChanger={false}
          className={styles.paginationBox}
          hideOnSinglePage
          onChange={changePage}
          defaultPageSize={paginationMeta.pageSize ?? DEFAULT_PAGE_LIMIT}
        />
      )}
    </div>
  );
}

export default ReportList;
