import { Card } from 'antd';
import classNames from 'classnames';
import React, { CSSProperties } from 'react';

import './CapCard.scss';
import colors from 'config/colors';

interface Props {
  className?: string;
  isFinished?: boolean;
  isValidated?: boolean;
  style?: CSSProperties;
  children: any;
  title: React.ReactNode;
}

function CapCard({
  children,
  style,
  title,
  className,
  isFinished = true,
  isValidated = false,
}: Props) {
  const titleColor = () => {
    if (isFinished || isValidated) {
      return colors.primaryColor;
    }
    return colors.primaryRed;
  };
  return (
    <div style={style} className={classNames('cap-card', className)}>
      <Card
        title={title}
        bordered={false}
        styles={{
          header: {
            color: titleColor(),
          },
        }}
      >
        {children}
      </Card>
    </div>
  );
}

export default CapCard;
