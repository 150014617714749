import { Form, message, Modal } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'translations/hooks';

import { UpdateRingForm } from 'business/production-and-performance/components/update-ring-form';
import { RingTableRow } from 'business/production-and-performance/pages/ring-table/types';
import { parseRingDurationToDate } from 'business/production-and-performance/services/parse-ring-duration';
import { parseRingTimeToDate } from 'business/production-and-performance/services/parse-ring-time-to-date';
import { formatRingDateTimeToTimeFormat } from 'business/production-and-performance/services/ring-date-time-to-time-format';
import {
  GetRingsQuery,
  useUpdateParameterRingMutation,
} from 'generated/graphql';
import apolloClient from 'technical/graphql/client';
import { isUndefinedOrNull } from 'technical/utils/is-null-or-undefined';

interface UpdateRingModalProps {
  isModalVisible: boolean;
  closeModal: () => void;
  ring: GetRingsQuery['parameter_ring'][number] | null;
}

export const UpdateRingModal: React.FC<UpdateRingModalProps> = ({
  isModalVisible,
  closeModal,
  ring,
}) => {
  const [messageApi, contextHolder] = message.useMessage();

  const { t } = useTranslation();
  const [form] = Form.useForm<GetRingsQuery['parameter_ring'][number]>();
  const [updateParameterRingMutation] = useUpdateParameterRingMutation();

  const save = async (row: RingTableRow) => {
    try {
      await updateParameterRingMutation({
        variables: {
          id: ring?.id,
          updatedFields: {
            ...row,
            realExcavationDuration: formatRingDateTimeToTimeFormat(
              row.realExcavationDuration,
            ),
            realBuildDuration: formatRingDateTimeToTimeFormat(
              row.realBuildDuration,
            ),
          },
        },
        onCompleted: async () => {
          await apolloClient.reFetchObservableQueries();
          closeModal();
          messageApi.open({
            type: 'success',
            content: t('pages.ring.edit.modal.update.success'),
          });
        },
        onError: () => {
          messageApi.open({
            type: 'error',
            content: t('pages.ring.edit.modal.update.error'),
          });
        },
      });
    } catch (err) {
      messageApi.open({
        type: 'error',
        content: t('pages.ring.edit.modal.update.error'),
      });
    }
  };

  useEffect(() => {
    if (ring) {
      form.setFieldsValue({
        ...ring,
        excavationStart: parseRingTimeToDate(ring.excavationStart),
        excavationEnd: parseRingTimeToDate(ring.excavationEnd),
        realExcavationDuration: parseRingDurationToDate(
          ring.realExcavationDuration,
        ),
        buildStart: parseRingTimeToDate(ring.buildStart),
        buildEnd: parseRingTimeToDate(ring.buildEnd),
        realBuildDuration: parseRingDurationToDate(ring.realBuildDuration),
      });
    }
  }, [form, ring]);

  return (
    <>
      {contextHolder}
      <Modal
        title={t('pages.ring.edit.modal.title', {
          ringNumber: ring?.ringNumber,
        })}
        open={isModalVisible}
        onOk={form.submit}
        onCancel={closeModal}
        destroyOnClose
      >
        {!isUndefinedOrNull(ring) ? (
          <UpdateRingForm form={form} save={save} />
        ) : null}
      </Modal>
    </>
  );
};
