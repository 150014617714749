import { MailOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import { Auth0ParseHashError } from 'auth0-js';
import { useState, useEffect } from 'react';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/contextProviders/useAppContext';
import DisconnectedPage from 'business/layout/disconnectedPage';
import { requestLoginCallback } from 'business/user/services/authentication';
import logger from 'technical/logger';
import Flex from 'ui/flex';
import Loader from 'ui/loader';

function LoginProviderCallbackScreen() {
  const [error, setError] = useState<Auth0ParseHashError | undefined>(
    undefined,
  );
  const { t } = useTranslation();
  const { requestRebootstrap } = useAppContext();

  useEffect(() => {
    requestLoginCallback()
      .then(() => {
        return requestRebootstrap();
      })
      .catch((err) => {
        logger.error(err);
        setError(err);
      });
  }, [requestRebootstrap]);

  if (error) {
    let errorKey = 'default';

    switch (error.errorDescription) {
      case 'invalid-email-domain':
      case 'email-not-verified':
      case 'email-not-registered':
        errorKey = error.errorDescription;
        break;
      default:
        break;
    }

    return (
      <DisconnectedPage>
        <Flex
          style={{ flex: 1, padding: '20px' }}
          justify="center"
          alignItems="center"
        >
          <Result
            icon={<MailOutlined />}
            title={t(`notConnected.login.callback.error.${errorKey}.title`)}
            subTitle={t(
              `notConnected.login.callback.error.${errorKey}.subTitle`,
            )}
          />
        </Flex>
      </DisconnectedPage>
    );
  }
  return (
    // Shortly displaying loading indicator, before either displaying error or redirecting to home page
    <DisconnectedPage>
      <Loader />
    </DisconnectedPage>
  );
}

export default LoginProviderCallbackScreen;
