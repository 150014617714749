import { Dayjs } from 'dayjs';
import { i18n } from 'translations';

import { parseShiftTimeStringWithReportDate } from 'business/shift/services/timeOperations';
import { ShiftFullFragment } from 'generated/graphql';

export const getShiftTotalTime = (
  date: Dayjs,
  { startTime, endTime }: ShiftFullFragment,
) => {
  const startDate = parseShiftTimeStringWithReportDate(startTime, date);
  let endDate = parseShiftTimeStringWithReportDate(endTime, date);
  if (startDate.isAfter(endDate)) {
    endDate = endDate.add(1, 'day');
  }
  return endDate.diff(startDate, 'minute');
};

export const formatRingInvalidText = (ringsInvalid: string[]) => {
  if (ringsInvalid.length >= 2) {
    return i18n.t('common.and', {
      firstString: ringsInvalid.slice(0, -1).join(', '),
      secondString: ringsInvalid.slice(-1),
    });
  }
  return ringsInvalid[0];
};
