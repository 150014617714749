import { PlotterMode } from 'business/data-analysis/constants';
import {
  formatGraphByPeriodData,
  formatGraphByRingData,
} from 'business/data-analysis/services/format-graph-data';
import {
  useDataAnalysisControllerGraphByPeriod,
  useDataAnalysisControllerGraphByRing,
} from 'generated/apiComponents';
import { GraphStateDto } from 'generated/apiSchemas';

import { useQueryParams } from './use-query-params';

export const useGetGraphValues = (
  queryParams: ReturnType<typeof useQueryParams>,
) => {
  const {
    refetch: fetchGraphValuesByPeriod,
    isFetching: isFetchingGraphValuesByPeriod,
  } = useDataAnalysisControllerGraphByPeriod(
    queryParams.queryParamsForGraphByPeriod,
    // enabled false === lazy query
    { enabled: false, retry: false },
  );

  const {
    mutateAsync: fetchGraphValuesByRing,
    isPending: isFetchingGraphValuesByRing,
  } = useDataAnalysisControllerGraphByRing();

  const getUpdatedGraphByMode = async (
    mode: PlotterMode,
    graphList: GraphStateDto[],
  ) => {
    if (mode === PlotterMode.TIME_PERIOD) {
      const value = await fetchGraphValuesByPeriod();
      if (!value.data) {
        return null;
      }

      return formatGraphByPeriodData(value.data, graphList);
    }

    if (mode === PlotterMode.RING) {
      const graphByRingResponse = await fetchGraphValuesByRing(
        queryParams.queryParamsForGraphByRing,
      );
      if (!graphByRingResponse) {
        return null;
      }

      return formatGraphByRingData(graphByRingResponse, graphList);
    }

    return null;
  };

  return {
    getUpdatedGraphByMode,
    isFetching: isFetchingGraphValuesByPeriod || isFetchingGraphValuesByRing,
  };
};
