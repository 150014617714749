import Icon from '@ant-design/icons/lib/components/Icon';

import { CustomIconProps } from 'ui/icons/types';

const PencilSvg = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <title>pencil icon</title>
    <path
      d="M10.7724 4.23879L11.8823 3.12879C12.4682 2.54301 13.4179 2.54301 14.0037 3.12879L15.0644 4.18946C15.6501 4.77524 15.6501 5.72499 15.0644 6.31077L13.9544 7.42077M10.7724 4.23879L3.56063 11.4505C3.31158 11.6996 3.1581 12.0283 3.12711 12.3792L2.94555 14.4345C2.90447 14.8995 3.29362 15.2887 3.75864 15.2476L5.81394 15.066C6.1648 15.0351 6.49356 14.8815 6.74261 14.6325L13.9544 7.42077M10.7724 4.23879L13.9544 7.42077"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PencilIcon = (props: CustomIconProps) => (
  <Icon component={PencilSvg} {...props} />
);

export default PencilIcon;
