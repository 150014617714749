export const defaultColors = [
  '#2b5c95',
  '#cc6f10',
  '#1e652e',
  '#18859e',
  '#a69400',
  '#603c88',
  '#575757',
  '#7d2f6d',
  '#4f3508',
  '#a82529',
];
