import { Result } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'translations/hooks';

import Button from 'ui/button';
import './SmallScreenWarning.scss';

function SmallScreen() {
  const { t } = useTranslation();
  const [smallScreenWarning, setSmallScreenWarning] = useState<boolean>(true);

  return (
    <>
      {smallScreenWarning && (
        <div className="too-small-screen">
          <Result
            status="warning"
            title={t('errors.too_small_screen')}
            extra={
              <Button
                type="primary"
                onClick={() => setSmallScreenWarning(false)}
              >
                {t('common.continue_anyway')}
              </Button>
            }
          />
        </div>
      )}
    </>
  );
}

export default SmallScreen;
