import { RingTableColumnKeys } from 'business/production-and-performance/pages/ring-table/types';

export const TIME_HOUR_MINUTE_SEC_FORMAT = 'HH:mm:ss';

export const dateColumns: string[] = [
  RingTableColumnKeys.ExcavationStart,
  RingTableColumnKeys.ExcavationEnd,
  RingTableColumnKeys.BuildStart,
  RingTableColumnKeys.BuildEnd,
];

export const numberColumns: string[] = [
  RingTableColumnKeys.RingNumber,
  RingTableColumnKeys.KeyPosition,
  RingTableColumnKeys.BuildEndMetricPoint,
];

export const timeColumns: string[] = [
  RingTableColumnKeys.TotalExcavationDuration,
  RingTableColumnKeys.RealExcavationDuration,
  RingTableColumnKeys.TotalBuildDuration,
  RingTableColumnKeys.RealBuildDuration,
];

export const selectColumns: string[] = [RingTableColumnKeys.Length];
