import { Card as AntCard, CardProps as AntCardProps } from 'antd';
import classNames from 'classnames';

import { CardHeader, CardHeaderProps } from './card-header';
import style from './index.module.scss';

interface CardProps extends AntCardProps {}

const Card: React.FC<CardProps> & { Header: React.FC<CardHeaderProps> } = ({
  children,
  className,
  ...otherProps
}) => {
  return (
    <AntCard
      bordered={false}
      className={classNames([style.card, className])}
      {...otherProps}
    >
      {children}
    </AntCard>
  );
};

Card.Header = CardHeader;
export default Card;
