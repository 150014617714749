import { Row, Col } from 'antd';
import React from 'react';

const InputRow = ({
  inputLeft,
  inputRight,
}: {
  inputLeft: React.ReactNode;
  inputRight?: React.ReactNode;
}) => (
  <Row>
    <Col md={10} lg={10} xl={7}>
      {inputLeft}
    </Col>
    {inputRight && (
      <Col
        md={{ span: 10, offset: 2 }}
        lg={{ span: 10, offset: 2 }}
        xl={{ span: 7, offset: 2 }}
      >
        {inputRight}
      </Col>
    )}
  </Row>
);

export default InputRow;
