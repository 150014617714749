import { Dayjs } from 'dayjs';

import { GraphStateDto } from 'generated/apiSchemas';
import { NumberRangeValue } from 'ui/form/numberRangeInput';

export enum DisplayType {
  graph = 'graph',
  table = 'table',
}

export type UpdateGraphState = Omit<GraphStateDto, 'index'>;

export type SaveGraphSetForm = {
  title: string;
};

export type GraphByPeriodFilters = {
  startDate: Dayjs;
  endDate: Dayjs;
};

export type GraphFilters = {
  dateFilters: GraphByPeriodFilters;
  ringFilters: NumberRangeValue;
};

export const parameterFamilyKeyPrefix = 'parameterFamilyKeyPrefix';

export type DataPoint = { date: number } & { [key: string]: number };
