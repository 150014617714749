import { RangePickerProps } from 'antd/lib/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

import { NumberRangeValue } from 'ui/form/numberRangeInput';

import { OnChangeGraphFilters } from './types';

export const useFilters = () => {
  const [startDate, setStartDate] = useState<Dayjs>(
    dayjs().subtract(1, 'day').startOf('day'),
  );
  const [endDate, setEndDate] = useState<Dayjs>(
    dayjs().subtract(1, 'day').endOf('day'),
  );

  const [ringRange, setRingRange] = useState<NumberRangeValue>([null, null]);

  const onChangeDateRange: RangePickerProps['onChange'] = (
    dates,
    _dateStrings,
  ) => {
    if (dates?.[0]) {
      setStartDate(dates[0]);
    }
    if (dates?.[1]) {
      setEndDate(dates[1]);
    }
  };

  const onChangeRingRange: OnChangeGraphFilters['onChangeRingRange'] = (
    values: NumberRangeValue,
  ) => {
    setRingRange(values);
  };

  const dateFilters = { startDate, endDate };
  const ringFilters = ringRange;

  return {
    filters: { dateFilters, ringFilters },
    onChangeFilters: { onChangeDateRange, onChangeRingRange },
  };
};
