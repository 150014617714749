import { Typography } from 'antd';
import { DatePicker } from 'antd';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'translations/hooks';

import { usePermissions } from 'business/contextProviders/usePermissions';
import { PlotterMode } from 'business/data-analysis/constants';
import { GraphFilters } from 'business/data-analysis/pages/graph/types';
import { useDataAnalysisControllerGetCurrentRingNumber } from 'generated/apiComponents';
import { notEmpty } from 'technical/utils/not-empty';
import { FilterCard } from 'ui/filter-card';
import Flex from 'ui/flex';
import NumberRangeInput from 'ui/form/numberRangeInput';

import { OnChangeGraphFilters } from './hooks/types';
import styles from './index.module.scss';

interface Props {
  mode: PlotterMode;
  filters: GraphFilters;
  onChangeFilters: OnChangeGraphFilters;
}

const { RangePicker } = DatePicker;

const DataAnalysisFilterCard = ({ children }: PropsWithChildren) => {
  return <FilterCard>{children}</FilterCard>;
};

const TimePeriodFilterCard = ({
  filters,
  onChangeFilters,
}: Omit<Props, 'mode'>) => {
  const { t } = useTranslation();

  return (
    <DataAnalysisFilterCard>
      <Flex column className={styles.filterContainer}>
        <Typography.Text className={styles.filterTitle} type="secondary">
          {t('dataAnalysis.labels.dateFilter')}
        </Typography.Text>
        <RangePicker
          showTime
          onChange={onChangeFilters.onChangeDateRange}
          value={[filters.dateFilters.startDate, filters.dateFilters.endDate]}
        />
      </Flex>
    </DataAnalysisFilterCard>
  );
};

const RingFilterCard = ({ filters, onChangeFilters }: Omit<Props, 'mode'>) => {
  const { t } = useTranslation();

  const { constructionSiteId } = usePermissions();
  const { data, isError } = useDataAnalysisControllerGetCurrentRingNumber({
    queryParams: { constructionSiteId },
  });

  const [minRing, maxRing] = filters.ringFilters ?? [];

  const ringModeErrorMessage =
    notEmpty(minRing) && notEmpty(maxRing) && minRing > maxRing
      ? t('errors.invalid_ring_range')
      : undefined;

  return (
    <DataAnalysisFilterCard>
      <Flex column className={styles.ringNumberRange}>
        <NumberRangeInput
          values={filters.ringFilters}
          onChange={onChangeFilters.onChangeRingRange}
          leftTitle={t('dataAnalysis.labels.startRing')}
          leftInputProps={{ min: 0, className: styles.numberInput }}
          rightTitle={t('dataAnalysis.labels.endRing')}
          rightInputProps={{ min: 0, className: styles.numberInput }}
          errorMessage={ringModeErrorMessage}
        />
        <Typography.Text
          italic
          type="secondary"
          className={styles.currentRingNumber}
        >
          {isError
            ? t('dataAnalysis.errors.currentRingNumberError')
            : t('dataAnalysis.labels.currentRingNumber', {
                ringNumber: data?.value,
              })}
        </Typography.Text>
      </Flex>
    </DataAnalysisFilterCard>
  );
};

export const FilterCardByMode = ({ mode, filters, onChangeFilters }: Props) => {
  switch (mode) {
    case PlotterMode.TIME_PERIOD: {
      return (
        <TimePeriodFilterCard
          filters={filters}
          onChangeFilters={onChangeFilters}
        />
      );
    }
    case PlotterMode.RING: {
      return (
        <RingFilterCard filters={filters} onChangeFilters={onChangeFilters} />
      );
    }
  }
};
