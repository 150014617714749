import { RouterProvider } from 'react-router-dom';

import { useAppRouter } from './useAppRouter';

const Router = () => {
  const router = useAppRouter();

  return <RouterProvider router={router} />;
};

export default Router;
