import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import {
  isAuthenticated,
  isAuthInitialized,
} from 'business/user/services/authentication';
import getUserAndCreateIfNeeded from 'business/user/services/user';
import { CommonUserFieldsFragment } from 'generated/graphql';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';

function useUserData() {
  const [isBootstraped, setIsBootstraped] = useState(false);
  const [user, setUser] = useState<CommonUserFieldsFragment | undefined>(
    undefined,
  );
  const client = useApolloClient();
  const fetchAndStoreUser = useCallback(
    async function refetchUser() {
      const targetUser = await getUserAndCreateIfNeeded(client);
      setUser(targetUser);
    },
    [client],
  );

  /**
   * Fonction to be called if needed typcially when authentication status changes (ie - was not connected and now am connected)
   */
  const requestRebootstrap = useCallback(
    function requestRebootstrap() {
      return isAuthInitialized
        .then(async () => {
          if (!isAuthenticated()) {
            return;
          }
          // try to get my user
          await fetchAndStoreUser();
        })
        .catch((err) => {
          logger.error(err);
          errorReporting.warning(err);
          throw err;
        })
        .finally(() => {
          return setIsBootstraped(true);
        });
    },
    [fetchAndStoreUser],
  );

  useEffect(() => {
    requestRebootstrap();
  }, [client, requestRebootstrap]);

  // user sync with providers
  useEffect(() => {
    if (user) {
      errorReporting.setUser({
        id: user.id,
        email: user.email,
      });
    } else {
      errorReporting.removeUser();
    }
  }, [user]);

  return {
    user,
    isConnected: !!user,
    isBootstraped,
    requestRebootstrap,
  };
}

export default useUserData;
