import { Table, TableProps } from 'antd';
import { useTranslation } from 'translations/hooks';

import {
  X_SCROLL_BASE_TRIGGER_VALUE,
  Y_SCROLL_TRIGGER_VALUE,
} from 'business/data-analysis/components/graphCard/components/graph-table-view/constants';
import { PlotterMode } from 'business/data-analysis/constants';
import { formatTableData } from 'business/data-analysis/services/format-table-data';
import { formatParameterName } from 'business/data-analysis/services/parameter-name-differentiation';
import { GraphStateDto } from 'generated/apiSchemas';

interface Props {
  graphState: GraphStateDto;
  mode: PlotterMode;
}

const useFirstColumn = (mode: PlotterMode) => {
  const { t } = useTranslation();
  if (mode === PlotterMode.TIME_PERIOD) {
    return {
      title: t('dataAnalysis.timePeriodMode.date'),
      dataIndex: 'date',
      key: 'date',
      fixed: 'left',
      width: 200,
    };
  }

  return {
    title: t('dataAnalysis.ringMode.ring'),
    dataIndex: 'ring',
    key: 'ring',
    fixed: 'left',
    width: 200,
  };
};

const GraphTableView = ({ graphState: { parameters }, mode }: Props) => {
  const firstColumn = useFirstColumn(mode);

  const columns: TableProps['columns'] = [
    firstColumn,
    ...parameters.map(({ name, index }) => {
      return {
        title: name,
        dataIndex:
          mode === PlotterMode.TIME_PERIOD
            ? name
            : formatParameterName({ name, index }),
        key: mode === PlotterMode.TIME_PERIOD ? name : index,
      };
    }),
  ];

  const data = formatTableData(parameters, mode);

  const xScroll = parameters.length * X_SCROLL_BASE_TRIGGER_VALUE;

  return (
    <Table
      columns={columns}
      dataSource={data}
      virtual
      scroll={{ x: xScroll, y: Y_SCROLL_TRIGGER_VALUE }}
      pagination={false}
      bordered
      size="small"
    />
  );
};

export default GraphTableView;
