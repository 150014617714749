import { ApolloClient } from '@apollo/client';

import { ConstructionSiteListQuery } from 'generated/graphql';

import { QUERY_CONSTRUCTION_SITE_LIST } from './query.gql';

export default async function fetchConstructionSiteList(
  client: ApolloClient<object>,
  userId: string,
) {
  const { data } = await client.query<ConstructionSiteListQuery>({
    query: QUERY_CONSTRUCTION_SITE_LIST,
    variables: {
      userId,
    },
    fetchPolicy: 'network-only',
  });

  if (!data) {
    return undefined;
  }

  const { constructionSiteList } = data;
  return constructionSiteList;
}
