import Icon from '@ant-design/icons';

import { CustomIconProps } from 'ui/icons/types';

const ShiftReportSvg = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <title>shift report icon</title>
    <g clipPath="url(#clip0_2243_2174)">
      <path
        d="M4.95837 2.33334H3.50004C2.85571 2.33334 2.33337 2.85567 2.33337 3.5V11.6667C2.33337 12.311 2.85571 12.8333 3.50004 12.8333H10.5C11.1444 12.8333 11.6667 12.311 11.6667 11.6667V3.5C11.6667 2.85567 11.1444 2.33334 10.5 2.33334H9.04171"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.66663 3.73334V2.625C4.66663 2.46392 4.79721 2.33334 4.95829 2.33334C5.11937 2.33334 5.25242 2.20269 5.28001 2.04399C5.36644 1.54683 5.70145 0.583336 6.99996 0.583336C8.29846 0.583336 8.63347 1.54683 8.71992 2.04399C8.74751 2.20269 8.88057 2.33334 9.04163 2.33334C9.20268 2.33334 9.33329 2.46392 9.33329 2.625V3.73334C9.33329 3.92664 9.17661 4.08334 8.98329 4.08334H5.01663C4.82333 4.08334 4.66663 3.92664 4.66663 3.73334Z"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2243_2174">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ShiftReportIcon = (props: CustomIconProps) => (
  <Icon component={ShiftReportSvg} {...props} />
);

export default ShiftReportIcon;
